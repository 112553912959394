import { createGlobalStyle } from 'styled-components';

export const Theme = {
  colors: {
    black: '#000000',
    pitchblack: '#3E3E3E',
    red: '#FD5523',
    orange: '#FF7246',
    litepink: '#FFF5F2',
    white: '#FFFFFF',
    darkgray: '#B7B7B7',
    semidarkgray: '#DFDFDF',
    gray: '#EEEEEE',
    lightgray: '#F6F6F6',
    lightGray:'#DAD5D6',
    softgray: '#FBFBFB',
    blue: '#A8D1E8',
    pink: '#FFBDA9',
    yellow: '#F5E87A',
    green: '#7B9F7E',
    TranparantOrange:'#ff724633',
    DarkTransparantOrange :'#fcc7b7',
  },
  breakpoints: {
    small: '@media (max-width: 480px)',
    medium: '@media (max-width: 768px)',
    large: '@media (max-width: 1024px)',
    mobileS: '@media (min-width: 320px)',
    mobileM: '@media (min-width: 375px)',
    mobileL: '@media (min-width: 425px)',
    tablet: '@media (min-width: 768px)',
    laptop: '@media (min-width: 1024px)',
    laptopM: '@media (min-width: 1260px)',
    laptopL: '@media (min-width: 1440px)',
    desktopM: '@media (min-width: 1920px)',
    desktop: '@media (min-width: 2560px)',
  },
  fontSize: {
    xsmall: '0.75rem',
    small: '0.875rem',
    normal: '1rem',
    medium: '1.25rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
  fontFamily: {
   font: 'Source Code Pro',
   secondaryFont: 'Source Sans Pro',
  },
  fontWeight: {
    regular: 'regular',
    semiBold: 'semibold',
    normal: 'normal',
  },
  lineHeight: {
    small: '1rem',
    normal: '1.25rem',
  },
  transition:{
    ease:'all 400ms ease',
  }
};

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    background-color: ${Theme.colors.lightgray};
    scroll-behavior: smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${Theme.fontFamily.font};
    margin-top: 0;
  }
  body,
  p,
  a {
    font-family: ${Theme.fontFamily.secondaryFont};
    font-weight: ${Theme.fontWeight.regular};
    line-height: ${Theme.lineHeight.normal};
    color: ${Theme.colors.black};
    font-size: ${Theme.fontSize.normal};
  }
  a,
  li{
    list-style: none;
    text-decoration: none;
  }
  hr {
      width: 90%;
      height: 0.063rem;
      background-color: ${Theme.colors.semidarkgray};
      border: none;
    }
`;
