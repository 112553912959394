import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'src/api/useApi';
import { Size } from 'src/components/Button/ButtonOptions';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { TextInput } from 'src/components/Input/TextInput';
import { useUser } from 'src/contexts/UserContext';
import { userService } from 'src/api/services/userService';
import { Error, UserNameWrapper } from 'src/views/Project/CreateUserName.styles';

export const CreateUserName = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const { currentUser, fetchCurrentUser } = useUser();
  const id = currentUser.id;

  // Create user name, last name and redirect to select project.
  const createUserName = useCallback(
    (token) => userService.patchUser({ token: token, id: id, name: name, lastName: lastName, role: null }),
    [id, name, lastName]
  );

  const onSuccess = () => {
    // Refetching data from currentUser to get user's latest information.
    fetchCurrentUser();
    navigate('/login');
  };

  const { error, fetch } = useApi({ fetcher: createUserName, fetchOnLoad: false, onSuccess: onSuccess });

  return (
    <>
      <UserNameWrapper>
        <div className='H3'>What's your name?</div>
        <p>Your team members will see this name</p>
        {error && (
          <Error>
            <ExclamationIcon /> {error}
          </Error>
        )}
        <div>
          <TextInput
            id='first-name'
            placeholder="First name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            id='last-name'
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <SecondaryButton size={Size.medium} data-testid='continue' onClick={() => fetch()}>
          Continue
        </SecondaryButton>
      </UserNameWrapper>
    </>
  );
};
