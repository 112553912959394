import { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { useApi } from 'src/api/useApi';
import { datasetService } from 'src/api/services/datasetService';
import { ArrowDownIcon } from "src/assets/icons/ArrowDownIcon";
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import DownloadIcon from "src/assets/icons/DownloadIcon";
import { DeleteRealFileModal } from 'src/components/Files/DeleteRealFileModal';
import { Modal } from 'src/components/Modal/Modal';
import { useConnectors } from "src/contexts/ConnectorContext";
import { formatDate } from "src/helpers/date";
import { openWindow } from 'src/helpers/openWindow';
import { numberSynFilesGenerated } from "src/views/Files/RealFiles/fileUtils";
import { SyntheticFiles } from "src/views/Files/SyntheticFiles/SyntheticFiles";

export const RealFileRow = ({ isOpen, file, toggleRow }) => {
  const { DFConnector } = useConnectors();
  const navigate = useNavigate();
  // Popup modal for deleting a dataset.
  const [deleteFile, setDeleteFile] = useState(false);

  //Getting real dataset url and download the file
  const getRealDatasetUrl = useCallback(
    (token, fileId) => datasetService.getRealDatasetUrl({ token: token, fileId: fileId }),
    []
  )
  const { fetch: downloadRealDataset, error, setError } = useApi({
    fetcher: getRealDatasetUrl,
    fetchOnLoad: false,
    onSuccess: (data) => openWindow(data, '_self')
  });

  const nrSynFiles = numberSynFilesGenerated(file);

  return (
    <div className="table-row-group">
      <div className="table-row text-pitch-black font-font text-sm real-datasets-rows">
        <div className="bg-white border-t-0">
          <div
            className={`cursor-pointer flex justify-center items-center transition-all duration-300 ease-linear
              ${isOpen ? "rotate-0" : "-rotate-90"}
              ${nrSynFiles ? "visible" : "collapse"}`}
            onClick={toggleRow}
          >
            <ArrowDownIcon />
          </div>
        </div>
        <div className="text-left pl-4 break-all">
          {file.filename}
        </div>
        <div>
          {formatDate(file.created_at)}
        </div>
        <div>
          {file.rows}
        </div>
        <div>
          {file.columns}
        </div>
        <div>
          {nrSynFiles ? nrSynFiles : "No"}
        </div>
        <div>
          <div className="flex justify-center items-center gap-x-4 px-2">
            <div
              className={`${DFConnector?.id!==file.connector_id ? "hidden" : "cursor-pointer"}`}
              title="Download"
              onClick={() => downloadRealDataset(file.id)}
            >
              <DownloadIcon />
            </div>
            <div
              className="cursor-pointer"
              title="Delete"
              onClick={() => setDeleteFile(file)}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
        {[
          ["Set parameters", "/parameters?fileId="],
          ["Train & Generate", "/models?fileId="]
        ].map((item, idx) => (
          <div key={idx} >
            <button
              className="button-style text-sm text-orange transition-colors focus:outline-none px-6"
              onClick={() => navigate(item[1]+file.id)}
            >
              {item[0]}
            </button>
          </div>
        ))}
      </div>
      {/* Render Synthetic Datasets */}
      <SyntheticFiles isOpen={isOpen} file={file} />
      {deleteFile && (
        <DeleteRealFileModal
          isOpen={deleteFile}
          setIsOpen={setDeleteFile}
          file={deleteFile}
        />
      )}
      {error && (
        <Modal
          isOpen={!!error}
          type="error"
          description={error}
          buttonText="Try again"
          buttonAction={() => setError('')}
        />
      )}
    </div>
  );
};