import { useEffect, useRef, useState } from 'react';
import { roles } from 'src/api/roles';
import { ArrowDownIcon } from 'src/assets/icons/ArrowDownIcon';
import { DropDownContainer, DropDownHeader, DropDownList, ListItem } from 'src/components/Dropdown/DropdownRole.styles';

export const DropdownRole = ({ onChange, role }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  // Map the existing roles to a list of options and set the given role.
  const options = Object.entries(roles).map(([key, value]) => ({ value: key, label: value }));
  const roleOption = options.find((o) => o.value === role);
  const [selectedRole, setSelectedRole] = useState(roleOption || options[0]);

  const toggling = () => setOpen((prevOpen) => !prevOpen);

  const roleClicked = (value) => (e) => {
    setSelectedRole(value);
    onChange(value);
    setOpen(false);
    e.preventDefault();
  };

  // Handle mouse clicks inside and outside the dropdown to know when to close it.
  const handleMouseClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  // Event listener for mouse clicks.
  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <DropDownContainer ref={ref}>
      <DropDownHeader className='flex gap-2 justify-center items-center' onClick={toggling} data-testid="dropdown-role">
        {selectedRole.label} <ArrowDownIcon />
      </DropDownHeader>
      {open && (
        <div>
          <DropDownList>
            {options.map((role) => (
              <ListItem data-testid="select-role" onClick={roleClicked(role)} key={role.value}>
                {role.label}
              </ListItem>
            ))}
          </DropDownList>
        </div>
      )}
    </DropDownContainer>
  );
};
