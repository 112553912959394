import { useCallback, useState } from 'react';
import { useApi } from 'src/api/useApi';
import { datasetService } from 'src/api/services/datasetService';
import { TextInput } from 'src/components/Input/TextInput';
import { Modal } from 'src/components/Modal/Modal';
import { Spinner } from 'src/components/Loaders/Spinner';
import { useFiles } from 'src/contexts/FileContext';

export const DeleteRealFileModal = ({ isOpen, setIsOpen, file }) => {
  const { fetchFiles } = useFiles();

  // Variables for type the dataset name and delete it.
  const [name, setName] = useState('');

  // Delete dataset and redirect to the home.
  const deleteDataset = useCallback(
    (token) => datasetService.deleteRealDataset({ token: token, fileId: file.id }),
    [file.id]
  );

  const { error, fetch, loading } = useApi({
    fetcher: deleteDataset,
    fetchOnLoad: false,
    onSuccess: () => {
      fetchFiles();
      setIsOpen(false);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title={`Are you sure that you want to delete "${file.filename}"?`}
      description={`This action can not be undone. You will loose all file information.
      To confirm, please write the file name (${file.filename}) and press Delete.`}
      error={error}
      buttonText="Delete"
      buttonDisabled={name !== file.filename}
      buttonAction={() => fetch()}
      linkText="Cancel"
      linkAction={() => setIsOpen(false)}
    >
      {loading ? (
        <Spinner />
      ) : (
        <TextInput
          data-testid="delete-file-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}
    </Modal>
  );
};
