import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'src/api/useApi';
import { Button } from 'src/components/Button/Button';
import { Size } from 'src/components/Button/ButtonOptions';
import { LinkButton } from 'src/components/Button/LinkButton';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { Spinner } from 'src/components/Loaders/Spinner';
import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';
import { projectService } from 'src/api/services/projectService';
import { Error, StepOnePaper } from 'src/views/Project/CreateProjectSteps.styles';

export const StartCreateProject = () => {
  const navigate = useNavigate();
  const { fetchCurrentUser } = useUser();
  const { setCurrentProjectId } = useStorage();

  // Creates a test project and redirects to the home view.
  const createTestProject = useCallback((token) => projectService.postProject({ token: token, name: null, is_play_project: true }), []);

  const onSuccess = (data) => {
    // Refetching data from currentUser to get user's latest information.
    fetchCurrentUser();
    // Setting the current project id.
    setCurrentProjectId(data.id);
    navigate('/');
  };
  const { loading, error, fetch } = useApi({ fetcher: createTestProject, fetchOnLoad: false, onSuccess: onSuccess });

  return (
    <StepOnePaper>
      {loading && <Spinner />}
      {error && (
        <Error>
          <ExclamationIcon />
          {error}
        </Error>
      )}
      <div className='H3'>Create new team project</div>
      <p>Create a team project in a few simple steps</p>
      <Button data-testid="create-team-project" size={Size.medium} onClick={() => navigate(`?step=2`)}>
        Create team project
      </Button>
      <p>or</p>
      <LinkButton data-testid="try-test-project" onClick={() => fetch()}>
        Try test project (with preloaded datasets)
      </LinkButton>
    </StepOnePaper>
  );
};
