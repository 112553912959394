import { H6 } from 'src/components/Headers/Headers.style';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';
import { DeleteProjectModal } from 'src/components/Project/DeleteProjectModal';
import { RenameProjectModal } from 'src/components/Project/RenameProjectModal';
import { PenIcon } from 'src/assets/icons/PenIcon';
import { ChangeIcon } from 'src/assets/icons/ChangeIcon';
import { DeleteIcon } from 'src/assets/icons/DeleteIcon';
import { ChangeProject } from 'src/views/Project/ChangeProject';
import { Container } from 'src/views/Project/ProjectSetting.styles';
import * as S from 'src/views/Settings/Settings.styles';
import { getCurrentProjectOrError } from 'src/contexts/aux';
import { Modal } from 'src/components/Modal/Modal';
import { TasksIcon } from 'src/assets/icons/TasksIcon';
import { TasksProjectModal } from 'src/components/Project/TasksProjectModal';

export const ProjectSetting = ({ fetchUsersFromProject, setMultiTableView }) => {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { getCurrentProjectId } = useStorage();
  // Popup modals for renaming and deleting a project.
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [changeIsOpen, setChangeIsOpen] = useState(false);
  const [taskIsOpen, setTaskIsOpen] = useState(false);

  const SettingsItems = [
    {
      title: "Rename Project",
      icon: <PenIcon />,
      onClick: () => setRenameIsOpen(true)
    },
    {
      title: "Delete Project",
      icon: <DeleteIcon />,
      onClick: () => setDeleteIsOpen(true)
    },
    {
      title: "Change Project",
      icon: <ChangeIcon />,
      onClick: () => setChangeIsOpen(true)
    },
    {
      title: "Project's Tasks",
      icon: <TasksIcon />,
      onClick: () => setTaskIsOpen(true)
    },
  ];

  let currentProject = getCurrentProjectOrError({ currentUser: currentUser, currentProjectId: getCurrentProjectId()});
  if (currentProject.error) {
    return (
      <Modal
        isOpen={true}
        error={true}
        title="Something went wrong"
        description={currentProject.error}
        buttonText="Refresh"
        buttonAction={()=> navigate("/")}
      />
    );
  }

  return (
    <S.Wrapper>
      <S.PaperContainer>
        <S.SubContainer>
          <H6>Project</H6>
          <p data-testid="project-name">{currentProject && currentProject.data.name}</p>
          <S.IconWrapper>
            {SettingsItems.map((item, i) => (
              <Container key={i} onClick={item.onClick} title={item.title}>
                <span>{item.icon}</span>
              </Container>
            ))}
          </S.IconWrapper>
        </S.SubContainer>
        {changeIsOpen && <ChangeProject isOpen={changeIsOpen} setIsOpen={setChangeIsOpen} fetchUsersFromProject={fetchUsersFromProject} setMultiTableView={setMultiTableView} />}
        {renameIsOpen && <RenameProjectModal isOpen={renameIsOpen} setIsOpen={setRenameIsOpen}/>}
        {deleteIsOpen && <DeleteProjectModal isOpen={deleteIsOpen} setIsOpen={setDeleteIsOpen} />}
        {taskIsOpen && <TasksProjectModal isOpen={taskIsOpen} setIsOpen={setTaskIsOpen}/>}
      </S.PaperContainer>
    </S.Wrapper>
  );
};