import image1 from "src/assets/images/HowitworksImage.jpeg";
import { H4 } from "src/components/Headers/Headers.style";
import { ArrowIcon } from "src/assets/icons/ArrowIcon";
import { ChooseIcon } from "src/assets/icons/ChooseIcon";
import { GenerateIcon } from "src/assets/icons/GenerateIcon";
import { ParametersIcon } from "src/assets/icons/ParametersIcon";
import { PlayIcon } from "src/assets/icons/PlayIcon";
import { ExternalLink } from "src/components/Link/Link";
import { Tooltip } from "src/components/Tooltip/Tooltip";
import { Questions } from "src/views/Faq/Questions";

export const HowItWorks = () => {
  const parametersId = Questions.parameters.find((q) => q.id === 9).id;
  const modelId = Questions.models.find((q) => q.id === 7).id;
  const TooltipList = [
    {
      icon: <ArrowIcon />,
      title: "Upload dataset",
      text: "Upload the dataset you want to synthesize.",
      linkAction: "",
    },
    {
      icon: <ParametersIcon />,
      title: "Confirm parameters",
      text: "Syndata's Engine automatically sets certain parameters of the dataset for you, such as if a column is a category or not. You can confirm or change these parameters.",
      linkText: " Read more",
      linkAction: () => `/faq?id=${parametersId}`,
    },
    {
      icon: <ChooseIcon />,
      title: "Choose model",
      text: "Choose between our models for synthesizing depending on the wanted task. Our models provide different speed, analytical value and disclosure risk metrics.",
      linkText: " Read more",
      linkAction: () => `/faq?id=${modelId}`,
    },
    {
      icon: <GenerateIcon />,
      title: "Generate synthetic dataset",
      text: "Generate synthetic dataset by providing how many rows of data you want to generate. You are done!",
      linkAction: "",
    },
  ];
  const TooltipListWithIndex = TooltipList.map((tooltip, index) => ({
    ...tooltip,
    index: index + 1,
  }));
  return (
    <div>
      <div className="viewHeader">How it works</div>
      <div className="lg:paper lg:bg-transparent">
        <div className="flex-view flex-col md:flex-row md:flex-nowrap md:h-18rem">
          {TooltipListWithIndex.map(
            ({ index, icon, title, text, linkText, linkAction }) => {
              return (
                <div className="p-4 w-[300px] max-md:w-5/6" key={index}>
                  <div className="border border-transparent border-solid box-border shadow-md overflow-hidden transition-all duration-300 ease-in rounded-md bg-TransparantOrange hover:transform hover:scale-103">
                    <Tooltip
                      number={index}
                      icon={icon}
                      title={title}
                      text={text}
                      linkText={linkText}
                      linkAction={linkAction}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="flex-view flex-col md:flex-row md:h-18rem">
          <div className="md:w-1/2 p-4">
            <img
              className="rounded-[2rem]"
              alt="How It Works Img"
              src={image1}
            ></img>
          </div>
          <div className="md:w-1/2 flex-view flex-col gap-3 p-4">
            <p>
              Synthesizing is as simple as uploading a dataset, confirming
              parameters, choosing a model for the task and generating synthetic
              data. You can synthesize files within the the different projects
              you belong to. Remember files must be tabular data of individual
              entities (such as one customer per row) and not a series of
              events.
            </p>
            <H4>
              Our most powerful engine to date is the{" "}
              <div className="text-orange font-bold font-font text-xl leading-tight text-center uppercase mt-4 md:text-2xl">
                Realistic model.
              </div>
            </H4>
            <p>
              It has a Generative Adversarial Network (GAN) deep learning model
              in its core. If you want to know more about synthetic data
              <ExternalLink
                className="flex justify-center"
                href="https://www.youtube.com/watch?v=7qWCESXmQbo"
              >
                {" "}
                <u>Watch more</u>
                <PlayIcon />
              </ExternalLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
