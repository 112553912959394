import styled  from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const JoinProjectWrapper = styled(Paper)`
  padding: 3rem 2rem;
  ${Theme.breakpoints.laptop} {
    padding: 5rem 2rem;
  }
`;

