import { useNavigate } from 'react-router-dom';
import { Questions } from 'src/views/Faq/Questions';
import { Modal } from 'src/components/Modal/Modal';

export const MoreInfoModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const questionId = Questions.parameters.find((q) => q.id === 8).id;

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title="Syndata's Engine has already preprocessed your file."
      description="If you want to verify our guesstimation of parameters, please go through the different attributes. Otherwise we recommend to Confirm all and proceed as a first attempt."
      buttonText="Close"
      buttonAction={() => setIsOpen(false)}
      linkText="Read more"
      linkAction={() => navigate(`/faq?id=${questionId}`)}
    />
  );
};
