import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext, useEffect, useState } from 'react';
import currentRole from 'src/api/current-role';
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';

export const TokenContext = createContext({});

export const useToken = () => {
  return useContext(TokenContext);
};

export const TokenProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [tokenState, setTokenState] = useState({
    token: '',
    role: '',
  }); // define our global state (accessToken & role)

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently(); // retrieve token from auth0 hook
      try {
        const current_role = currentRole(token);
        setTokenState({
          ...tokenState,
          token: token,
          role: current_role,
        });
      } catch (error) {
        setTokenState({
          ...tokenState,
        });
      }
    };

    if (!isLoading && isAuthenticated && !tokenState.token) {
      getToken();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, tokenState]);

  if (isLoading)
    // Auth0 component is loading
    return <LoadingSpinner/>;

  // Not authenticated at Auth0, redirect to login
  if (!isAuthenticated) loginWithRedirect();

  // Token state is not set, but user is authenticated.
  // Spinner screen while waiting for tokenState.token to be set.
  if (!tokenState.token) {
    return <LoadingSpinner/>;
  }

  return (
    <TokenContext.Provider value={{ ...tokenState, setTokenState }}>
      {children}
    </TokenContext.Provider>
  );
};
