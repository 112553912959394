import { useNavigate } from 'react-router-dom';

import { LeftArrowIcon } from "src/assets/icons/LeftArrowIcon/LeftArrowIcon";

export const BackNavigation = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center gap-3 font-bold underline hover:cursor-pointer mb-4"
      onClick={() => navigate(-1)}
    >
      <LeftArrowIcon />
      Back
    </div>
  );
};