export const QuestionIcon = ({ onClick }) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        cursor="pointer"
      >
        <path
          d="M9.5 20C14.1944 20 18 16.1944 18 11.5C18 6.80558 14.1944 3 9.5 3C4.80558 3 1 6.80558 1 11.5C1 16.1944 4.80558 20 9.5 20Z"
          fill="white"
          stroke="#525252"
          strokeWidth="2"
        />
        <path
          d="M8.512 13.56C8.448 13.0907 8.47467 12.6747 8.592 12.312C8.70933 11.9493 8.87467 11.6187 9.088 11.32C9.312 11.0213 9.54133 10.7387 9.776 10.472C10.0107 10.2053 10.208 9.944 10.368 9.688C10.528 9.432 10.608 9.16 10.608 8.872C10.608 8.488 10.4907 8.17867 10.256 7.944C10.032 7.70933 9.71733 7.592 9.312 7.592C8.98133 7.592 8.68267 7.66667 8.416 7.816C8.14933 7.96533 7.89867 8.168 7.664 8.424L6.64 7.48C6.992 7.07467 7.41333 6.744 7.904 6.488C8.39467 6.22133 8.93867 6.088 9.536 6.088C10.368 6.088 11.0507 6.312 11.584 6.76C12.128 7.208 12.4 7.86933 12.4 8.744C12.4 9.14933 12.3147 9.512 12.144 9.832C11.984 10.152 11.7813 10.4507 11.536 10.728C11.3013 11.0053 11.0667 11.2827 10.832 11.56C10.5973 11.8373 10.4107 12.136 10.272 12.456C10.1333 12.776 10.0853 13.144 10.128 13.56H8.512ZM9.328 17.192C8.99733 17.192 8.71467 17.0747 8.48 16.84C8.256 16.6053 8.144 16.3067 8.144 15.944C8.144 15.5813 8.256 15.2827 8.48 15.048C8.71467 14.8133 8.99733 14.696 9.328 14.696C9.66933 14.696 9.952 14.8133 10.176 15.048C10.4107 15.2827 10.528 15.5813 10.528 15.944C10.528 16.3067 10.4107 16.6053 10.176 16.84C9.952 17.0747 9.66933 17.192 9.328 17.192Z"
          fill="#525252"
        />
      </svg>
    );
  };