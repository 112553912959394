import styled from 'styled-components';
import { H6 } from 'src/components/Headers/Headers.style';
import { Theme } from 'src/components/Theme';

export const DropDownContainer = styled.div`
  width: 100%;
  height: 2rem;
  cursor: pointer;
  border: 0.063rem solid ${Theme.colors.pitchblack};
  background: ${(props) => (props.disabled ? Theme.colors.lightgray : Theme.colors.white)};
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin: 1rem 0rem;
  &:disabled {
    opacity: 0.4;
  }
`;

export const DropDownHeader = styled(H6)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: -1rem;
  position: relative;
  font-family: ${Theme.fontFamily.secondaryFont};
  font-size: ${Theme.fontSize.xsmall};
  font-style: ${Theme.fontWeight.regular};
  background: ${Theme.colors.white};
  border: 0.063rem solid #e7e7e7;
  z-index: 100;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -0.5rem;
    left: 40%;
    overflow: hidden;
    border: 0.6rem solid transparent;
    border-top: 0;
    border-bottom-color: ${Theme.colors.white};
  }
  &::before {
    top: -0.6rem;
    border-bottom-color: #e7e7e7;
  }
`;

export const ListItem = styled.li`
  text-align: left;
  list-style: none;
  margin: 0.313rem 1.25rem;
  &:hover {
    font-weight: 600;
  }
`;
