import { DarkSvg } from 'src/assets/icons/LeftArrowIcon/LeftArrowIcon.styles';
export const LeftArrowIcon = () => {
    return (
      <DarkSvg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.16797 1L1.0013 5.16667L5.16797 9.33333"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 5.16602L13 5.16602"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </DarkSvg>
    );
  };