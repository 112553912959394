import { useNavigate } from 'react-router-dom';

import { EvaluationIcon } from "src/assets/icons/EvaluationIcon";
import { formatDate } from "src/helpers/date";
import { getMetricColor } from 'src/helpers/getMetricColor';
import { isOpenTransitionCss } from "src/views/Files/utils";

export const SyntheticSchema = ({ isOpen, schema }) => {
  const navigate = useNavigate();

  return (
    <div className={`table-row bg-lite-pink text-sm font-font synthetic-datasets-rows transition duration-1000
        ${isOpenTransitionCss(isOpen)}`}
    >
      <div className="bg-white"></div>
      <div className="border-dashed border-l-[0.5px] border-red-300 text-left pl-8">
        {schema.db_name}
      </div>
      {[schema.name, formatDate(schema.created_at), ""].map((item, i) => (
        <div key={i}>
          {item}
        </div>
      ))}
      {[schema.avg_pct_diff, schema.avg_WA, schema.avg_MI].map((metric, i) => (
        <div key={i}>
          <div className={`inline-flex items-center p-1 rounded-full
            ${getMetricColor(metric)}`}>
            <h2 className="text-sm font-normal">
              {Number(metric).toFixed(2)}
            </h2>
          </div>
        </div>
      ))}
      <div />
      <div className="border-dashed border-r-[0.5px] border-red-300">
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={()=>navigate(`/mt_evaluation/${schema.id}`)}
        >
          <EvaluationIcon />
        </div>
      </div>
    </div>
  );
};