import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'src/api/useQuery';
import { SIcon } from 'src/assets/icons/SIcon';
import { BackNavigation } from 'src/components/BackNavigation';
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';
import { MultiTabelModal } from "src/components/Modal/MultiTableModal";
import { useFiles } from 'src/contexts/FileContext';
import { useSchema } from 'src/contexts/SchemaContext';
import { formatDate } from 'src/helpers/date';
import { Correlation } from 'src/views/Files/Evaluation/Correlation/Correlation';
import { Histogram } from 'src/views/Files/Evaluation/Histogram/Histogram';
import { NullValues } from 'src/views/Files/Evaluation/NullValues/NullValues';
import { Quality } from 'src/views/Files/Evaluation/Quality/Quality';
import { Table } from 'src/views/Files/Evaluation/Table/Table';

const getVariablesEvaluation = ({
  table,
  realDataset,
  synDataset,
  realSchemaInfo,
  synSchemaInfo,
  loadingDatasets,
  loadingRealSchemaInfo,
  loadingSynSchemaInfo
}) => {
  const generated_from = realDataset?.filename;
  let infoLoaded = (realDataset && synDataset);
  let loading = loadingDatasets;
  let name = synDataset?.filename;
  let realMData = synDataset?.meta_data?.model_metadata?.real_file_metadata;
  let synMData = synDataset?.meta_data?.synthetic_file_metadata;
  let evaluation = synDataset?.meta_data?.evaluation;
  let created_at = synDataset?.created_at;
  let rows = synDataset?.rows;
  let columns = synDataset?.columns;
  if (table)
  {
    infoLoaded = (realSchemaInfo && synSchemaInfo);
    loading = (loadingRealSchemaInfo || loadingSynSchemaInfo);
    name = table;
    realMData = realSchemaInfo?.meta_data?.meta_data[table];
    synMData = synSchemaInfo?.meta_data?.synthetic_mt_analyzer?.meta_data[table];
    evaluation = synSchemaInfo?.meta_data?.evaluation?.individual_eval[table];
    created_at = synSchemaInfo?.created_at;
    rows = synSchemaInfo?.meta_data?.synthetic_mt_analyzer?.table_info[table]?.n_rows;
    columns = Object.keys(synMData?.attributes).length;
  }

  return ({
    generated_from,
    infoLoaded,
    loading,
    name,
    realMData,
    synMData,
    evaluation,
    created_at,
    rows,
    columns
  });
}

export const Evaluation = () => {
  const views = ['Overall Quality', 'Table', 'Histogram', 'Correlation', 'Mutual Information', 'Missing Rate'];
  const [activeView, setActiveView] = useState(0);
  const navigate = useNavigate();
  const {
    synSchemaInfo,
    loadingSynSchemaInfo,
    realSchemaInfo,
    loadingRealSchemaInfo
  } = useSchema();
  const query = useQuery();
  const { getFile, getSynFile, loadingDatasets } = useFiles();

  // Get real and synthetic dataset by id, or table
  const realDataset = getFile(query.get('fileId'));
  const synDataset = getSynFile(query.get('synFileId'));
  const table = query.get('table')

  // Get aux variables, real and synthetic metadata and evaluation info (depends on if table query parameter present)
  const {
    generated_from,
    infoLoaded,
    loading,
    name,
    realMData,
    synMData,
    evaluation,
    created_at,
    rows,
    columns
  } = getVariablesEvaluation({
    table: table,
    realDataset: realDataset,
    synDataset: synDataset,
    realSchemaInfo: realSchemaInfo,
    synSchemaInfo: synSchemaInfo,
    loadingDatasets: loadingDatasets,
    loadingRealSchemaInfo: loadingRealSchemaInfo,
    loadingSynSchemaInfo: loadingSynSchemaInfo
  })

  // Get headerInfo and push if generated_from is defined
  const headerInfo = [
    ["Generated", formatDate(created_at)],
    ["Rows", rows],
    ["Columns", columns],
  ];
  generated_from && headerInfo.push(["Generated from", generated_from]);

  const activeButtonCss = 'bg-transparant-orange border border-orange';

  return (
    <div>
      <div className="viewHeader">Evaluation</div>
      <BackNavigation />
      {infoLoaded && (
        <>
          <div className="flex flex-row items-center gap-3 max-md:flex-col H6 text-left px-5 py-3 mb-6">
            <SIcon />
            <div className="font-normal text-orange">
              {name}
            </div>
          </div>
          <div className="flex flex-row gap-10 max-md:flex-col max-md:gap-4 H6 text-left px-5 mb-6">
            {headerInfo.map((item, idx) => (
              <div key={idx} className="flex flex-row gap-3">
                {item[0]}
                <div className="font-normal">
                  {item[1]}
                </div>
              </div>
            ))}
          </div>
          <div className='flex items-center justify-center flex-wrap gap-2 py-2'>
            {views.map((view, idx) => (
              !((idx === 1) && table) && (
                <div
                  key={idx}
                  className={`button-style text-center
                    ${(idx === activeView) ? activeButtonCss : ''}`}
                  onClick={() => setActiveView(idx)}
                >
                  {view}
                </div>
              )
            ))}
          </div>
          {activeView === 0 && <Quality setActiveView={setActiveView} evaluation={evaluation} />}
          {activeView === 1 && <Table realFileId={realDataset.id} synFileId={synDataset.id} />}
          {activeView === 2 && <Histogram realMData={realMData} synMData={synMData} evaluation={evaluation} />}
          {activeView === 3 && <Correlation realMData={realMData} synMData={synMData} evaluation={evaluation} />}
          {/* Mutual information */}
          {activeView === 4 && <Correlation realMData={realMData} synMData={synMData} evaluation={evaluation} isMutualInfo={true} />}
          {activeView === 5 && <NullValues realMData={realMData} synMData={synMData} evaluation={evaluation} />}
        </>
      )}
      {(!infoLoaded && loading) && <LoadingSpinner />}
      {(!infoLoaded && !loading) && <MultiTabelModal
        isOpen={(!infoLoaded && !loading)}
        type="error"
        title="Missing information"
        description="Could not load evaluation information"
        buttonText={"Go back"}
        buttonAction={() => navigate(-1)}
      />}
    </div>
  );
};