import styled from 'styled-components'
import { Theme } from 'src/components/Theme';

export const H1 = styled.h1`
  color: ${Theme.colors.black};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 3.438rem;
  text-align: center;
  ${Theme.breakpoints.laptop} {
    font-size: 3rem;
  }
`

export const H2 = styled.h2`
  color: ${Theme.colors.black};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.625rem;
  text-align: center;
  ${Theme.breakpoints.laptop} {
    font-size: 2.25rem;
  }
`;

export const H4 = styled.h4`
  color: ${Theme.colors.black};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  ${Theme.breakpoints.laptop} {
    font-size: 1.25rem;
  }
`;

export const H6 = styled.h6`
  color: ${Theme.colors.black};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  ${Theme.breakpoints.laptop} {
    font-size: 0.875rem;
  }
`;

export const H7 = styled.h6`
  color: ${Theme.colors.red};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  margin-top:2rem;
  ${Theme.breakpoints.laptop} {
    font-size: 2rem;
  }
`;