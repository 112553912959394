import { useState } from "react";

import { ArrowIcon } from "src/assets/icons/ArrowIcon";
import { useFiles } from "src/contexts/FileContext";
import { useConnectors } from "src/contexts/ConnectorContext";
import {
  toggleRow,
  columnSortByNameAux,
  columnSortByNumberAux,
} from "src/views/Files/utils";
import { RealFileRow } from "src/views/Files/RealFiles/RealFileRow";
import { SortingIcon } from "src/assets/icons/SortingIcon";

export const RealFiles = () => {
  const [openRows, setOpenRows] = useState(new Set());
  const { files, uploadFileRef } = useFiles();
  const { DFConnector, databaseConnectors } = useConnectors();
  // State for sorted column ascend or descend
  const [sorted, setSorted] = useState(true);
  const columnSort = ({ column }) => {
    let columnSortBy = columnSortByNumberAux;
    if (column === "filename") {
      columnSortBy = columnSortByNameAux;
    }
    columnSortBy({ list: files, column: column, sortAscending: sorted });
    setSorted((prev) => !prev);
  };
  const realFilesHeaderInfo = [
    { title: "Name", sortedValue: "filename", extraCss: "" },
    { title: "Created", sortedValue: "created_at", extraCss: "w-40" },
    { title: "Rows", sortedValue: "rows", extraCss: "w-16"},
    { title: "Columns", sortedValue: "columns", extraCss: "w-16" },
    { title: "Generated ", extraCss: "w-16" },
  ];

  return (
    <div>
      {(DFConnector || databaseConnectors) && files && files.length === 0 && (
        <div className="paper min-h-[70vh] flex flex-col justify-center items-center">
          <div className="flex justify-center items-center">
            <ArrowIcon />
          </div>
          <div className="H4 py-4">
            {DFConnector ?
              'Upload your first dataset (.csv file)' :
              'Load tables'
            }
          </div>
          <p>
            {DFConnector && ('There are currently no datasets uploaded here. ' +
              'Remember that you can always delete datasets you have uploaded.' )}
            { databaseConnectors && databaseConnectors.length>0 && (
              <>
                <br />
                To load tables via connectors select
                <strong> Load Data</strong> in the Toolbar.
              </>
            )}
          </p>
          <br />
          <div className="flex justify-center items-center m-2">
            {DFConnector && (
              <button
                data-testid="files-uploadfile-button"
                className="button-style text-sm text-orange transition-colors focus:outline-none px-6"
                onClick={() => (uploadFileRef.current && uploadFileRef.current.click())}
              >
                Upload File
              </button>
            )}
          </div>
        </div>
      )}
      {files && files.length > 0 && (
        <section className="container paper px-4 mx-auto">
          <div className="flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="table min-w-full">
                  <div className="table-header-group bg-light-gray w-full">
                    <div className="table-row font-font font-bold text-sm text-black real-datasets-headers">
                      <div className="w-[10px] bg-white" />
                      {realFilesHeaderInfo.map((item, i) => (
                        <div key={i} className={`${item.extraCss}`}>
                          <div className={`flex items-center gap-x-1 ${
                              i===0 ? "justify-start pl-4 pr-2" : "justify-center px-2"
                          }`}>
                            {item.sortedValue && (
                              <div
                                className={"cursor-pointer"}
                                onClick={() =>
                                  columnSort({ column: item.sortedValue })
                                }
                              >
                                <SortingIcon />
                              </div>
                            )}
                            <span>{item.title}</span>
                          </div>
                        </div>
                      ))}
                      <div className="w-28"/>
                      <div className="max-w-[140px] w-[140px]" />
                      <div className="max-w-[140px] w-[140px]" />
                    </div>
                  </div>
                  {files.map((file, i) => (
                    <RealFileRow
                      key={i}
                      isOpen={openRows.has(i)}
                      file={file}
                      toggleRow={() => toggleRow({
                        setOpenRows: setOpenRows,
                        i: i,
                      })}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};