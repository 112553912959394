import { Theme } from "src/components/Theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  Button {
    margin: 0 0.5rem;
    &:hover {
      color: ${Theme.colors.orange};
    }
  }
`;