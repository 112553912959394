import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EvaluationIcon } from "src/assets/icons/EvaluationIcon";
import { SortingIcon } from "src/assets/icons/SortingIcon";
import { BackNavigation } from 'src/components/BackNavigation';
import { LoadingSpinner } from "src/components/Loaders/LoadingSpinner";
import {
  columnSortByNameAux,
  columnSortByNumberAux
} from "src/views/Files/utils";
import { useSchema } from "src/contexts/SchemaContext";
import { formatDate } from "src/helpers/date";
import { getMetricColor } from 'src/helpers/getMetricColor';

export const MTEvaluation = () => {
  const navigate = useNavigate();
  const {
    schemaSummary,
    synSchemaId,
    setSynSchemaId,
    synSchemaInfo,
    loadingSynSchemaInfo,
    fetchSynSchemaInfo,
    realConnectorId,
    setRealConnectorId,
    fetchRealSchemaInfo
  } = useSchema();

  // id refers to synthetic schema id
  const { id } = useParams();
  // Get real schema by synthetic schema id
  const realSchema = schemaSummary.find((realSchema) =>
    realSchema.mt_models.some((mtModel) =>
      mtModel.synthetic_schemas.some((schema) => schema.id.toString() === id)
    )
  );

  // Callback for fetching synthetic schema info
  const fetchSynSchemaInfoCallback = useCallback(() => {
    if (id!==synSchemaId) {
      setSynSchemaId(id);
      fetchSynSchemaInfo(id);
    }
  }, [fetchSynSchemaInfo, synSchemaId, setSynSchemaId, id])

  // Callback for fetching real schema info
  const fetchRealSchemaInfoCallback = useCallback(() => {
    if (realSchema.connector_id!==realConnectorId) {
      setRealConnectorId(realSchema.connector_id);
      fetchRealSchemaInfo(realSchema.connector_id);
    }
  }, [fetchRealSchemaInfo, realConnectorId, setRealConnectorId, realSchema])

  // useEffect to fetch synthetic schema info if not fetched
  useEffect(() => fetchSynSchemaInfoCallback(), [fetchSynSchemaInfoCallback])

  // Get variables summaryColumns, summaryColumnsMetrics and mtModel
  const summaryColumns = synSchemaInfo?.meta_data?.evaluation?.summary_columns;
  const summaryColumnsMetrics = synSchemaInfo?.meta_data?.evaluation?.summary_metrics;
  const mtModel = realSchema.mt_models.find((mtModel) =>
      mtModel.synthetic_schemas.some((schema) => schema.id.toString() === id)
  );

  // State for sorted column ascend or descend
  const [sorted, setSorted] = useState(true);
  const columnSort = ({ index }) => {
    let columnSortBy = columnSortByNumberAux;
    if (index === 0) {
      columnSortBy = columnSortByNameAux;
    }
    columnSortBy({
      list: summaryColumnsMetrics,
      column: index,
      sortAscending: sorted,
    });
    setSorted((prev) => !prev);
  };

  const getWidthHeader = (item) => {
    switch (item) {
      case "Table":
        return "w-[120px]";
      default:
        return "w-[60px]";
    }
  };

  const getHeaderText = (item) => {
    switch (item) {
      case "pct_diff":
        return "% Diff";
      case "U_rows":
        return "RT Rows";
      case "V_rows":
        return "ST Rows";
      case "NullCorr":
        return "Null Corr";
      default:
        return item;
    }
  };

  const getHoverText = (item) => {
    switch (item) {
      case "pct_diff":
        return "Percentage Difference of nr. of Rows";
      case "U_rows":
        return "Real Table nr. of Rows x Nrow Factor";
      case "V_rows":
        return "Synthetic Table nr. of Rows";
      case "MR":
        return "Max. Missing Rate Difference";
      case "MI":
        return "Mutual Information Matrixes Distance";
      case "WA":
        return "Wasserstein Distance";
      case "Corr":
        return "Correlation Matrixes Distance";
      case "NullCorr":
        return "Null Correlation Matrixes Distance";
      default:
        return item;
    }
  };

  const navigateEvaluation = ({ table }) => {
    fetchRealSchemaInfoCallback();
    navigate(`/evaluation?table=${table}`);
  };

  if (loadingSynSchemaInfo || !synSchemaInfo) return <LoadingSpinner />;

  const synModel = mtModel.syn_model_id===1 ? "RandomMT" : "RealisticMT";

  const headerInfo = [
    ["Real Schema", [
      ["DB: ", realSchema.db_name],
      ["Schema: ", realSchema.db_schema],
      ["Created: ", formatDate(realSchema.created_at)]
    ]],
    ["Synthetic Schema", [
      ["DB: ", synSchemaInfo.db_name],
      ["Schema: ", synSchemaInfo.db_schema],
      ["Created: ", formatDate(synSchemaInfo.created_at)]
    ]],
    ["MT Model", [
      ["Model: ", synModel],
      ["Nrow Factor: ", synSchemaInfo.meta_data.evaluation.nrow_factor],
      ["id: ", synSchemaInfo.mt_model_id]
    ]]
  ];

  return (
    <div>
      <div className="viewHeader">Evaluation</div>
      <BackNavigation />
      <div className="flex flex-row gap-4 justify-between max-md:flex-col H6 text-left px-5 py-3 mb-6">
        {headerInfo.map((item0, idx0) => (
          <div key={idx0} className="flex flex-col">
            <div className="mb-4">{item0[0]}</div>
            {item0[1].map((item1, idx1) => (
              <div className="font-normal" key={idx1}>
                {item1[0]}<span className="text-orange">{item1[1]}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <section className="container paper px-4 mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="table min-w-full">
                <div className="table-header-group bg-light-gray w-full">
                  <div className="table-row font-font font-bold text-sm text-black real-datasets-headers">
                    {summaryColumns &&
                      summaryColumns.map((item, index) => (
                        <div
                          className={getWidthHeader(item)}
                          key={index}
                        >
                          <div
                            className="flex gap-x-1 items-center justify-center cursor-alias"
                            title={getHoverText(item)}
                          >
                            <div className="cursor-pointer" onClick={()=> columnSort({ index: index })}>
                              <SortingIcon />
                            </div>
                            {getHeaderText(item)}
                          </div>
                        </div>
                      ))}
                    <div className="w-[40px]" />
                  </div>
                </div>
                {summaryColumnsMetrics &&
                  summaryColumnsMetrics.map((row, rowIndex) => (
                    <div
                      key={rowIndex}
                      className="table-row text-pitch-black font-font text-sm real-datasets-rows"
                    >
                      {row.map((metric, cellIndex) => (
                        <div
                          key={cellIndex}
                          className={`
                            ${cellIndex<=2 && "font-bold"}
                            ${cellIndex>2 && getMetricColor(metric)}
                          `}
                        >
                          {metric}
                        </div>
                      ))}
                      <div
                        className={`flex items-center justify-center cursor-pointer
                          ${(row[1]>0 && row[2]>0) ? '' : 'invisible'}`}
                        onClick={() => navigateEvaluation({ table: row[0] })}
                      >
                        <EvaluationIcon />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};