import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { datasetService } from "src/api/services/datasetService";
import { useApi } from "src/api/useApi";
import DownloadIcon from "src/assets/icons/DownloadIcon";
import { EvaluationIcon } from "src/assets/icons/EvaluationIcon";
import ShareIcon from "src/assets/icons/ShareIcon";
import { GenerateSyntheticFileModal } from "src/components/Files/GenerateSyntheticFileModal";
import { ShareSyntheticFileModal } from "src/components/Files/ShareSyntheticFileModal";
import { Modal } from "src/components/Modal/Modal";
import { useConnectors } from "src/contexts/ConnectorContext";
import { formatDate } from "src/helpers/date";
import { openWindow } from "src/helpers/openWindow";
import { isOpenTransitionCss } from "src/views/Files/utils";

export const SyntheticFiles = ({ file, isOpen }) => {
  const { DFConnector } = useConnectors();
  const navigate = useNavigate();
  // Popup modal for sharing synthetic dataset.
  const [shareSynFile, setShareSynFile] = useState(false);
  // Popup modal for generating synthetic dataset.
  const [generateInputProps, setGenerateInputProps] = useState(false);

  // Getting synthetic dataset url and download the file
  const getSyntheticDatasetUrl = useCallback(
    (token, synFileId) =>
      datasetService.getSyntheticDatasetUrl({
        token: token,
        fileId: synFileId,
      }),
    []
  );
  const {
    fetch: downloadSynDataset,
    error,
    setError,
  } = useApi({
    fetcher: getSyntheticDatasetUrl,
    fetchOnLoad: false,
    onSuccess: (data) => openWindow(data, "_self"),
  });

  return (
    <>
      <div
        className={`table-row bg-lite-pink text-xs text-orange font-font transition duration-1000
          ${isOpenTransitionCss(isOpen)}`}
      >
        <div className="table-cell bg-white" />
        <div className="table-cell text-left pl-4 py-2">Synthetic datasets</div>
        {[...Array(7).keys()].map((_, j) => (
          <div key={j} className="table-cell" />
        ))}
      </div>
      {file.trained_models.map((trainedModel, _) => (
        trainedModel.synthetic_datasets && trainedModel.synthetic_datasets.map((synFile, idx) => (
          <div
            key={idx}
            className={`table-row bg-lite-pink text-sm font-font synthetic-datasets-rows transition duration-1000
              ${isOpenTransitionCss(isOpen)}`}
          >
            <div className="bg-white" />
            <div
              className="text-left pl-4 break-all cursor-pointer hover:font-semibold"
              onClick={() => navigate(
                `/evaluation?fileId=${file.id}&trainedModelId=${trainedModel.id}&synFileId=${synFile.id}`
              )}
            >
              {synFile.filename}
            </div>
            {[
              formatDate(synFile.created_at),
              synFile.rows,
              synFile.columns,
              "", //empty cell
            ].map((item, i) => (
              <div key={i}>{item}</div>
            ))}
            <div>
              <div className="flex justify-center items-center gap-x-4 px-2">
                <div
                  data-testid="Download"
                  className={`${DFConnector?.id!==file.connector_id ? "hidden" : "cursor-pointer"}`}
                  title="Download"
                  onClick={() => downloadSynDataset(synFile.id)}
                >
                  <DownloadIcon />
                </div>
                <div
                  className={`${DFConnector?.id!==file.connector_id ? "hidden" : "cursor-pointer"}`}
                  title="Share"
                  onClick={() => setShareSynFile(synFile)}
                >
                  <ShareIcon />
                </div>
                <div
                  className="cursor-pointer"
                  title="Evaluation"
                  onClick={() => navigate(
                    `/evaluation?fileId=${file.id}&trainedModelId=${trainedModel.id}&synFileId=${synFile.id}`
                  )}
                >
                  <EvaluationIcon />
                </div>
              </div>
            </div>
            <div />
            <div>
              <button
                className="button-style text-sm text-orange transition-colors focus:outline-none bg-transparant-orange hover:bg-TransparantOrange border border-orange hover:border-transparent"
                onClick={() => setGenerateInputProps({
                  realFileId: file.id,
                  synFileName: synFile.filename,
                  trainedModelId: trainedModel.id
                })}
              >
                Generate
              </button>
            </div>
          </div>
        ))
      ))}
      {shareSynFile && (
        <ShareSyntheticFileModal
          isOpen={shareSynFile}
          setIsOpen={setShareSynFile}
          synFile={shareSynFile}
        />
      )}
      {generateInputProps && (
        <GenerateSyntheticFileModal
          isOpen={generateInputProps}
          setIsOpen={setGenerateInputProps}
          inputProps={generateInputProps}
        />
      )}
      {error && (
        <Modal
          isOpen={!!error}
          type="error"
          description={error}
          buttonText="Try again"
          buttonAction={() => setError("")}
        />
      )}
    </>
  );
};