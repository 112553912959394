import styled from 'styled-components';
import { DropDownContainer } from 'src/components/Dropdown/DropdownRole.styles';
import { H6 } from 'src/components/Headers/Headers.style';
import { Theme } from 'src/components/Theme';

export const Row = styled.div`
  display: inherit;
  justify-content: center;
  align-items: center;
  ${H6} {
    margin: 1rem 0rem;
    margin-right: 0rem;
  }
`;

export const RoleWrapper = styled.div`
  ${DropDownContainer} {
    position: relative;
    display: inherit;
  }
  justify-content: center;
  margin-left: 3.5rem;
  margin-top: 1rem;
  ${Theme.breakpoints.medium}{
    margin-left: 0rem;
  }
`;

export const InviteUsersWrapper = styled.div`
  justify-content: center;
  Button {
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  }
  background-color: ${Theme.colors.white};
  ul {
    li {
      align-items: flex-start;
      ${H6} {
        margin: 1rem 0rem 1rem 0rem;
      }
    }
  }
`;

export const IconWrapper = styled.div`
 svg {
    width: 25px;
    height: 25px;
    color: ${Theme.colors.pitchblack};
  }
`;
