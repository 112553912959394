import { useState } from "react";
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { Circle } from 'src/assets/icons/Circle';
import { NumberInput } from 'src/components/Input/NumberInput';
import { Theme } from 'src/components/Theme';
import { CircleWrapper, DotContainer, MainWrapper, RowNumber, WrapperPaper, Column, InputExtra } from 'src/views/Files/Generate/Models.styles';
import { HyperParametersModal } from 'src/views/Files/Generate/CtganUtils/HyperParameters';

const Dot = ({ color }) => (
  <CircleWrapper>
    <Circle color={color} />
  </CircleWrapper>
);

export const ModelPaper = ({
  modelId,
  fileId,
  rows,
  setRows,
  extraInput,
  setExtraInput,
  image,
  title,
  description,
  time,
  value,
  risk,
  buttonText,
  buttonAction,
}) => {
  // A list with a range of the total number of dots we want to show.
  const dotRange = [...Array(5).keys()];
  // CTGAN Extra input parameters
  const CTGAN_PARAMETERS = {
    EPOCHS: '{"epochs": 300}', //initial display
    HYPER: '{"auto_hyper": true}'
  };

  //Updating extra input parameter value
  const updatingExtraInput = async (value) => {
    setExtraInput(value);
  };
  //Set initExtraInputParam value on the extra input parameter field
  const setInitExtraInputParam = async (value) => {
    if (value === '') {
      setExtraInput(CTGAN_PARAMETERS.EPOCHS);
    }
  };
  // state of auto hyper checkbox
  const [isChecked, setIsChecked] = useState(false);

  const setAutoParam = () => {
    if (isChecked) {
      // set auto hyper parameter
      setExtraInput(CTGAN_PARAMETERS.HYPER);
    }
    else if (!isChecked) {
      setExtraInput(CTGAN_PARAMETERS.EPOCHS);
    }
  };
  return (
    <>
      <WrapperPaper>
        <img src={image} alt="model_image_url" />
        <MainWrapper>
          <div className='H3'>{title}</div>
          <p>{description}</p>
        </MainWrapper>
        <DotContainer>
          <p>Time</p>
          <p className="flex">
            {dotRange.map((n) =>
              n < time ? <Dot key={n} /> : <Dot key={n} color={Theme.colors.gray} />
            )}
          </p>
        </DotContainer>
        <DotContainer>
          <p>Analytical value</p>
          <p className="flex">
            {dotRange.map((n) =>
              n < value ? <Dot key={n} /> : <Dot key={n} color={Theme.colors.gray} />
            )}
          </p>
        </DotContainer>
        <DotContainer>
          <p>Disclosure risk</p>
          <p className="flex">
            {dotRange.map((n) =>
              n < risk ? <Dot key={n} /> : <Dot key={n} color={Theme.colors.gray} />
            )}
          </p>
        </DotContainer>
        {fileId && modelId===2 && (
          <Column isChecked={isChecked}>
            <p>Extra Input Parameters</p>
            <InputExtra
              data-testid="extra-input"
              placeholder={CTGAN_PARAMETERS.EPOCHS}
              value={extraInput}
              onClick={(e) => setInitExtraInputParam(e.target.value)}
              onChange={(e) => updatingExtraInput(e.target.value)}
              isChecked={isChecked}
            />
            <HyperParametersModal isChecked={isChecked} setIsChecked={setIsChecked} setAutoParam={setAutoParam}/>
          </Column>
        )}
        {fileId && (
          <RowNumber>
            <NumberInput
              data-testid="number-input"
              placeholder="Number of rows"
              value={rows}
              onChange={(e) => setRows(e.target.value)}
            />
            <SecondaryButton onClick={buttonAction}>{buttonText}</SecondaryButton>
          </RowNumber>
        )}
      </WrapperPaper>
    </>
  );
};
