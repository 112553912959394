import { callApi } from "src/api/services/aux";

export const taskService = {
    // Endpoint for getting a specific task info
    getTask: ({ token, task_id }) => callApi({
        token: token,
        method: "GET",
        endpoint: `/task/${task_id}`
    }),
    // Endpoint for getting all project's tasks
    getAllTasks: ({ token, projectId }) => callApi({
        token: token,
        method: "GET",
        endpoint: `/all_tasks`,
        params: { project_id: projectId },
    }),
    // Endpoint for deleting task
    deleteTask: ({ token, taskId, deleteFlag }) => callApi({
        token: token,
        method: "DELETE",
        endpoint: `/task/${taskId}`,
        params: { ...(deleteFlag && { delete_flag: deleteFlag }) },
    })
};