import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { useApi } from 'src/api/useApi';
import { callApi } from 'src/api/services/aux';
import { Spinner } from 'src/components/Loaders/Spinner';
import LogoutButton from 'src/components/LogoutButton';
import { Modal } from 'src/components/Modal/Modal';
import { useFiles } from 'src/contexts/FileContext';
import { useToken } from 'src/contexts/TokenContext';
import { useUser } from 'src/contexts/UserContext';

const Test = () => {
  const { user } = useAuth0();
  const { loading, error, data } = useApi({
    fetcher: useCallback((token) => callApi({ token: token, method: "GET", endpoint: "/hello_world"}), []),
    fetchOnLoad: true
  });
  const { role, token } = useToken();
  const { currentUser } = useUser();
  const { files } = useFiles();

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="App" data-testid="app">
      User profile:
      <p style={{ padding: '10px' }}>{JSON.stringify(user, null, 2)}</p>
      <LogoutButton />
      <div style={{ padding: 10 }}>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <h3>Api Data</h3>
        API Data:
        <p>{JSON.stringify(data, null, 2)}</p>
        Role:
        <p>{JSON.stringify(role, null, 2)}</p>
        <h3>Current user:</h3>
        <p>{JSON.stringify(currentUser)}</p>
        <h3>Token:</h3>
        <p>{JSON.stringify(token)}</p>
        <h3>Number of Files:</h3>
        <p>{files?.length}</p>
        <h3>Current Files:</h3>
        <table>
          <tbody>
            {files?.map((file) => (
              <tr key={file.id}>
                <th>
                  <h4>File name:</h4>
                </th>
                <td>{file.filename}</td>
                <th>
                  <h4>File rows:</h4>
                </th>
                <td>{file.rows}</td>
                <th>
                  <h4>File columns:</h4>
                </th>
                <td>{file.columns}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Testing the progress spinner, change to true to show it */}
      <Modal isOpen={false} progress={25} type="progress-spinner" title="Some text here..." />
    </div>
  );
};

export default Test;
