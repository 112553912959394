import { createContext, useCallback, useContext, useState } from 'react';

import { useApi } from 'src/api/useApi';
import { schemaService } from 'src/api/services/schemaService';
import { useStorage } from "src/contexts/useStorage";
import { Modal } from "src/components/Modal/Modal";
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';

export const SchemaContext = createContext({});

export const useSchema = () => {
  return useContext(SchemaContext);
};

export const SchemaProvider = ({ children }) => {
  const { getCurrentProjectId }  = useStorage();

  const {
    data: schemaSummary,
    error: errorSchemaSummary,
    setError: setErrorSchemaSummary,
    fetch: fetchSchemaSummary,
    loading: loadingSchemaSummary
  } = useApi({
    fetchOnLoad: true,
    fetcher: useCallback(
      (token) => schemaService.getSchemaSummary({
        token: token,
        projectId: getCurrentProjectId()
      }),
      [getCurrentProjectId]
    )
  });

  const [synSchemaId, setSynSchemaId] = useState(null);
  const [realConnectorId, setRealConnectorId] = useState(null);


  const {
    data: synSchemaInfo,
    error: errorSynSchemaInfo,
    setError: setErrorSynSchemaInfo,
    fetch: fetchSynSchemaInfo,
    loading: loadingSynSchemaInfo
  } = useApi({
    fetchOnLoad: false,
    fetcher: useCallback(
      (token, synSchemaId) => schemaService.getSchemaInfo({
          token: token,
          id: synSchemaId,
          realSchemaFlag: false,
        }),
      []
    ),
  });

  const {
    data: realSchemaInfo,
    error: errorRealSchemaInfo,
    setError: setErrorRealSchemaInfo,
    fetch: fetchRealSchemaInfo,
    loading: loadingRealSchemaInfo
  } = useApi({
    fetchOnLoad: false,
    fetcher: useCallback(
      (token, connectorId) =>
        schemaService.getSchemaInfo({
          token: token,
          id: connectorId
        }),
      []
    ),
  });

  if (!schemaSummary) {
    return <LoadingSpinner />;
  }

  const error = errorSchemaSummary || errorSynSchemaInfo || errorRealSchemaInfo;

  if (error) {
    return <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Try again"
        buttonAction={() => {
          setErrorSchemaSummary('');
          setErrorSynSchemaInfo('');
          setErrorRealSchemaInfo('');
        }}
    />;
  }

  return (
    <SchemaContext.Provider
      value={{
        schemaSummary: schemaSummary,
        loadingSchemaSummary: loadingSchemaSummary,
        fetchSchemaSummary: fetchSchemaSummary,
        synSchemaId: synSchemaId,
        setSynSchemaId: setSynSchemaId,
        synSchemaInfo: synSchemaInfo,
        loadingSynSchemaInfo: loadingSynSchemaInfo,
        fetchSynSchemaInfo: fetchSynSchemaInfo,
        realConnectorId: realConnectorId,
        setRealConnectorId: setRealConnectorId,
        realSchemaInfo: realSchemaInfo,
        loadingRealSchemaInfo: loadingRealSchemaInfo,
        fetchRealSchemaInfo: fetchRealSchemaInfo
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};
