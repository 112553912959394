import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';
import { LinkButton } from 'src/components/Button/LinkButton';
import * as S from 'src/views/Project/ChangeProject.styles';
import { Modal } from 'src/components/Modal/Modal';
import { useFiles } from 'src/contexts/FileContext';
import { useConnectors } from 'src/contexts/ConnectorContext';
import { useSchema } from 'src/contexts/SchemaContext';

export const ChangeProject = ({ isOpen, setIsOpen, fetchUsersFromProject, setMultiTableView }) => {
  const { currentUser } = useUser();
  const { fetchConnectors } = useConnectors();
  const { fetchFiles } = useFiles();
  const { fetchSchemaSummary } = useSchema();
  const { getCurrentProjectId, setCurrentProjectId } = useStorage();

  const onSelectProject = (project) => {
    // close modal on select project
    setIsOpen(false);
    // if selected project not equal current project, set and fetch selected project info
    if (project.id !== getCurrentProjectId()) {
      setCurrentProjectId(project.id);
      setMultiTableView(false);
      fetchConnectors();
      fetchFiles();
      fetchSchemaSummary();
      fetchUsersFromProject();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Change Project"
      buttonText="Cancel"
      buttonAction={() => setIsOpen(false)}
    >
      {currentUser.projects.map((project) => (
        <S.Wrapper key={project.id}>
          <li>
            <LinkButton onClick={() => onSelectProject(project)}>
              {project.name}
            </LinkButton>
          </li>
        </S.Wrapper>
      ))}
    </Modal>
  );
};
