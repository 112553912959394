import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { buttonCss } from 'src/components/Button/Button.styles';
import { Theme } from 'src/components/Theme';

export const A = styled.a`
  font-size: ${Theme.fontSize.small};
  cursor: pointer;
  &:hover {
    color: ${Theme.colors.pitchblack};
  }
  ${Theme.breakpoints.laptop} {
    font-size: ${Theme.fontSize.normal};
  }
`;

export const StyledLink = styled(RouterLink)`
  font-size: ${Theme.fontSize.small};
  cursor: pointer;
  &:hover {
    color: ${Theme.colors.pitchblack};
  }
  ${Theme.breakpoints.laptop} {
    font-size: ${Theme.fontSize.normal};
  }
`;

export const StyledButtonLink = styled(RouterLink)`
  ${buttonCss}
`;
