import { jwtDecode } from "jwt-decode";

import { roles } from 'src/api/roles';

const currentRole = (token) => {
  const jwtToken = jwtDecode(token);
  const jwtRoles = jwtToken.scope.split(' '); // convert string of scopes to array

  let currentRole = '';
  let isDefaultRole = true;
  for (const role in roles) {
    if (jwtRoles.indexOf(role) !== -1) { // look for matching scope
      currentRole = jwtRoles[jwtRoles.indexOf(role)];
      isDefaultRole = false;
      break;
    }
  }

  if (isDefaultRole) {
    currentRole = Object.keys(roles)[0]; // set user as role for this authenticated user.
  }

  return currentRole;
};

export default currentRole;