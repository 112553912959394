import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';

const ProtectedRoute = ({ component, ...args }) => {
  const AuthenticatedComponent = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingSpinner/>
  });
  return <AuthenticatedComponent {...args} />;
};

export default ProtectedRoute;
