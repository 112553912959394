import React from 'react';
import { Theme } from 'src/components/Theme';
import { StyledButton } from 'src/components/Button/Button.styles';
import { Size, Variant } from 'src/components/Button/ButtonOptions';

export const Button = ({
  color = Theme.colors.red,
  bgColor = Theme.colors.TranparantOrange,
  variant = Variant.filled,
  hover = Theme.colors.litepink,
  size = Size.small,
  disabled = false,
  onClick,
  children,
  ...props
}) => (
  <StyledButton
    color={color}
    bgColor={bgColor}
    variant={variant}
    hover={hover}
    size={size}
    disabled={disabled}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
    }}
    {...props}
  >
    {children}
  </StyledButton>
);
