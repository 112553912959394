import { useCallback,useState} from 'react';
import { useApi } from 'src/api/useApi';
import { navigatorClipboard } from 'src/helpers/navigatorClipboard';
import { datasetService } from 'src/api/services/datasetService';
import { Modal } from 'src/components/Modal/Modal';

export const ShareSyntheticFileModal = ({ isOpen, setIsOpen, synFile }) => {
  // Getting synthetic file url from the current project.
  const getSyntheticFileUrl = useCallback(
    (token) => datasetService.getSyntheticDatasetUrl({ token: token, fileId: synFile.id }),
    [synFile.id]
  );
  const [isCopied, setIsCopied] = useState(false);
  const { data: fileUrl, error } = useApi({ fetcher: getSyntheticFileUrl, fetchOnLoad: true });

  return (
    <Modal
      isOpen={isOpen}
      error={error}
      bigTitle="Share synthetic file"
      title={synFile.filename}
      buttonText={!error?"Copy link":""}
      description={!error?isCopied?"Link has been copied to your clipboard":"Click to copy a link (to share) that downloads the synthetic file":""}
      linkText="Cancel"
      buttonAction={() => {navigatorClipboard(fileUrl); setIsCopied(true);}}
      linkAction={() => setIsOpen(false)}
    />
  );
};
