import {
    useContext,
    useCallback,
    createContext,
} from "react";
import { connectorService } from "src/api/services/connectorService";
import { useApi } from "src/api/useApi";
import { useStorage } from "src/contexts/useStorage";
import { Modal } from "src/components/Modal/Modal";

export const ConnectorContext = createContext({});

export const useConnectors = () => {
  return useContext(ConnectorContext);
};

export const ConnectorProvider = ({ children }) => {
    const { getCurrentProjectId } = useStorage();

    const getConnectors = useCallback(
        (token) => connectorService.getConnectors({ token: token, projectId: getCurrentProjectId() }),
        [getCurrentProjectId]
    );

    const { data: connectors, error, setError, fetch } = useApi({
        fetcher: getConnectors,
        fetchOnLoad: true
    });

    if (error) {
        return <Modal
            isOpen={!!error}
            type="error"
            description={error}
            buttonText="Try again"
            buttonAction={() => setError('')}
        />;
    }

    const returnDForOthers = ({ connectors, getDF = true }) => {
        if (typeof connectors === 'undefined') return [];
        if (getDF) return connectors.find(c => c.type === "DF");
        else return connectors.filter(c => c.type !== "DF");
    };

    return (
        <ConnectorContext.Provider
            value={{
                fetchConnectors: fetch,
                DFConnector: returnDForOthers({ connectors: connectors }),
                databaseConnectors: returnDForOthers({ connectors: connectors, getDF: false })
            }}
        >
            {children}
        </ConnectorContext.Provider>
    );
};