import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = (props) => {
  //Config from Auth0 from .env file
  // Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
  // for a full list of the available properties on the provider
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const scopes = process.env.REACT_APP_AUTH0_SCOPE.replace(/"/g, ''); //Scopes from env file come with quotes "

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        scope: scopes,
        audience: audience,
        redirect_uri: window.location.origin
      }}
    >
      {props.children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
