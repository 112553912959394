import { callApi } from "src/api/services/aux";

export const projectService = {
    // Endpoint for post project name. If is_play_project is true a play project with be created instead.
    postProject: ({ token, name, is_play_project = false }) => callApi({
        token: token,
        method: "POST",
        endpoint: `/project?is_play_project=${is_play_project}`,
        options: { body: is_play_project ? null : JSON.stringify({ name }) }
    }),
    // Endpoint for patch project with project id and project name.
    patchProject: ({ token, name, id }) => callApi({
        token: token,
        method: "PATCH",
        endpoint: "/project",
        options: { body: JSON.stringify({ name, id }) }
    }),
    // Endpoint for delete project with id.
    deleteProject: ({ token, id }) => callApi({
        token: token,
        method: "DELETE",
        endpoint: "/project",
        params: { id: id }
    })
};