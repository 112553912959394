import { Button } from 'src/components/Button/Button';
import { Size } from 'src/components/Button/ButtonOptions';
import { LinkButton } from 'src/components/Button/LinkButton';
import { H7 } from 'src/components/Headers/Headers.style';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { SuccessIcon } from 'src/assets/icons/SuccessIcon';
import { Spinner } from 'src/components/Loaders/Spinner';
import { ProgressSpinner } from 'src/components/Loaders/ProgressSpinner';

export const MultiTabelModal = ({
  isOpen,
  type,
  bigTitle,
  title,
  error,
  text,
  description,
  buttonText,
  buttonText2,
  buttonDisabled,
  linkText,
  linkText2,
  buttonAction,
  buttonAction2,
  linkAction,
  linkAction2,
  children,
  progress,
}) => {
  return (
    <div className={`${isOpen?"fixed":"hidden"} inset-0 flex items-center justify-center z-10 overflow-auto`}>
      <div className="modal-overlay bg-black opacity-50 fixed inset-0" />
      <div className="modal-container w-2/5 max-md:w-4/5 z-50 ">
        <div className="bg-white shadow-3xl p-8 m-auto padding-x">
          <div className='flex flex-col justify-center items-center gap-5'>
            {type === 'success' && <SuccessIcon />}
            {type === 'error' && <ExclamationIcon />}
            {type === 'loading' && <Spinner />}
            {type === 'progress-spinner' && <ProgressSpinner progress={progress}/>}
            {bigTitle && <div className='H3'>{bigTitle}</div>}
            {title && <div className='H5'>{title}</div>}
            {error && (
              <div className='error-style'>
                <ExclamationIcon /> {error}
              </div>
            )}
            {text && <H7>{text}</H7>}
            {description && <p>{description}</p>}
            {children}
            <div className=" flex justify-center items-center gap-4">
              {buttonText && (
                <Button
                  size={Size.medium}
                  disabled={buttonDisabled}
                  onClick={buttonAction}
                >
                  {buttonText}
                </Button>
             )}
              {buttonText2 && (
                <Button
                  size={Size.medium}
                  disabled={buttonDisabled}
                  onClick={buttonAction2}
               >
                  {buttonText2}
                </Button>
              )}
           </div>
            {linkText && <LinkButton onClick={linkAction}>{linkText}</LinkButton>}
            {linkText2 && <LinkButton onClick={linkAction2}>{linkText2}</LinkButton>}
          </div>
        </div>
      </div>
    </div>
  );
};
