import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const Container = styled.div`
    ${(props) => !props.isOpen && 'display: none;'}
    background: rgba(82, 82, 82, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
`;

export const ModalPaper = styled(Paper)`
  display: flex;
  align-content: center;
  align-items:center;
  flex-direction: column;
  margin: auto;
  position: relative;
  top:50%;
  transform: translateY(-50%);
  width: 44%;
  padding: 2rem;
  box-sizing: content-box;
  margin-top: 10;
  p{
    font-size: ${Theme.fontSize.small};
  }
  H5,H6 {
    margin-top: 1rem;
    line-height: 2rem;
  }
  Button {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    width: 10.5rem;
    cursor: pointer;
  }
  svg {
    width: 100px;
    height: 100px;
    padding: 1rem;
  }
  ${Theme.breakpoints.medium} {
    width: 80%;
  }
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  font-weight: bold;
  font-size: ${Theme.fontSize.small};
  color:red;
 svg{
  margin-left:1.5rem;
 }
`;