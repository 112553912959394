import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'src/api/useQuery';
import { InviteProject } from 'src/components/Project/InviteProject'
import { SetProjectName } from 'src/views/Project/SetProjectName';
import { StartCreateProject } from 'src/views/Project/StartCreateProject';
import { PageNotFound } from 'src/views/PageNotFound/PageNotFound';

export const CreateProjectSteps = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const step = query.get('step');

  useEffect(() => {
    if (!step) {
      navigate(`?step=1`);
    }
  });

  return (
    <>
      {step === "1" ? (
        <StartCreateProject />
      ) : step === "2" ? (
        <SetProjectName />
      ) : step === "3" ? (
        <InviteProject />
      ) : (
        <PageNotFound />
      )}
    </>
  );
};
