import { roundToDecimals } from "src/helpers/numberHelper";
import { CorrelationChart } from "src/views/Files/Evaluation/Correlation/CorrelationChart";
import { getMissingAttributes } from "src/helpers/getMissingAttributes";

export const BottomCorrelation = ({ title, sub }) => (
  <div className="half-screen-wrapper">
    <div className="px-4 pt-4 pb-8 eval-border">
      <div className="H6">{title}</div>
      <p>{sub}</p>
    </div>
  </div>
);

export const Correlation = ({
  realMData,
  synMData,
  evaluation,
  isMutualInfo = false,
}) => {
  //Note: if isMutualInfo then mutual information is returned, otherwise correlation
  const aux1 = `${isMutualInfo ? "mutual_information" : "correlation"}_matrix`;
  const aux2 = `${isMutualInfo ? "mutual_information" : "corr"}_euclidean_`;
  const aux3 = `${isMutualInfo ? "Mutual Information" : "Correlation"}`;
  // Getting real and synthetic datasets correlation attributes and values
  const realAttributes = realMData[aux1].attributes;
  const realValues = realMData[aux1].values;
  const synAttributes = synMData[aux1].attributes;
  const synValues = synMData[aux1].values;
  // Get correlation distance
  const distance = evaluation[`${aux2}distance`];
  const distanceMsg = `${aux3} euclidean distance:`;
  // Get missing attributes
  const missingAttributes = getMissingAttributes(
    evaluation[`${aux2}missing_attr`]
  );
  // Auxiliary arrays to map
  const correlationAttributesValues = [
    ["Real dataset", realAttributes, realValues],
    ["Synthetic dataset", synAttributes, synValues]
  ];
  const secondBlockVars = [
    ["Missing attributes:", missingAttributes],
    [distanceMsg, roundToDecimals(distance, 4)],
  ];

  return (
    <>
      <div className="eval-paper">
        {correlationAttributesValues.map((item, i) => (
          <CorrelationChart
            key={i}
            title={item[0]}
            correlationAttributes={item[1]}
            correlationValues={item[2]}
          />
        ))}
        {secondBlockVars.map((item, i) => (
          <BottomCorrelation key={i} title={item[0]} sub={item[1]}/>
        ))}
      </div>
    </>
  );
};