import map from 'src/assets/images/map.png';
import { H6, H4 } from 'src/components/Headers/Headers.style';
import { ExternalLink } from 'src/components/Link/Link';
import { Modal } from 'src/components/Modal/Modal';
import { Column, Container, MapWrapper, Wrapper } from 'src/views/Contact/Contact.styles';

export const Contact = ({ isOpen, setIsOpen }) => (
  <Modal isOpen={isOpen} title="Contact us" linkText="Cancel" linkAction={() => setIsOpen(false)}>
    <Wrapper>
      <Container>
        <Column>
          <H4>Topic</H4>
        </Column>
        <Column>
          <H4>Mail</H4>
        </Column>
      </Container>
      <Container>
        <Column>
          <p>Support (tech or models)</p>
        </Column>
        <Column>
          <p>
            <ExternalLink href="mailto:support@syndata.co">support@syndata.co</ExternalLink>
          </p>
        </Column>
      </Container>
      <Container>
        <Column>
          <p>Further inquiries</p>
        </Column>
        <Column>
          <p>
            <ExternalLink href="mailto:info@syndata.co">info@syndata.co</ExternalLink>
          </p>
        </Column>
      </Container>
      <Container>
        <Column>
          <p>Finance</p>
        </Column>
        <Column>
          <p>
            <ExternalLink href="mailto:invoices@syndata.co">invoices@syndata.co</ExternalLink>
          </p>
        </Column>
      </Container>
      <MapWrapper>
        <H6>Visit us</H6>
        <ExternalLink href="https://www.google.com/maps/place/Medborgarplatsen+25,+118+72+Stockholm/@59.3158652,18.0682726,17z/data=!3m1!4b1!4m5!3m4!1s0x465f77fac7c0361d:0x3fce39ea2b9a932b!8m2!3d59.3158652!4d18.0704613">
          Medborgarplatsen 25
        </ExternalLink>
        , 118 72 Stockholm
      </MapWrapper>
      <div className='flex justify-center'>
      <img alt="map" src={map} />
      </div>
    </Wrapper>
  </Modal>
);
