import { Theme } from 'src/components/Theme';
import { StyledLinkButton } from 'src/components/Button/LinkButton.styles';

export const LinkButton = ({ hover = Theme.colors.pitchblack, onClick, children, ...extraProps }) => {
  return (
    <StyledLinkButton
      hover={hover}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
      {...extraProps}
    >
      {children}
    </StyledLinkButton>
  );
};
