import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from 'src/api/useApi';
import { Modal } from 'src/components/Modal/Modal';
import { ModelPaper } from 'src/views/Files/Generate/ModelPaper';
import { modelService } from 'src/api/services/modelService';
import { useTask } from "src/api/services/useTask";
import { useFiles } from 'src/contexts/FileContext';

export const Model = ({ model, fileId }) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const navigate = useNavigate();
  const { fetchFiles } = useFiles();
  // Variable for typing the number of rows for data generation
  const [nrRows, setRows] = useState('');
  // Variable for typing extra input parameters
  const [extraInput, setExtraInput] = useState('');

  // set taskCallable
  const taskCallable = (taskPayload) => {
    // get syntheticFileId from taskPayload
    let syntheticFileId = taskPayload.synthetic_dataset_id
    // get latest information
    fetchFiles();
    // navigate to evaluation
    navigate(`/evaluation?fileId=${fileId}&synFileId=${syntheticFileId}`);
  }

  const { fetch, error, setError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setError
      })
    ),
    fetcher: useCallback(
      (token) => modelService.postTrainGenerate({
        token: token, modelId: model.id, fileId: fileId, nRows: Number(nrRows), body: extraInput
      }),
      [model, fileId, nrRows, extraInput]
    )
  });

  return (
    <>
      <ModelPaper
        key={model.id}
        modelId={model.id}
        image={model.meta_data.webapp.model_image_url}
        title={model.modelname}
        description={model.description}
        time={model.meta_data.time}
        value={model.meta_data.analytical_value}
        risk={model.meta_data.disclosure_risk}
        fileId={fileId}
        rows={nrRows}
        setRows={setRows}
        extraInput={extraInput}
        setExtraInput={setExtraInput}
        buttonText="Generate"
        buttonAction={() => {
          fetch();
          initTask({ initStatus: "Training" });
        }}
      />
      {taskMonitoring && <Modal
        isOpen={taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {!!error && <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
        }}
      />}
    </>
  );
};
