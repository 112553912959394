import { useState } from 'react';
import { useToken } from 'src/contexts/TokenContext';
import { userService } from 'src/api/services/userService';
import { userProjectService } from 'src/api/services/userProjectService';

// Hook for re-using invite logic between different components.
export const useInvite = () => {
  // hooks
  const { token } = useToken();

  // Show successful modal.
  const [showSuccess, setShowSuccess] = useState(false);
  // List of all the emails the user wants to invite.
  const [emails, setEmails] = useState(['']);
  // List of the roles connected to the emails.
  const [roles, setRoles] = useState(['user']);
  // State of api calls
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Add another email input.
  const addEmail = () => {
    setEmails((current) => [...current, '']);
  };

  const inviteUser = async (projectId, email, role) => {
    // Check if user exists in the organization.
    let response = await userService.getUsers({ token: token, projectId: null, email: email });
    let userData = await response.json();
    if (!response.ok) {
      setError(userData.detail);
      return;
    }
    // If user does not exist in the organization, create (add) it.
    if (userData.length === 0) {
      response = await userService.postUser({ token: token, email: email, role: role });
      userData = await response.json();
      if (!response.ok) {
        setError(userData.detail);
        return;
      }
    } else {
      userData = userData[0];
    }
    // Add the user to the project.
    response = await userProjectService.postUsersProjects({ token: token, userId: userData.id, projectId: projectId });
    let data = await response.json();
    if (!response.ok) {
      // The user is already a part of the project.
      if (response.status === 400 && data.detail === 'User already belongs to project') {
        return;
      }
      setError(data.detail);
    }
  }

  const inviteUsers = async (projectId) => {
    setLoading(true);
    // Placeholder for inviting users promises
    let inviteUsersPromises = [];
    // Loop through emails to invite users to the project.
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      const role = roles[i];
      if (!email) {
        continue;
      }
      // Invite user (and push promise to placeholder)
      inviteUsersPromises.push(inviteUser(projectId, email, role));
    }
    // Gather inviteUsersPromises and await them
    await Promise.all(inviteUsersPromises);
    setEmails(['']);
    setLoading(false);
    setShowSuccess(true);
  };

  return {
    showSuccess,
    setShowSuccess,
    emails,
    setEmails,
    roles,
    setRoles,
    loading,
    setLoading,
    error,
    setError,
    addEmail,
    inviteUsers,
  };
};
