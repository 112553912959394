export const Questions = {
  models: [
    {
      question: 'What are models?',
      answer: "Models are Syndata's machine learning algorithms for creating synthetic data.",
      id: 1,
    },
    {
      question: 'Which models are there?',
      answer:
        "There are two models available to choose from: Random and Realistic. Each model has it's own characteristics resumed in their metrics.",
      id: 2,
    },
    {
      question: 'What is the time metric?',
      answer:
        "It's a metric for indicating how long does a model takes to train and generate synthetic data. The less the metric value, the faster it is to train and generate synthetic data.",
      id: 3,
    },
    {
      question: 'What is analytical value metric?',
      answer:
        "It's a metric for indicating how well a model preserves statistical relationships, hence how well does the resulting generated synthetic dataset preserves analytical value. The more the metric value, the better the synthetic data file preserves statistical relationships from original file.",
      id: 4,
    },
    {
      question: 'What is disclosure risk metric?',
      answer:
        "It's a metric for indicating how well does a model to avoid disclosing individual rows from the original dataset in the resulting synthetic dataset. The less the metric value, the less risk of disclosure the synthetic data is deemed to have.",
      id: 5,
    },
    {
      question: 'Is there a relationship between the metrics?',
      answer:
        'Faster models (for example Random), in general, have less analytical value and less risk of disclosure. Usually the more analytical value a model has, the longer the it will take to train (resulting in higher time metric value). Additionally, more analytical value usually carries a higher risk of disclosure.',
      id: 6,
    },
    {
      question: 'Which model should I choose?',
      answer:
        'It depends on your purpose of synthesizing. As a rule of thumb: Random use for testing purposes, where the form of the data is interesting (disregarding analytical value or statistical relationships in the data). Realistic use for more advanced cases (e.g. analysis or machine learning modeling).',
      id: 7,
    },
  ],
  parameters: [
    {
      question: 'What are parameters?',
      answer:
        'All dataset files have parameters for each of their columns. These are, among others, the type of data of the column (if it is an integer, a datetime, etc.), if the values in the column are unique for each row, or if the column is a category (example female or male).',
      id: 8,
    },
    {
      question: 'Why do I need to set parameters?',
      answer:
        "Setting parameters correctly helps the synthetization models to produce better results. Syndata's Engine preprocess a file when it is uploaded and gives a guesstimation of the parameters. As a user you don't have to set parameters to generate, you can just confirm the ones Syndata's Engine proposed. If results are not satisfactory though, we recommend to review the parameters for the different columns and synthesize again if there are changes.",
      id: 9,
    },
    {
      question: 'Which parameters can I set?',
      answer:
        'You can set: the datatype of a column, if a column only has unique values and if a column is a category.',
      id: 10,
    },
    {
      question: 'What is an attribute?',
      answer: 'An attribute is a column.',
      id: 11,
    },
    {
      question: 'What is a data type?',
      answer: `A datatype is the different types of data that Syndata's Engine recognize for each column. There are five types. 
        1. String: includes characters and text. Example: names of persons.
        2. Integer: numbers without decimal. Used for measuring values without decimal numbers. Example: number of children a person has.
        3. Float: numbers with decimals. Used for measuring values. Example: height of persons in centimeters e.g. 1.75 cm, 1.80 cm, etc.
        4. Date Time: dates or dates with a timestamp. Example: birthdate of a list of persons.
        5. Constant: this is data that only consist of one value per column, ie there is no variability in the column values.`,
      id: 12,
    },
    {
      question: 'What is a unique value attribute?',
      answer:
        'Unique value attributes or columns are those that have a unique value for each row. These attributes are uninteresting for synthetization since each value is specific for each row so not much can be inferred from them, in the statistical sense. Only columns that have unique values per row can by marked as a unique values attribute.',
      id: 13,
    },
    {
      question: 'What is a categorical attribute?',
      answer:
        'A categorical attribute are those that hold a specific set of outcomes, usually a string datatype (for example male or female). The specific set is limited to a number of outcomes (or categories).',
      id: 14,
    },
    {
      question: 'Categorical attributes vs integer datatype',
      answer:
        'Categorical attributes and integer data types are similar in the sense that categories could be seen as having a corresponding integer number (a map). The difference is that the integer set is not be bounded, ie integer data types do not necessarily need to be categorical. Integer data types additionally hold an ordinal meaning to the information they hold. To exemplify, the number of children a person has can be seen as an Integer category (for example 0, 1, 2) with the maximum being the maximum number of children one person has in the dataset. It can also be seen as an Integer non categorical, and the integer value has meaning (2 children are twice the number of children than 1).',
      id: 15,
    },
    {
      question: "Why can't I change to different data types or change unique values settings?",
      answer: `The Synthetic Engine prohibits to use data types that do not correspond to what the data contains. 
        This includes using datatype Constant for attributes that do not hold only one value (ie a constant). 
        Another example is to set as DateTime a datatype that is not a date or datetime (for example, a name or a number).
        The same principle applies for trying to set an attribute as unique values, if the attribute contains nulls or repeated values.`,
      id: 16,
    },
  ],
  project: [
    {
      question: 'What is a project?',
      answer:
        "A project is a group of members and files (datasets). Members of the project can synthesize files and evaluate the synthetization. Members of a project will not be able to see files from projects that they don't belong to. You can always change the project you are viewing for another project you belong to. Admin role users can also create, rename and delete projects.",
      id: 17,
    },
    {
      question: 'What can I do within a project?',
      answer:
        'You as member of a project can upload and synthesize files, as well as evaluate the synthetization, all within the project. If a member has role admin, they can invite other members to the project and do admin tasks on the project (such as renaming the project, or deleting it).',
      id: 18,
    },
    {
      question: 'User vs Admin',
      answer:
        'Members can have 2 roles: user and admin. Admin role includes all the functionality of the user role (file uploading, synthesizing and evaluation among others). Additionally, admins are able to create, rename, and delete projects as well as to manage project members.',
      id: 19,
    },
    {
      question: 'What happens if I delete a project?',
      answer:
        'Currently this function is just for users with role admin. All the files, synthetic files and trained models related to the project will be deleted when you delete a project. This applies for you and for the other members of the project, and the information will be lost and is not recoverable.',
      id: 20,
    },
    {
      question: 'Manage project members',
      answer: `Currently this function is just for users with role admin. In the sidebar, click on Settings, and you will see the list of members of the project you are currently viewing, alongside their respective roles. As an admin you can:
        - Change other members role
        - Remove a member from a project
        - Add new members to the project (with their respective role). New members will receive a mail invitation for accessing Syndata's Engine.`,
      id: 21,
    },
  ],
  files: [
    {
      question: 'What are files?',
      answer: 'Files are datasets that users would like to synthesize.',
      id: 22,
    },
    {
      question: 'What type of files can I upload?',
      answer:
        'Files must be tabular data in a CSV file, holding individual entities information (such as one customer per row) and not a series of events. The first row of the CSV must contain the columns (or attributes) names. Columns in the CSV must be separated by commas.',
      id: 23,
    },
    {
      question: 'How can I download a file?',
      answer:
        'Click on the 3 dots within the file row and "Download". You can download the file to your own computer directly or generate a link to send to download the file to you or others via mail.',
      id: 24,
    },
    {
      question: 'What happens if I delete a file?',
      answer:
        'When you delete a file from a project, the file will no longer be available, for you and for the other members of the project. The file information will be lost and is not recoverable.',
      id: 25,
    },
    {
      question: 'Do you have more questions?',
      answer: `If you have further questions that we haven't addressed please ask us at support@syndata.co.`,
      id: 26,
    },
  ],
  testProject: [
    {
      question: 'What is a test project?',
      answer:
        'A test project is a project that contains pre uploaded files for you to start synthesising.',
      id: 27,
    },
    {
      question: 'What can I do within a test project?',
      answer:
        'A test project functions the same way as a real project, except it has pre uploaded files for you to start synthesising. This means that with a test project you can do everything you can do with a real project.',
      id: 28,
    },
  ],
  evaluation: [
    {
      question: 'What is evaluation framework?',
      answer: `It is difficult to evaluate the quality of synthetic data. By quality we mean that the statistical relationships present in the original data are replicated in the synthetic generated data. For this purpose we have created an evaluation framework within Synapp.
      We evaluate different aspects of the generated synthetic data, such as comparing individual distributions similarity, joint distributions similarity and null values relationships.`,
      id: 31,
    },
    {
      question: 'What is overall quality?',
      answer:
        'The overall quality view within the evaluation framework gives you metrics for the different criteria we have for evaluating a synthetic dataset. The color of the criteria indicate that the metric is of good quality (green) or less quality (red), the higher the metric is the less quality that criteria has.',
      id: 32,
    },
    {
      question: 'What are the 3 criteria you evaluate?',
      answer: `We evaluate i) how similar are individual distributions, called distributions similarity; ii) how similar are joint-distributions relationships and iii) how similar are null values relationships. All comparison are between the uploaded (real) dataset and the synthetic generated dataset.`,
      id: 33,
    },
    {
      question: 'What is distributions similarity?',
      answer:
        'The distribution similarity metric gives the average of Wasserstein distances between individual distributions. A metric close to 0 means the individual distributions are very similar.',
      id: 34,
    },
    {
      question: 'What is joint-distributions similarity?',
      answer:
        'The joint-distributions similarity metric gives the Euclidean distance between the correlation matrixes of the uploaded (real) dataset and the synthetic generated dataset. A metric close to 0 means the matrixes are very similar.',
      id: 35,
    },
    {
      question: 'What is null values evaluation?',
      answer:
        'The null values evaluation metric gives the Euclidean distance between the correlation of transformed matrixes for null values of the uploaded (real) dataset and the synthetic generated dataset. A metric close to 0 means the matrixes are very similar.',
      id: 36,
    },
    {
      question: 'How can I make my results better?',
      answer:
        'Synthesising can be challenging, even with a powerful engine like Synapp. Better results can be achieved by using a more powerful model, or by better adjusting for parameters before training and generating. Contact us if you need help to improve your results.',
      id: 37,
    },
  ],
};
