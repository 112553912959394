import { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from 'src/assets/icons/ArrowDownIcon';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
} from 'src/components/Dropdown/DropdownDataTypes.styles'

export const DropdownDataTypes = ({ disabled = false, onChange, dataType }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  // List of the data types options and set the given data type.
  const dataTypeOptions = [
    { label: 'String', value: 'String' },
    { label: 'Integer', value: 'Integer' },
    { label: 'Float', value: 'Float' },
    { label: 'Date Time', value: 'DateTime' },
    { label: 'Constant', value: 'Constant' },
  ];

  const selectedDataType = dataTypeOptions.find((o) => o.value === dataType) || dataTypeOptions[0];

  const toggling = () => setOpen((prevOpen) => !prevOpen);

  // Handle the data type clicked to change according the value.
  const dataTypeClicked = (value) => (e) => {
    onChange(value);
    setOpen(false);
    e.preventDefault();
  };

  // Handle mouse clicks inside and outside the dropdown to know when to close it.
  const handleMouseClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  // Event listener for mouse clicks.
  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <DropDownContainer ref={ref} disabled={disabled}>
      <DropDownHeader onClick={toggling} data-testid="dropdown-data-type">
        {selectedDataType.label} <ArrowDownIcon />
      </DropDownHeader>
      {open && !disabled && (
        <div>
          <DropDownList>
            {dataTypeOptions.map((option) => (
              <ListItem
                data-testid="select-data-type"
                onClick={dataTypeClicked(option)}
                key={option.value}
              >
                {option.label}
              </ListItem>
            ))}
          </DropDownList>
        </div>
      )}
    </DropDownContainer>
  );
};
