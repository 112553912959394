import { H6 } from 'src/components/Headers/Headers.style';
import { RecIcon } from 'src/assets/icons/RecIcon';
import { SubTable } from 'src/views/Files/Evaluation/Table/SubTable';

export const Table = ({ realFileId, synFileId }) => {
  // Aux array to render
  const auxArray = [
    ["Real dataset", "real", realFileId],
    ["Synthetic dataset", "syn", synFileId]
  ];

  return (
    <div className='paper p-4 mt-8 overflow-visible overflow-x-scroll overflow-y-hidden '>
      {auxArray.map((item, i) => (
        <>
          <H6 key={i} className='flex justify-start items-center'>
            <div className='mr-[0.625rem]'>{item[0]}</div>
            <RecIcon realOrSyn={item[1]}/>
          </H6>
          <SubTable key={i} realOrSyn={item[1]} fileId={item[2]} />
        </>
      ))}
    </div>
  );
};