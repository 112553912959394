import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const ToggleDiv = styled.div`
  width: 3.75rem;
  display: block;
  height: 1.375rem;
  z-index: 1;
  margin: 0.188rem;
  border-radius: 1.25rem;
  width: 3.75rem;
  height: 1.375rem;
  background: ${(props) => (props.checked ? Theme.colors.red : Theme.colors.lightgray)};
  transition: 0.2s;
`;