// Function that accepts a numeric value with decimals and rounds the value to the given amount of decimals.
export const roundToDecimals = (value, nrOfDecimals = 2) => {
  const exp = Math.pow(10, nrOfDecimals);

  // The Number.EPSILON property represents the difference between 1 and the smallest floating point number greater than 1.
  return Math.round((value + Number.EPSILON) * exp) / exp;
};

export const roundIfNumber = (value) => {
  if (typeof value === 'number') {
    return roundToDecimals(value, 2);
  }
  return value;
};
