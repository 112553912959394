import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const UserNameWrapper = styled(Paper)`
  padding: 2rem;
  H1 {
    padding-bottom: 1rem;
  }
  p {
    padding-bottom: 4rem;
  }
  Button {
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
  ${Theme.breakpoints.laptop} {
    padding: 4rem 5rem;
    Button {
      margin-top: 4rem;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -3rem;
  color: red;
  font-weight: bold;
  font-size: ${Theme.fontSize.small};
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;
