import { useConnectors } from "src/contexts/ConnectorContext";
import { RealSchemas } from "src/views/Files/MultiTable/RealSchemas";
import { RealFiles } from "src/views/Files/RealFiles/RealFiles";
import { Wrapper } from "src/views/Home/Home.styles";

const Home = ({ isMultiTableView, setMultiTableView }) => {
  const { databaseConnectors } = useConnectors();

  //Auxiliary array for toggle button
  const aux = [
    ["rounded-r-none", !isMultiTableView, false, "Datasets"],
    ["rounded-l-none", isMultiTableView, true, "Schemas"],
  ];

  return (
    <Wrapper data-testid="app">
      <div className="viewHeader">Data</div>
      {/* Don't display toggle button when non-DF connectors not available */}
      {databaseConnectors.length > 0 && (
        <div
          className="mb-10 flex justify-center gap-0"
          data-testid="toggle-view"
        >
          {aux.map((item, i) => (
            <button
              key={i}
              className={`button-style text-orange max-md:px-10 px-20 ${item[0]} text-sm
                ${item[1] && " bg-DarkTransparantOrange font-semibold border border-TransparantOrange"}`}
              onClick={() => setMultiTableView(item[2])}
            >
              {item[3]}
            </button>
          ))}
        </div>
      )}
      {isMultiTableView ? (
        <RealSchemas setMultiTableView={setMultiTableView} />
      ) : (
        <RealFiles />
      )}
    </Wrapper>
  );
};

export default Home;
