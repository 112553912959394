import { Navigate } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';
import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';

export const LoginRedirect = () => {
  const { currentUser, loadingUser } = useUser();
  const { setCurrentProjectId } = useStorage();

  if (!loadingUser) {
    const { projects, role, name, last_name } = currentUser;
    // Will render according to the if statement.
    // If there is not name and last name render CreateUserName component.
    if (!name && !last_name) {
      return <Navigate to="/onboarding/user-name" />
      // Else if there is not project and you're an admin render CreateProject component.
    } else if (projects.length === 0 && role === 'admin') {
      return <Navigate to="/onboarding/create-project" />
      // Else if there is not project and you're a user render JoinProject component.
    } else if (projects.length === 0 && role === 'user') {
      return <Navigate to="/onboarding/user-no-project" />
      // Else if there is one project render Home component.
    } else if (projects.length === 1) {
      // setting current project to avoid going to /onboarding/select-project
      setCurrentProjectId(projects[0].id);
      return <Navigate to="/" />
      // Else two projects or more render SelectProject component.
    } else {
      return <Navigate to="/onboarding/select-project" />
    }
  }
  return <LoadingSpinner />
};
