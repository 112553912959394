import { callApi } from "src/api/services/aux";

export const modelService = {
    // Endpoint for getting the synthetic models for data generation.
    getModels: ({ token }) => callApi({
        token: token,
        method: "GET",
        endpoint: "/synthetic_models"
    }),
    // Endpoint for train and generate one synthetic dataset with
    // model id, id and number of rows, as well as body (extra input params)
    // Body param is destructured for passing undefined values
    postTrainGenerate: ({ token, modelId, fileId, nRows, body }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/train_and_generate",
        options: {
            body: JSON.stringify({
                ...(body && { extra_input_parameters: JSON.parse(body) }),
            })
        },
        params: {
            syn_model_id: modelId,
            id: fileId,
            n_rows: nRows
        }
    }),
    // Endpoint for generate one synthetic dataset with trained model id and number of rows.
    postGenerate: ({ token, trainedModelId, nRows }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/generate",
        params: {
            trained_model_id: trainedModelId,
            n_rows: nRows,
        }
    }),
    // Endpoint for training a real schema by connector id
    trainSchema: ({ token, synModelId, connectorId, body, multiProcess }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/mt/train",
        params: {
            syn_model_id: synModelId,
            connector_id: connectorId,
            ...(multiProcess && { multi_process: multiProcess })
        },
        options: {
            body: JSON.stringify({
                ...(body && JSON.parse(body))
            })
        }
    }),
    //Endpoint to generate a real schema by trained mt_model_id with number of rows multiplier (nrow_factor)
    generateSchema: ({ token, mtModelId, nrowFactor = 1 }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/mt/generate",
        params: {
            ...(mtModelId && { mt_model_id: mtModelId }),
            ...(nrowFactor && { nrow_factor: nrowFactor })
        }
    })
};