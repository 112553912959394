import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const SelectTeamWrapper = styled(Paper)`
padding: 3rem 2rem;  
${Theme.breakpoints.laptop} {
    padding: 4rem 3rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  border: none;
  border-bottom: 0.1rem solid ${Theme.colors.black};
  flex: 1;
  margin-top: 3rem;
  H6 {
    margin: 1rem 0;
  }
`;
export const Row = styled.div`
  border: none;
  border-bottom: 0.1rem solid ${Theme.colors.lightgray};
  flex: 1;
  padding: 1rem;
  H6 {
    margin: 1rem 0;
  }
`;

export const ButtonWrapper = styled.div`
 margin-top: 4rem;
`;
