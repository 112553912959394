import { Modal } from 'src/components/Modal/Modal';
import { wordDelimiter } from 'src/helpers/wordDelimiter';
import { useFileRows } from 'src/views/Files/Evaluation/Table/useFileRows';

export const SubTable = ({ realOrSyn, fileId }) => {
  const { datasetRows, loading, error, setError } = useFileRows({
    realOrSyn: realOrSyn,
    fileId: fileId,
    startRow: 0,
    nrRows: 5
  });

  return (
    <>
      {(!loading && !error) && (
        <div className='table m-0 mb-4'>
          <div className='table-header-group'>
            <div className='table-row'>
                {datasetRows[0].map((columnName, i) => (
                  <div key={i} className='table-cell bg-black font-bold text-white border-[0.063rem] border-gray'>
                    {wordDelimiter(columnName, 15)}
                  </div>
              ))}
            </div>
          </div>
          {datasetRows.slice(1).map((dataSetRow, i) => (
            <div key={i} className='table-row'>
              {dataSetRow.map((element, j) => (
                <div key={j} className='table-cell eval-table-style'>{wordDelimiter(element, 15)}</div>
              ))}
            </div>
          ))}
        </div>
      )}
      <Modal isOpen={loading} type="loading" title="Loading tables..." />
      <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Try again"
        buttonAction={() => {
          setError('');
        }}
      />
    </>
  );
};