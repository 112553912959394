import { Theme } from 'src/components/Theme';
import styled from 'styled-components';
import { ToolbarItemCss } from 'src/views/Dashboard/ToolbarItem/ToolbarItem.styles';

export const ToolbarContainer = styled.nav`
  background-color: ${Theme.colors.TranparantOrange};
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  position: relative;
  height: 100%;
  top: 0;
  z-index: 1;
  transition: all 1s ease-in-out;
  width: inherit;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;

  ${Theme.breakpoints.medium} {
    width: 75px;
    img {
      display: none;
    }
  }
`;

export const ToolbarTopSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 2rem;
  width: 100%;
`;

export const ToolbarMainSection = styled.div`
  width: 100%;
`;

export const ToolbarBottomSection = styled.div`
  ${ToolbarItemCss}
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
`;