import { callApi } from "src/api/services/aux";

export const schemaService = {
  // Endpoint for getting one real/synthetic schema info with id and real_schema_flag
  // realSchemaFlag when True returns real schema otherwise a synthetic schema
  // When flag is true schema_id refers to connector_id otherwise to id
  getSchemaInfo: ({ token, id, realSchemaFlag = true }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/mt/get_schema_info",
    params: {
      ...(id && { id: id }),
      ...({ real_schema_flag: realSchemaFlag })
    }
  }),
  // Endpoint for getting project summary from real schemas joined by mt_models and synthetic schemas
  getSchemaSummary: ({ token, projectId }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/mt/schema_summary",
    params: { ...(projectId && { project_id: projectId }) }
  }),
  // Endpoint for loading a real schema by connector id and also able to ignore tables if needed
  // reprocessFlag for alerting user that meta_data was already built
  processSchema: ({ token, connectorId, tablesToIgnore = [], reprocessFlag = false }) => callApi({
    token: token,
    method: "POST",
    endpoint: "/mt/process_schema",
      options: {
        body: JSON.stringify(tablesToIgnore)
    },
    params: {
        ...(connectorId && { connector_id: connectorId }),
        ...(reprocessFlag && { reprocess_flag: reprocessFlag })
    }
  })
};