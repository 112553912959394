import { useNavigate } from 'react-router-dom';
import { Questions } from 'src/views/Faq/Questions';
import { Modal } from 'src/components/Modal/Modal';

export const CategoricalInfoModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const questionId = Questions.parameters.find((q) => q.id === 14).id;

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title="What is a categorical attribute?"
      description="Categorical attributes are those that hold a specific set of outcomes, usually a string datatype (for example male or female)."
      buttonText="Close"
      buttonAction={() => setIsOpen(false)}
      linkText="Read more"
      linkAction={() => navigate(`/faq?id=${questionId}`)}
    />
  );
};
