import { useCallback, useState } from 'react';
import { useApi } from 'src/api/useApi';
import { userProjectService } from 'src/api/services/userProjectService';
import { TextInput } from 'src/components/Input/TextInput';
import { Modal } from 'src/components/Modal/Modal';
import { useStorage } from 'src/contexts/useStorage';

export const DeleteUserProjectModal = ({ isOpen, setIsOpen, user, fetchUsersFromProject }) => {
  const { getCurrentProjectId } = useStorage();

  // Variables for type the user name or email and delete it.
  const [name, setName] = useState('');

  const showNameOrEmail = () => {
    if (user.name && user.last_name) {
      return user.name + " " + user.last_name;
    }
    return user.email;
  };

  const isDisabled = !(showNameOrEmail() === name);

  // Delete user from the current project and redirect to the team.
  const deleteUser = useCallback(
    (token) => userProjectService.deleteUsersProjects({ token: token, userId: user.id, projectId: getCurrentProjectId() }),
    [user.id, getCurrentProjectId]
);

  const { error, fetch } = useApi({
    fetcher: deleteUser,
    fetchOnLoad: false,
    onSuccess: () => {
      fetchUsersFromProject();
      setIsOpen(false);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title={`Are you sure that you want to take away user "${showNameOrEmail()}" from project?`}
      description={`This action can not be undone. ${showNameOrEmail()} will loose access to project. To confirm, please write the user name (${showNameOrEmail()}) and press Delete.`}
      error={error}
      buttonText="Delete"
      buttonDisabled={isDisabled}
      linkText="Cancel"
      buttonAction={() => fetch()}
      linkAction={() => setIsOpen(false)}
    >
      <TextInput
        data-testid="delete-user-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};
