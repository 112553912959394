import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { datasetService } from "src/api/services/datasetService";
import { useTask } from "src/api/services/useTask";
import { useApi } from "src/api/useApi";
import { useQuery } from "src/api/useQuery";
import { QuestionIcon } from "src/assets/icons/QuestionIcon";
import { BackNavigation } from 'src/components/BackNavigation';
import { LinkButton } from "src/components/Button/LinkButton";
import { ProgressBar } from "src/components/Loaders/ProgressBar";
import { Modal } from "src/components/Modal/Modal";
import { MoreInfoModal } from "src/components/Parameters/MoreInfoModal";
import { useFiles } from "src/contexts/FileContext";
import { useFileRows } from "src/views/Files/Evaluation/Table/useFileRows";
import { Parameter } from "src/views/Parameters/Parameter";

export const Parameters = () => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const navigate = useNavigate();
  const query = useQuery();
  const datasetId = query.get("fileId");
  // Get real_dataset meta_data passing the dataset id and store the fetch function.
  const { files, getFile, fetchFiles } = useFiles();
  const dataset = getFile(datasetId);
  // memoized to avoid the patchRealDatasetInfo callBack from re-rendering
  const copyOfMetadata = useMemo(() => {
    if (dataset) return JSON.parse(JSON.stringify(dataset.meta_data))
  }, [dataset]);
  // Show info modal.
  const [showModal, setShowModal] = useState(false);
  // Keep a set of all the confirmed attributes.
  const [confirmed, setConfirmed] = useState(new Set());

  // Update dataset metadata.
  const patchRealDatasetInfo = useCallback(
    (token, meta_data) => datasetService.patchRealDatasetInfo({
        token: token,
        description: null,
        id: datasetId,
        metadata: meta_data,
    }),
    [datasetId]
  );
  // set taskCallable
  const taskCallable = () => {
    fetchFiles();
    navigate(`/models?fileId=${datasetId}`)
  }
  const { fetch: patchRealDataset, error: patchError, setError: setPatchError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setPatchError
      })
    ),
    fetcher: patchRealDatasetInfo
  });

  // Get dataset rows from startRow = 0 up to limit = 3.
  const { datasetRows, loading, error, setError } = useFileRows({
    realOrSyn: "real",
    fileId: datasetId,
    startRow: 0,
    nrRows: 3,
  });

  // Handle confirming and unconfirming the column.
  const toggleAttrConfirm = (attribute) => {
    let toggleConfirm = () => {
      setConfirmed((prevState) => {
        let newState = new Set([...prevState]);
        newState.has(attribute) ? newState.delete(attribute) : newState.add(attribute);
        return newState;
      });
    }
    return toggleConfirm;
  };

  return (
    <>
      {files && !loading && (
        <div>
          <div className='viewHeader'>Setup parameters for:</div>
          <div className='H5'>"{dataset.filename}"</div>
          <BackNavigation />
          <div className='w-2/3 bg-white flex flex-col items-center mx-auto py-8 mb-8'>
            <p>
              {confirmed.size}/{datasetRows[0].length} confirmed
            </p>
            <ProgressBar progress={(confirmed.size / datasetRows[0].length) * 100} />
            <div className='flex mb-4 gap-1'>
              <LinkButton onClick={() => setConfirmed(new Set(Object.keys(dataset.meta_data.attributes))) }>
                Confirm all
              </LinkButton>
              <QuestionIcon className='cursor-pointer' onClick={() => setShowModal(true)} />
            </div>
            <button
              className="button-style text-sm text-orange transition-colors focus:outline-none bg-transparant-orange hover:bg-TransparantOrange border border-orange hover:border-transparent"
              disabled={confirmed.size !== datasetRows[0].length}
              onClick={() => {
                initTask({ initStatus: `Reprocessing ${dataset.filename}` });
                patchRealDataset(copyOfMetadata);
              }}
            >
              Reprocess
            </button>
          </div>
          <div className="paper flex flex-wrap justify-around">
            {datasetRows && copyOfMetadata && datasetRows[0].map((_, i) => {
              // Mapping through all the parameters passing the current dataset meta_data.
              const datasetRowsMap = datasetRows.map((p) => p[i]);
              const attribute = datasetRowsMap[0];
              return (
                <Parameter
                  key={i}
                  attribute={attribute}
                  rows={datasetRowsMap.slice(1, datasetRowsMap.length)}
                  attributeMetaData={copyOfMetadata.attributes[attribute]}
                  toggleConfirm={toggleAttrConfirm(attribute)}
                  isConfirmed={confirmed.has(attribute)}
                />
              );
            })}
          </div>
        </div>
      )}
      <Modal
        isOpen={loading || !files}
        type="loading"
        title="Loading file..."
      />
      {taskMonitoring && <Modal
        isOpen={taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {(!!error || !!patchError) && <Modal
        isOpen={!!error || !!patchError}
        type="error"
        description={error || patchError}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
          setPatchError("");
          setConfirmed(new Set());
        }}
      />}
      {showModal && (
        <MoreInfoModal isOpen={showModal} setIsOpen={setShowModal} />
      )}
    </>
  );
};
