import { useCallback, useRef, useState } from "react";
import { H4 } from "src/components/Headers/Headers.style";
import { useConnectors } from "src/contexts/ConnectorContext";
import { CancelIcon } from "src/assets/icons/CancelIcon";
import { ListTables } from "src/components/ListTablesModal/ListTables";
import { tableService } from "src/api/services/tableService";
import { useTask } from "src/api/services/useTask";
import { useApi } from "src/api/useApi";
import { Connector } from "src/components/LoadDataModal/Connector";
import { schemaService } from "src/api/services/schemaService";
import { MultiTabelModal } from "src/components/Modal/MultiTableModal";
import { useSchema } from "src/contexts/SchemaContext";
import { useFiles } from "src/contexts/FileContext";
import { useNavigate } from "react-router-dom";
import { QuestionIcon } from "src/assets/icons/QuestionIcon";

export const LoadDataModal = ({ setIsLoadModalOpen, setMultiTableView }) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const [checkedTables, setCheckedTables] = useState([]);
  const navigate = useNavigate();
  const { fetchFiles } = useFiles();
  const { fetchSchemaSummary } = useSchema();
  const { databaseConnectors } = useConnectors();
  const [selectedConnectorId, setSelectedConnectorId] = useState(
    // set init connector when multiple available
    databaseConnectors.length === 1 ? databaseConnectors[0].id : -1
  )
  // State for showing information when pressing question mark
  const [showInfoModal, setShowInfoModal] = useState("");
  // Reference to keep track if user chose multi or single table flow
  let isMultiTableFlow = useRef(false);

  const {
    data: tables,
    fetch: fetchListTables,
    loading: loadingListTables,
    error: errorListTable,
    setError: setErrorListTables
  } = useApi({
    fetchOnLoad: databaseConnectors.length === 1, // fetchOnLoad true when connector is pre-selected
    // listTables api
    fetcher: useCallback(
      (token, connectorId) =>
        tableService.listTables({
          token: token,
          // query by pre-selected connector if set otherwise a connector in the list
          connectorId: databaseConnectors.length > 1 ? connectorId : selectedConnectorId
        }),
      [selectedConnectorId, databaseConnectors]
    ),
  });

  // set taskCallable
  const taskCallable = () => {
    // if flag is true user chose multi table flow
    if (isMultiTableFlow.current) {
      // get latest schemas
      fetchSchemaSummary();
      // set multi table view in home
      setMultiTableView(true);
    } else { // user chose single table flow
      // get latest preprocessed datasets
      fetchFiles();
      // set single table view in home
      setMultiTableView(false);
    }
    // close modal
    setIsLoadModalOpen(false);
    // whenever the modal is open and in a different path except Home (/) navigate to home.
    window.location.pathname !== "/" && navigate("/");
  }

  // preprocessTables api
  const {
    fetch: fetchPreprocessTables,
    error: errorPreprocessTables,
    setError: setErrorPreprocessTables
  } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setErrorPreprocessTables
      })
    ),
    fetcher: useCallback(
      (token, reprocessFlag) =>
        tableService.preprocessTables({
          token: token,
          connectorId: selectedConnectorId,
          tableNames: checkedTables,
          reprocessFlag: reprocessFlag
        }),
      [selectedConnectorId, checkedTables]
    ),
  });

  // processSchema api
  const {
    fetch: fetchSchema,
    error: errorPreprocessSchema,
    setError: setErrorPreprocessSchema
  } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setErrorPreprocessSchema
      })
    ),
    fetcher: useCallback(
      (token, reprocessFlag) => schemaService.processSchema({
          token: token,
          connectorId: selectedConnectorId,
          tablesToIgnore: tables.filter((t) => !checkedTables.includes(t)),
          reprocessFlag: reprocessFlag
        }),
      [selectedConnectorId, checkedTables, tables]
    ),
  });

  const error = errorListTable || errorPreprocessTables || errorPreprocessSchema;
  const resetError = () => {
    setErrorListTables("");
    setErrorPreprocessTables("");
    setErrorPreprocessSchema("");
  };

  return (
    <>
      <div className="fixed inset-0 flex-view z-10 max-lg:overflow-y-scroll top-9">
        <div className="modal-overlay bg-black opacity-50 fixed inset-0" />
        <div className="modal-container w-2/3 max-md:w-4/5 z-10">
          <div className="bg-white shadow-3xl p-8 m-auto padding-x">
            <div
              className="flex justify-end cursor-pointer max-lg:mt-20"
              onClick={() => setIsLoadModalOpen(false)}
            >
              <CancelIcon />
            </div>
            <div className="flex flex-col gap-3 justify-center p-5">
              <div className="flex flex-row justify-center items-center gap-1">
                <H4 className="max-lg:mb-0">Load Data</H4>
                <QuestionIcon onClick={() => setShowInfoModal(
                  "Load table(s) is for single table modelling, load schema is for multitable modelling"
                )}/>
              </div>
              <div className="flex justify-between gap-4 max-lg:flex-col">
                <div className="rounded-xl p-5 half-screen-wrapper">
                  <p className="font-bold font-font">Choose connector</p>
                  <div className="flex flex-col justify-center justify-items-center pt-10 max-lg:pt-0">
                    {databaseConnectors.map((connector, index) => (
                      /* Renders a connector in the modal */
                      <Connector
                        key={index}
                        label={connector.description}
                        connectorId={connector.id}
                        selectedConnectorId={selectedConnectorId}
                        setSelectedConnectorId={setSelectedConnectorId}
                        setCheckedTables={setCheckedTables}
                        fetchListTables={fetchListTables}
                      />
                    ))}
                  </div>
                </div>
                <div className="rounded-xl p-5 half-screen-wrapper 2xl:w-2/3 max-lg:p-1">
                  <ListTables
                    tables={tables}
                    loadingListTables={loadingListTables}
                    checkedTables={checkedTables}
                    setCheckedTables={setCheckedTables}
                    selectedConnectorId={selectedConnectorId}
                  />
                </div>
              </div>
              <div className="flex justify-center gap-10 p-3">
                <button
                  data-testid="loadTable"
                  className="button-style max-lg:text-xs font-font disabled:bg-gray disabled:text-dark-gray disabled:cursor-not-allowed"
                  disabled={checkedTables.length === 0}
                  onClick={() => {
                    isMultiTableFlow.current = false;
                    fetchPreprocessTables(false);
                    initTask({ initStatus: 'Preprocessing' });
                  }}
                >
                  Load table(s)
                </button>
                <button
                  data-testid="loadSchema"
                  className="button-style max-lg:text-xs font-font bg-orange text-white hover:text-black hover:border-orange disabled:bg-gray disabled:text-dark-gray disabled:cursor-not-allowed"
                  disabled={checkedTables.length === 0}
                  onClick={() => {
                    isMultiTableFlow.current = true;
                    fetchSchema(false);
                    initTask({ initStatus: 'Processing' });
                  }}
                >
                  Load schema
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {taskMonitoring && <MultiTabelModal
        className="z-50"
        isOpen={taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {!!(error || showInfoModal) && <MultiTabelModal
        isOpen={!!(error || showInfoModal)}
        type="error"
        description={ error || showInfoModal }
        buttonText={showInfoModal ? "" : "Continue"}
        buttonAction={() => {
          if (errorPreprocessSchema === "This action is going to rebuild existing metadata, set reprocess_flag to true if this is intended and try again") {
            // re-process schema with flag set to true
            fetchSchema(true);
            initTask({ initStatus: 'Processing' });
            // close the error modal
            resetError();
          } else if (errorPreprocessTables.substring(0, 60) === "This action is going to rebuild existing metadata for table ") {
            // preprocess tables with reprocess flag set to true
            fetchPreprocessTables(true);
            initTask({ initStatus: 'Preprocessing' });
            // close the error modal
            resetError();
          } else {
            resetError();
            setIsLoadModalOpen(false);
          }
        }}
        buttonText2="Cancel"
        buttonAction2={()=>{
          forceEndTask();
          resetError();
          setShowInfoModal(false);
        }}
      />}
    </>
  );
};
