import { Background, Container, Progress } from 'src/components/Loaders/ProgressBar.styles';

export const ProgressBar = ({ progress }) => {
  return (
    <Container>
      <Background />
      <Progress percent={progress} />
    </Container>
  );
};
