import { useState } from "react";

import { taskService } from "src/api/services/taskService";
import { useToken } from "src/contexts/TokenContext";
import { sleep } from 'src/helpers/sleep';

export const useTask = () => {
  //Hook useTask for handling task monitoring
  const { token } = useToken();
  //Task related states
  const [taskMonitoring, setTaskMonitoring] = useState(false);
  const [taskProgress, setTaskProgress] = useState(0);
  const [taskStatus, setTaskStatus] = useState("");

  const initTask = ({ initStatus = "" }) => {
    //Method sets init states
    setTaskProgress(0);
    setTaskStatus(initStatus);
    setTaskMonitoring(true);
  }

  const forceEndTask = () => (setTaskMonitoring(false));

  const monitorTask = async ({ taskId, callable, setTaskError }) => {
    //Method receives a taskId and monitors the task until done, and when done runs callable
    let taskPayload = {};
    let taskDone = false;
    while (!taskDone) {
      let taskInfo = await taskService
        .getTask({ token: token, task_id: taskId })
        .then((response) => response.json())
        .then((data) => {
          // Update task progress and status
          setTaskProgress(Math.round(data.progress));
          setTaskStatus(data.status);
          if (
            Object.hasOwn(data, "detail") ||
            data.status === "Complete" ||
            data.status === "Error"
          ) {
            return [true, data];
          }
          return [false, data];
        });
      taskDone = taskInfo[0];
      taskPayload = taskInfo[1]
      //Sleep for 3 seconds if task is not done
      if (!taskDone) await sleep(3000);
    }
    //Task is done, check for errors, otherwise run callable
    // Check if there were tables that could not be preprocessed
    if (taskPayload["unable_to_preprocess_tables"]) {
      // process taskPayload["unable_to_preprocess_tables"]
      let arr = taskPayload["unable_to_preprocess_tables"].split("; ");
      arr = arr.map(element => {
        let index = element.indexOf(" ");
        if (index !== -1) {
            return element.slice(0, index);
        } else {
            return element;
        }
      });
      arr = arr.join(', ');
      // set the tables that gave error
      setTaskError(
        `Unable to preprocess tables: ${arr}`
      )
    } else if (taskPayload["detail"]) {
      setTaskError(taskPayload["detail"]);
    } else {
      callable(taskPayload);
    }
    setTaskMonitoring(false);
  };

  return {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  };
};