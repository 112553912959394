import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const StyledLinkButton = styled.button`
  color: ${Theme.colors.black};
  background: none;
  border: none;
  padding: 0rem;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.hover};
    font-weight: 600;
  }
`;
