import { useNavigate } from 'react-router-dom';
import { Questions } from 'src/views/Faq/Questions';
import { Modal } from 'src/components/Modal/Modal';

export const WassersteinInfoModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const questionId = Questions.evaluation.find((q) => q.id === 34).id;

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title="What is distributions similarity?"
      description="The distributions similarity metric gives you an indication of how similar the individual distributions in the file’s attributes are when comparing the uploaded file with the generated synthetic file."
      buttonText="Close"
      buttonAction={() => setIsOpen(false)}
      linkText="Read more"
      linkAction={() => navigate(`/faq?id=${questionId}`)}
    />
  );
};
