import styled, { css } from 'styled-components';
import { Theme } from 'src/components/Theme';

export const ContainerSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerSize = {
  small: css`
    width: 2rem;
    height: 2rem;
  `,
  default: css`
    width: 6.25rem;
    height: 6.25rem;
  `
}

export const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  padding: 1rem;
  ${({size}) => (SpinnerSize[size])};
  & circle {
    stroke: ${Theme.colors.red};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke: ${Theme.colors.litepink};
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke: ${Theme.colors.orange};
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke: ${Theme.colors.red};
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
