// Toggle the given row index to be open or closed.
export const toggleRow = ({ setOpenRows, i }) => {
  setOpenRows((current) => {
    if (current.has(i)) {
      current.delete(i);
      return new Set([...current]);
    }
    current.add(i);
    return new Set([...current]);
  });
};

const sorter = (column, a, b) => {
  const item1 = a[column];
  const item2 = b[column];
  if (item1 === undefined || item2 === undefined)  {
    if (item1 === undefined || item2 !== undefined) return 0;
    else return 1;
  };
  return item1.toString().localeCompare(item2.toString());
};

export const columnSortByNameAux = ({ list, column, sortAscending = true }) => {
  if (sortAscending) {
    list.sort((a, b) => sorter(column, a, b));
  } else {
    list.sort((a, b) => sorter(column, b, a));
  }
};

export const columnSortByNumberAux = ({ list, column, sortAscending = true }) => {
  if (sortAscending) {
    list.sort((a, b) => a[column]>b[column]?1:-1);
  } else {
    list.sort((a, b) => a[column]<b[column]?1:-1);
  }
};

export const isOpenTransitionCss = (isOpen) => {
  if (isOpen) return " opacity-100 visible ";
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return " opacity-0 collapse " + (isSafari ? "hidden " : "");
};