import { ResponsiveBar } from '@nivo/bar';
import { wordDelimiter } from 'src/helpers/wordDelimiter';

export const Bar = ({ title, data, icon, color, attrOrBin, attributeName="" }) => {
  // Get keys from attrOrBin value
  const keys = attrOrBin==="bin" ? ["prob"] : ["rate"];
  // Calculate hasLongValues to rotate the ticks depending on bins length
  const hasLongValues = data ? data.find((value) => value[attrOrBin].length > 4) : false

  return (
    <div className="half-screen-wrapper">
      <div className='eval-border'>
        <div className='H6 mx-[1.125rem] my-[1.25rem] text-left flex justify-start items-center'>
          <div className='mr-[0.625rem]'>{icon}</div>
          {title}
        </div>
      </div>
      <div className='h-[30rem] px-2 pb-2 eval-border'>
        {(data) && (
          <ResponsiveBar
            data={data}
            indexBy={attrOrBin}
            keys={keys}
            margin={{ top: 50, right: 50, bottom: 90, left: 50 }}
            padding={0.1}
            colors={[color]}
            groupMode="grouped"
            borderColor="#A8D1E8"
            axisBottom={{
              tickSize: 10,
              tickPadding: 15,
              tickRotation: hasLongValues ? -30 : 0,
              legend: wordDelimiter(attributeName, 30),
              legendPosition: 'middle',
              legendOffset: 80,
            }}
            axisLeft={{
              tickSize: 10,
              tickPadding: 15,
              legendPosition: 'middle',
            }}
          />
        )}
      </div>
    </div>
  );
};