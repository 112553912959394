export const LogoutIcon = ({ onClick}) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        d="M13,9 L13,2 L1,2 L1,22 L13,22 L13,15 M22,12 L5,12 M17,7 L22,12 L17,17"
      />
    </svg>
  );
};
