import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from 'src/api/useApi';
import { useFiles } from 'src/contexts/FileContext';
import { modelService } from 'src/api/services/modelService';
import { useTask } from "src/api/services/useTask";
import { NumberInput } from 'src/components/Input/NumberInput';
import { Modal } from 'src/components/Modal/Modal';

export const GenerateSyntheticFileModal = ({
  isOpen,
  setIsOpen,
  inputProps
}) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const navigate = useNavigate();
  const { fetchFiles } = useFiles();
  const { realFileId, synFileName, trainedModelId} = inputProps;
  // Variable for type the number of rows for data generation.
  const [nrRows, setRows] = useState('');

  // set taskCallable
  const taskCallable = (taskPayload) => {
    // get syntheticFileId from taskPayload
    const syntheticFileId = taskPayload.synthetic_dataset_id
    // get latest information
    fetchFiles();
    // navigate to evaluation
    navigate(`/evaluation?fileId=${realFileId}&synFileId=${syntheticFileId}`);
  }

  const { fetch, error, setError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setError
      })
    ),
    fetcher: useCallback(
      (token) => modelService.postGenerate({
        token: token,
        trainedModelId: trainedModelId,
        nRows: Number(nrRows)
      }),
      [trainedModelId, nrRows]
    )
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        bigTitle="Generate a new synthetic file from same model that generated:"
        title={synFileName}
        description="Input number of rows to generate."
        buttonText="Generate"
        linkText="Generate with a new model"
        linkText2="Cancel"
        buttonAction={() => {
          fetch();
          initTask({ initStatus: 'Generating' });
        }}
        linkAction={() => navigate(`/models?fileId=${realFileId}`)}
        linkAction2={() => setIsOpen(false)}
      >
        <NumberInput
          data-testid="number-input-generate-syn"
          placeholder="Number of rows"
          value={nrRows}
          onChange={(e) => setRows(e.target.value)}
        />
      </Modal>
      {taskMonitoring && <Modal
        isOpen={taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {!!error && <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
        }}
      />}
    </>
  );
};