import styled from "styled-components";
import { Theme } from "src/components/Theme";

export const Container = styled.div`
  display: inline-flex;
  border-radius: 50%;
  margin:  0.2rem 0.5rem;
  svg {
    width: 25px;
    height: 25px;
    color: ${Theme.colors.pitchblack};
  }
  ${Theme.breakpoints.medium} {
    svg {
      width: 13px;
      height: 20px;
      color: ${Theme.colors.pitchblack};
    }
    margin:  0.2rem 0.1rem;
  }
`;
