import { useState, useEffect } from "react";
import { Spinner } from "src/components/Loaders/Spinner";

export const ListTables = ({
  tables,
  loadingListTables,
  checkedTables,
  setCheckedTables,
  selectedConnectorId,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(() => {
    if (loadingListTables) setIsCheckAll(false);
  }, [loadingListTables]);

  const handleSelectAll = () => {
    setIsCheckAll((prevState) => !prevState);
    setCheckedTables(tables.map((item, _) => item));
    if (isCheckAll) {
      setCheckedTables([]);
    }
  };

  return (
    <div>
      <p className="text-left font-bold font-font">Tables to load</p>
      <div className="w-full h-[300px] max-lg:h-[200px] bg-light-gray rounded-md overflow-y-scroll mt-2 p-3">
        {selectedConnectorId <= 0 ? (
          <div className="flex justify-center items-center h-full text-bitch-black cursor-not-allowed">
            Select connector to see the tables!
          </div>
        ) : loadingListTables ? (
          <div className="flex justify-center items-center h-full cursor-not-allowed">
            <Spinner />
          </div>
        ) : (
          tables && (
            <>
              <div className="py-2 flex border-b border-gray justify-start items-center gap-x-2">
                <input type="checkbox" onClick={() => handleSelectAll()} />
                Select All
              </div>
              <div>
                {tables.map((table, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-3 justify-start items-center align-middle p-2 max-lg:p-0 hover:bg-soft-gray rounded-sm cursor-pointer disabled:cursor-not-allowed"
                    onClick={() => {
                      if (checkedTables.includes(table)) {
                        setCheckedTables(
                          checkedTables.filter((t) => t !== table)
                        );
                      } else {
                        setCheckedTables((prevState) => [...prevState, table]);
                      }
                    }}
                  >
                    <input
                      data-testid="table-checkbox"
                      type="checkbox"
                      name={table}
                      checked={checkedTables.includes(table)}
                      readOnly={true}
                    />
                    {table}
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};
