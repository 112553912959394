import styled,{ css } from 'styled-components';
import { Theme } from 'src/components/Theme';

export const Container = css`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Label = css`
  color: ${Theme.colors.darkgray};
  font-weight: normal;
  opacity: 0.75;
  pointer-events: none;
  text-shadow: none;
`;

export const Styled = css`
  display: flex;
  text-shadow: none;
  border: none;
  box-sizing: border-box;
  flex: 1 1 auto;
`;

export const TextContainer = styled.div`
  ${Container}
  height: 3rem;
  margin: auto auto 2.5rem auto;
  padding: auto;
  width: 18rem;
`;

export const LabelText = styled.label`
  ${Label}
  order: 1;
  font-size: ${Theme.fontSize.small};
  text-align: left;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
  transform-origin: left top;
  transform: scale(1) translate3d(0, 1.25rem, 0);
  transition: 200ms ease all;
`;

export const StyledText = styled.input`
  ${Styled}
  order: 2;
  padding-bottom: 1rem;
  border-radius: 0;
  border-bottom: 0.063rem solid ${Theme.colors.darkgray};
  &:focus {
    outline: 0;
    color: ${Theme.colors.black};
    border-bottom: 0.063rem solid ${Theme.colors.black};
  }
  &:not(:focus) {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
  &:focus + ${LabelText} {
    color: ${Theme.colors.darkgray};
    opacity: 1;
    transform: scale(0.8) translate3d(0, 0.3rem, 0);
  }
`;

export const NumberContainer = styled.div`
  ${Container}
  height: 3rem;
  padding-left: 0.625rem;
  width: 50%;
`;

export const StyledNumber = styled.input`
  ${Styled}
  padding: 0.625rem 1.25rem;
  border-radius: 0.375rem;
  border: 0.063rem solid ${Theme.colors.darkgray};
  &:focus {
    font-weight: 600;
    outline: 0;
    color: ${Theme.colors.black};
    border: 0.063rem solid ${Theme.colors.black};
  }
  ::placeholder {
    ${Label}
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputError = styled.span`
  color: red;
`;

export const Input = ({ error, children, placeholder, ...props }) => (
  <TextContainer>
    <StyledText id={props.id} {...props} />
    <LabelText htmlFor={props.id}>
      {placeholder}
      {children}
    </LabelText>
    {error && <InputError data-testid="input-error">{error}</InputError>}
  </TextContainer>
);

export const InputNumber = ({ error, children, placeholder, ...props }) => (
  <NumberContainer>
    <StyledNumber {...props} placeholder={placeholder} />
    {children}
    {error && <InputError>{error}</InputError>}
  </NumberContainer>
);
