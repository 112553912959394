import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Theme } from "src/components/Theme";

export const ToolbarItemCss = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.2rem 1rem;
    font-size: ${Theme.fontSize.small};
    font-weight: ${Theme.fontWeight.semiBold};
    transition: all 0.5s ease-in-out;
    font-family: ${Theme.fontFamily.secondaryFont};
    margin: 1rem 0;
    &:hover,
    &.active-item{
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        transition: all 0.5s;
        color: ${Theme.colors.orange};
        background-color: ${Theme.colors.lightgray};
    } ;
    svg {
        width: 30px;
        height: 25px;
        margin: 0.6rem;
    }
    ${Theme.breakpoints.medium} {
        span {
            display: none;
        }
    }
`;

export const ToolbarItemLink = styled(NavLink)`
    ${ToolbarItemCss}
    :last-of-type {
        position: sticky;
    }
    div {
        display: flex;
        align-items: center;
    }
`;

export const DropdownLink = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    &:hover {
        cursor: pointer;
    }
`;