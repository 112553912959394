import { useEffect, useState } from 'react';
import { useQuery } from 'src/api/useQuery';
import { Accordion } from 'src/components/Accordion/Accordion';
import { Column, Footer, PaperContainer, Row, Wrapper } from 'src/views/Faq/Faq.styles';
import { Questions } from 'src/views/Faq/Questions';
import { Contact } from 'src/views/Contact/Contact';

export const Faq = () => {
  const project = Questions.project;
  const file = Questions.files;
  const model = Questions.models;
  const parameter = Questions.parameters;
  const testProject = Questions.testProject;
  const evaluation = Questions.evaluation;

  // Showing the answer of the selected Question.
  const [isOpen, setOpen] = useState(false);

  // Getting the question id from the search url params.
  const query = useQuery();
  const questionId = query.get("id");

  // Handle the scroll to element by question id.
  useEffect(() => {
    const pageNode = document.getElementById(questionId);
    if (pageNode) {
      pageNode.scrollIntoView({ behavior: "smooth" });
    }
  }, [questionId]);

  // Handle the section to show in the faq.
  const FaqSection = ({ items, name }) => {
    return (
      <Column>
        <div className='border-[0.063rem] border-light-gray flex'>
          <div className='H5 mx-4 lg:mx-8 my-4 underline tracking-widest text-orange align-middle'>
            {name}
          </div>
        </div>
        {items.map((item) => (
          <Accordion
            isOpen={Number(questionId) === item.id}
            id={item.id}
            key={item.id}
            title={item.question}
            description={item.answer}
          />
        ))}
      </Column>
    );
  };

  return (
    <Wrapper>
      <div className="viewHeader">Faq</div>
      <PaperContainer>
        <Row>
          <FaqSection items={project} name="Project" />
          <FaqSection items={file} name="Files" />
        </Row>
        <Row>
          <FaqSection items={model} name="Models" />
          <FaqSection items={evaluation} name="Evaluation" />
        </Row>
        <Row>
          <FaqSection items={parameter} name="Parameters" />
          <FaqSection items={testProject} name="Test project" />
        </Row>
      </PaperContainer>

      <Footer onClick={() => setOpen((prevState) => !prevState)}>
        <p>
          For more questions please {' '}
          <u>
            Contact us
          </u>
        </p>
      </Footer>
      {isOpen && <Contact isOpen={isOpen} setIsOpen={() => setOpen()} />}
    </Wrapper>
  );
};
