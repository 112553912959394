
export const ExclamationIcon = () => {
    return (
      <svg
        width="103"
        height="103"
        viewBox="0 0 103 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.5781 72.6667H51.6302"
          stroke="#525252"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.6042 31V51.8333"
          stroke="#525252"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="51.5" cy="51.5" r="47.5" stroke="#525252" strokeWidth="8" />
      </svg>
    );
  };
  