import { callApi } from "src/api/services/aux";

export const tableService = {
    // Endpoint for listing tables
    listTables: ({ token, connectorId }) => callApi({
        token: token,
        method: "GET",
        endpoint: "/list_tables",
        params: { connector_id: connectorId }
    }),
    // Endpoint for preprocessing tables
    preprocessTables: ({ token, connectorId, tableNames, reprocessFlag = false }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/preprocess_tables",
        params: {
            connector_id: connectorId,
            reprocess_flag: reprocessFlag
        },
        options: { body: JSON.stringify(tableNames) }
    })
};