import { useEffect, useState } from 'react';
import { useToken } from 'src/contexts/TokenContext';

export const DEFAULT_ERROR = 'Oops! Something went wrong. Try again or contact support.'

// Custom Hook for fetching data via APIs. Accepts a fetcher function, which should be
// asynchronous and accept a token. This hook will call the fetcher and handle loading,
// error and data state. It also provides a function for refetching data which updates the state.
// fetchOnLoad when true fetch when component renders otherwise call fetch manually.
export function useApi({ fetcher, fetchOnLoad, onSuccess = null }) {
  const [loading, setLoading] = useState(fetchOnLoad); // Handle loading state from API call.
  const [error, setError] = useState(''); // Handle error state from API call.
  const [data, setData] = useState(undefined); // Handle data state from API call.
  const { token } = useToken();

  const fetch = async (...args) => {
    try {
      setLoading(true);
      // Call the provided fetcher with the token and optional arguments.
      const response = await fetcher(token, ...args);
      const data = await response.json();
      if (!response.ok) {
        // Expects the error to always have a detail field.
        // If detail is a list display default error.
        setError(data.detail instanceof Object ? DEFAULT_ERROR : data.detail);
      } else {
        setData(data);
        if (onSuccess) {
          onSuccess(data);
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (fetchOnLoad) fetch();
    // Cleanup function
    return () => {};
    // eslint-disable-next-line
  }, []);

  return { loading, error, setError, data, fetch };
}