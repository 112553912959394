import { createContext, useCallback, useContext, useRef } from 'react';
import { useApi } from 'src/api/useApi';
import { datasetService } from 'src/api/services/datasetService';
import { useStorage } from "src/contexts/useStorage";
import { Modal } from "src/components/Modal/Modal";

export const FileContext = createContext({});

export const useFiles = () => {
  return useContext(FileContext);
};

export const FileProvider = ({ children }) => {
  const { getCurrentProjectId }  = useStorage();
  // Get all the datasets from the current project. You can pass filename and dataset id with a null value.
  const getUserRealDatasets = useCallback(
    (token) =>
    datasetService.getRealDatasetsInfo({
      token: token, projectId: getCurrentProjectId(), connectorId: null, filename: null, fileId: null
    }),
    [getCurrentProjectId]
  );
  const { data, error, setError, fetch, loading } = useApi({ fetcher: getUserRealDatasets, fetchOnLoad: true });

  // Function for getting the dataset by id.
  const getFile = (fileId) => data && data.find((f) => f.id === Number(fileId));

  // Get a synthetic dataset by id.
  const getSynFile = (synFileId) => data && data
    .flatMap((f) => f.trained_models)
    .flatMap((m) => m.synthetic_datasets)
    .find((f) => f.id === Number(synFileId));

  // Create a reference for the hidden UploadFile component to upload files
  const uploadFileRef = useRef();

  if (error) {
    return <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Try again"
        buttonAction={() => setError('')}
    />;
  }

  return (
    <FileContext.Provider
      value={{
        files: data,
        loadingDatasets: loading,
        fetchFiles: fetch,
        getFile,
        getSynFile,
        uploadFileRef
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
