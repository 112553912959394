import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

 export const Svg = styled.svg`
  cursor: pointer;
  &:hover,
  &:active {
    fill: ${Theme.colors.litepink};
    transition: background-color 0.5s;
  }
`;
