// The keys for the storage items.
export const Storage = {
  CURRENT_PROJECT: 'currentProject',
};

// Function for getting and setting items in local storage.
export const useStorage = () => {
  // Helper function for setting the current project id.
  const setCurrentProjectId = (projectId) => localStorage.setItem(Storage.CURRENT_PROJECT, projectId);

  // Helper function for getting the current project id.
  const getCurrentProjectId = () => Number(localStorage.getItem(Storage.CURRENT_PROJECT));

  // Helper function for deleting the current project id.
  const deleteCurrentProjectId = () => localStorage.removeItem(Storage.CURRENT_PROJECT);

  return { setCurrentProjectId, getCurrentProjectId, deleteCurrentProjectId };
};
