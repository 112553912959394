//below imports are for TasksContainer
import { useCallback, useState } from "react";
import { useApi } from "src/api/useApi";
import { taskService } from "src/api/services/taskService";
import { CancelIcon } from "src/assets/icons/CancelIcon";
import { roundToDecimals } from "src/helpers/numberHelper";
import { useStorage } from "src/contexts/useStorage";
import { ReloadIcon } from "src/assets/icons/ReloadIcon";
import { SortingIcon } from "src/assets/icons/SortingIcon";
import { columnSortByNameAux, columnSortByNumberAux } from "src/views/Files/utils";

export const TasksProjectModal = ({ setIsOpen }) => {
  const { getCurrentProjectId } = useStorage();
  const { data: tasks, fetch: fetchTasks } = useApi({
    fetchOnLoad: true,
    fetcher: useCallback(
      (token) =>
        taskService.getAllTasks({
          token: token,
          projectId: getCurrentProjectId(),
        }),
      [getCurrentProjectId]
    ),
  });
  const { fetch: fetchDelete } = useApi({
    fetchOnLoad: false,
    onSuccess: () => fetchTasks(),
    fetcher: useCallback(
      (token, taskId, deleteFlag) =>
        taskService.deleteTask({
          token: token,
          taskId: taskId,
          deleteFlag: deleteFlag,
        }),
      []
    ),
  });
  // State for sorted column ascend or descend
  const [sorted, setSorted] = useState(true);
  const columnSort = ({ idx }) => {
    const columnSortBy = idx===2 ? columnSortByNumberAux : columnSortByNameAux
    const columns = ["name", "status", "progress", "start_time", "end_time"];
    columnSortBy({ list: tasks, column: columns[idx], sortAscending: sorted });
    setSorted((prev) => !prev);
  };
  //Aux arrays and functions
  const topIcons = [
    [() => fetchTasks(), <ReloadIcon />],
    [() => setIsOpen(false), <CancelIcon />]
  ];
  const headers = ["Name", "Status", "Progress", "Start Time", "End Time", ""];
  const cellCss = "border-b border-gray py-1 text-sm";
  const TaskRows = (row, i) => {
    const auxArray = [
      i+1,
      row.name,
      row.status,
      `${roundToDecimals(row.progress, 2)}%`,
      row.start_time.replace("Z", " "),
      row.end_time?.replace("Z", " ")
    ];
    return (
      <tr key={i}>
        {auxArray.map((item, j) => (
          <td
            key={j}
            className={`${cellCss} ${j===0 ? "w-4" : "w-14"}`}
          >
            {item}
          </td>
        ))}
        <td
          className={`${cellCss} w-14 bg-DarkTransparantOrange cursor-pointer`}
          onClick={() => fetchDelete(row.task_id)}
        >
          Terminate
        </td>
        <td
          className={`${cellCss} w-14 bg-red-500 cursor-pointer`}
          onClick={() => fetchDelete(row.task_id, true)}
        >
          Delete
        </td>
      </tr>
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-10 overflow-auto">
      <div className="modal-overlay bg-black opacity-50 fixed inset-0" />
      <div className="modal-container w-1/2 max-md:w-full z-10 overflow-x-auto bg-white p-8 flex-view flex-col">
        <div className="self-end flex gap-8 items-center">
          {topIcons.map((item, i) => (
            <div
              key = {i}
              className="cursor-pointer"
              onClick={item[0]}
            >
              {item[1]}
            </div>
          ))}
        </div>
        <span className="H6 m-4">Tasks</span>
        {tasks && (tasks?.length===0 ? (
          <span>No tasks are running</span>
        ) : (
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-TransparantOrange text-sm">
                {headers.map((header, i) => (
                  <th
                    key={i}
                    className="px-1 py-1" colSpan={(i===0  || i===(headers.length-1)) ? "2" : "1"}
                  >
                    <div className="flex items-center justify-center">
                      {(i!==(headers.length-1)) && (
                        <div
                          className={"cursor-pointer"}
                          onClick={() => columnSort({ idx: i })}
                        >
                          <SortingIcon />
                        </div>
                      )}
                      {header}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tasks.map((row, i) => TaskRows(row, i))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};
