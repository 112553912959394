import styled from 'styled-components';
import { Theme } from 'src/components/Theme';
import { H6 } from 'src/components/Headers/Headers.style';

export const Wrapper = styled.div`
  img {
    width: 80%;
    margin-top: 1.25rem;
  }
  H4,
  p {
    font-size: ${Theme.fontSize.small};
  }
  ${Theme.breakpoints.laptop} {
    img {
      width: 14rem;
      margin-top: 2rem;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  border: 0.063rem solid ${Theme.colors.lightgray};
  gap: 0rem;
  ${Theme.breakpoints.laptop} {
    gap: 5rem;
  }
`;

export const Column = styled.div`
  flex: 2;
  padding: 0rem 3rem;
  text-align: left;
  H4 {
    text-align: left;
    margin-top: 1rem;
  }
`;

export const MapWrapper = styled.div`
  ${H6} {
    margin: 2rem 0 0.5rem 0;
    color: ${Theme.colors.red};
    text-align: center;
  }
`;
