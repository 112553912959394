import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';
import { StyledButton } from 'src/components/Button/Button.styles';

export const Wrapper = styled.div`
  H3 {
    margin-top: 2rem;
  }
  ${StyledButton} {
    border-radius: 1.5rem;
  }
  H6 {
    margin: 1rem;
    text-align: left;
  }
  span {
    padding: 0.5rem;
  }
  p {
    font-size: 0.7rem;
  }
  ${Theme.breakpoints.laptop} {
    p {
      font-size: 0.875rem;
    }
  }
  svg {
    cursor: pointer;
  }
`;

export const SettingsButtonContainer = styled(Paper)`
  display: grid;
  grid-template-columns: repeat(2, 18%);
  background-color: transparent;
  gap: 2rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  a {
    color: ${Theme.colors.red};
  }
  svg {
    height: 0.8rem;
    width: 1rem;
    align-items: center;
    margin-right: 0.4rem;
  }
  ${Theme.breakpoints.medium} {
    width: 20rem;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: auto 4rem 5rem;
  }
`;

export const PaperContainer = styled(Paper)`
  background-color: ${Theme.colors.lightgray};
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 0.02rem solid ${Theme.colors.lightgray};
  background-color: ${Theme.colors.white};
  align-items: center;
  justify-content: center;
  H6 {
    text-align: center;
    margin-right: 3rem;
  }
  ${Theme.breakpoints.medium} {
    font-size: ${Theme.fontSize.small};
    margin: 0;
  }
`;
export const ContainerWrapper = styled.div`
  background-color: ${Theme.colors.lightgray};
  H6 {
    text-align: left;
  }

  ${Theme.breakpoints.medium} {
    font-size: ${Theme.fontSize.small};
  }
`;

export const SubContainer = styled(Container)`
  grid-template-columns: repeat(3, 1fr);
`;

export const IconWrapper = styled.div``;

export const ContactWrapper = styled(SettingsButtonContainer)`
  margin-top: 0;
`;
