const cssColorFixer = {
  good: "text-emerald-500 bg-emerald-100/60",
  midgood: "text-amber-500 bg-amber-100/60",
  midbad: "text-rose-500 bg-rose-100/80",
  bad: "text-red-500 bg-red-200/80",
};

export const getMetricColor = (metric) => {
  if (metric<0.10) return cssColorFixer.good;
  if (metric<0.20) return cssColorFixer.midgood;
  if (metric<0.30) return cssColorFixer.midbad;
  return cssColorFixer.bad;
};