import { callApi } from "src/api/services/aux";

export const userProjectService = {
    // Endpoint for post user_project with user id and project id.
    postUsersProjects: ({ token, userId, projectId }) => callApi({
        token: token,
        method: "POST",
        endpoint: "/users_projects",
        options: { body: JSON.stringify({
            user_id: userId,
            project_id: projectId
        })
        }
    }),
    // Endpoint for delete user_project with user id and project id.
    deleteUsersProjects: ({ token, userId, projectId }) => callApi({
        token: token,
        method: "DELETE",
        endpoint: "/users_projects",
        params: { user_id: userId, project_id: projectId }
    }),
};