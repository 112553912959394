import { useEffect, useRef, useState } from 'react';
import { H6 } from 'src/components/Headers/Headers.style';
import { ArrowDownIcon } from 'src/assets/icons/ArrowDownIcon';
import { Container, Wrapper } from 'src/components/Accordion/Accordion.styles';

export const Accordion = ({ id, isOpen, title, description }) => {
  const [open, setOpen] = useState(isOpen);
  const toggling = () => {setOpen((prevOpen) => !prevOpen)};
  const ref = useRef();

  // Handle mouse clicks inside and outside the dropdown to know when to close it.
  const handleMouseClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  // Event listener for mouse clicks.
  useEffect(() => {
    document.addEventListener("mouseup", handleMouseClick)
    return () => {
      document.removeEventListener("mouseup", handleMouseClick)
    };
  }, []);

  return (
    <Container ref={ref} open={open} id={id} onClick={toggling}>
      {title && (
        <Wrapper >
          <H6>{title}</H6>
          <span>
            <ArrowDownIcon />
          </span>
        </Wrapper>
      )}
      {open && description && <p>{description}</p>}
    </Container>
  );
};
