import { ResponsiveHeatMap } from '@nivo/heatmap';
import { Theme } from 'src/components/Theme';
import { roundToDecimals } from 'src/helpers/numberHelper';
import { wordDelimiter } from 'src/helpers/wordDelimiter';

export const CorrelationChart = ({ title, correlationAttributes, correlationValues }) => {
  const colors = {
    type: "diverging",
    scheme: "red_blue",
    minValue: -0.85,
    maxValue: 0.85,
  }
  // Getting data by following the default expected type chart data.
  const data = correlationAttributes.map((attribute, i) => ({
    id: wordDelimiter(attribute, 40),
    data: correlationValues[i].map((value, j) => ({
      x: wordDelimiter(correlationAttributes[j], 40),
      y: roundToDecimals(value),
    })),
  }));

  return (
    (data.length !== 0) && (
      <div className='half-screen-wrapper'>
        <div className='eval-border'>
          <div className='H6 mx-5 my-[1.125rem] text-left'>{title}</div>
        </div>
        <div className='h-[35rem] px-4 eval-border'>
          <ResponsiveHeatMap
            data={data}
            margin={{ top: 30, right: 5, bottom: 90, left: 90}}
            axisTop={null}
            axisBottom={{
              tickSize: 15,
              tickPadding: 5,
              tickRotation: -30,
              legendOffset: 46,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            colors={colors}
            emptyColor="#555555"
            labelTextColor={`${Theme.colors.black}`}
            enableLabels={correlationAttributes.length<=10}
          />
        </div>
      </div>
    )
  );
};