import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const Logo = styled.img`
  width: 6.25rem;
  height: 1.25rem;
  mix-blend-mode: multiply;
  margin-bottom: 6.5rem;
  ${Theme.breakpoints.laptop} {
    width: 7.063rem;
    height: 1.438rem;
    mix-blend-mode: multiply;
  }
`;

export const LoggedInWrapper = styled.main`
  background-color: ${Theme.colors.lightgray};
  grid-area: main;
  max-width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`;

export const ToolbarWrapper = styled.div`
  position: relative;
  height: 100%;
  grid-area: toolbar;
  width: 200px;
  ${Theme.breakpoints.medium} {
     width: 75px;
  }
`;

export const OnboardingWrapper = styled.main`
  align-items: center;
  padding: 2.813rem 1rem;
  grid-area: main;
  margin-top:3rem;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  ${Theme.breakpoints.laptop} {
    padding: 2.813rem 4.375rem;
  }
`;

export const AppContainer = styled.div`
  display: grid;
  grid-template-areas: 'toolbar main';
  grid-template-columns: 200px auto;
  height: 100vh;
  column-gap: 0rem;
   ${Theme.breakpoints.medium} {
    grid-template-columns: 75px auto;
  }
`;