import { useState, useCallback } from 'react';
import { useApi } from 'src/api/useApi';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/contexts/UserContext';
import { Team } from 'src/views/Team/Team';
import { Button } from 'src/components/Button/Button';
import { Profile } from 'src/views/Profile/Profile';
import { ContactIcon } from 'src/assets/icons/ToolbarIcons/ContactIcon';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { Contact } from 'src/views/Contact/Contact';
import { userService } from 'src/api/services/userService';
import { useStorage } from 'src/contexts/useStorage';
import * as S from 'src/views/Settings/Settings.styles';

export const Settings = ({ setMultiTableView }) => {
  const navigate = useNavigate();
  const { getCurrentProjectId } = useStorage();
  const { currentUser: { role } } = useUser();
  const [isOpen, setOpen] = useState(false);

  // Get all the users from the current project.
  const getUsersForProject = useCallback(
    (token) => userService.getUsers({ token: token, projectId: getCurrentProjectId(), email: null }),
    [getCurrentProjectId]
  );
  const { data, fetch } = useApi({ fetcher: getUsersForProject, fetchOnLoad: true });

  return (
    <S.Wrapper>
      <div className="viewHeader">Settings</div>
      <S.SettingsButtonContainer>
        <Button onClick={() => navigate("/onboarding/create-project")}>
          Create new Project
        </Button>
        <SecondaryButton
          className="flex justify-center items-center"
          onClick={() => setOpen((prevState) => !prevState)}
        >
          <ContactIcon />
          Contact us
        </SecondaryButton>
      </S.SettingsButtonContainer>
      <Profile fetchUsersFromProject={fetch} setMultiTableView={setMultiTableView} />
      {(role === "admin" || role === "user") && (
        <Team data={data} fetchUsersFromProject={fetch} />
      )}
      {isOpen && <Contact isOpen={isOpen} setIsOpen={setOpen} />}
    </S.Wrapper>
  );
};
