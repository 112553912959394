import React from "react";

export const CancelIcon = () => {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 512 512"
    >
      <path
        fill="#FF7246"
        d="M83.753 511.961c-21.451 0-42.904-8.167-59.236-24.497-32.663-32.664-32.663-85.811 0-118.473l201.65-201.65c6.38-6.377 16.719-6.377 23.101 0 6.378 6.378 6.378 16.721 0 23.101l-201.65 201.65c-19.926 19.926-19.926 52.348 0 72.273 19.926 19.927 52.346 19.924 72.272 0L464.345 119.91c19.926-19.926 19.926-52.348 0-72.273-19.927-19.929-52.348-19.926-72.272 0l-51.881 51.881c-6.38 6.377-16.719 6.377-23.101 0-6.378-6.378-6.378-16.721 0-23.101l51.881-51.881c32.664-32.659 85.808-32.661 118.472 0 32.663 32.663 32.663 85.809 0 118.473L142.988 487.464c-16.332 16.33-37.783 24.497-59.235 24.497z"
      />
      <path
        fill="#FF7246"
        d="M475.894 475.914c-26.336 26.336-69.036 26.336-95.373 0L36.066 131.459c-26.336-26.336-26.336-69.036 0-95.373 26.336-26.336 69.036-26.336 95.373 0l344.455 344.455c26.337 26.337 26.337 69.036 0 95.373z"
      />
      <path
        fill="#FD5523"
        d="M428.208 512c-22.377 0-43.413-8.714-59.237-24.535L24.517 143.01c-32.663-32.664-32.663-85.809 0-118.473C40.341 8.714 61.377 0 83.753 0c22.377 0 43.413 8.714 59.238 24.535L487.445 368.99c15.822 15.824 24.535 36.86 24.535 59.238 0 22.377-8.714 43.413-24.535 59.238-15.824 15.82-36.86 24.534-59.237 24.534zM83.753 32.667c-13.648 0-26.483 5.315-36.135 14.968-19.926 19.926-19.926 52.348 0 72.273l344.455 344.455c9.652 9.653 22.487 14.968 36.137 14.968 13.648 0 26.483-5.315 36.135-14.968 9.653-9.652 14.968-22.487 14.968-36.137 0-13.65-5.315-26.485-14.968-36.137L119.889 47.636c-9.651-9.654-22.486-14.969-36.136-14.969z"
      />
    </svg>
  );
};
