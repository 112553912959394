import { useCallback, useState } from "react";

import { useApi } from "src/api/useApi";
import { modelService } from "src/api/services/modelService";
import { useTask } from "src/api/services/useTask";
import { SortingIcon } from "src/assets/icons/SortingIcon";
import { MultiTabelModal } from "src/components/Modal/MultiTableModal";
import { columnSortByNameAux } from "src/views/Files/utils";
import { SyntheticSchema } from "src/views/Files/MultiTable/SyntheticSchema";
import { isOpenTransitionCss } from "src/views/Files/utils";

const columnsToSortBy = {
  "Avg % Diff": "avg_pct_diff",
  "Avg WA": "avg_WA",
  "Avg MI": "avg_MI"
};

export const MTModelRow = ({ id, isOpen, mt_model, fetchSchemaSummary }) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const [numberRowFactor, setNumberRowFactor] = useState(1.0);
  // State for sorted column ascend or descend
  const [sorted, setSorted] = useState(true);
  const columnSort = ({ column }) => {
    columnSortByNameAux({ list: mt_model.synthetic_schemas, column: column, sortAscending: sorted });
    setSorted((prev) => (!prev));
  }

  const { fetch, error, setError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: fetchSchemaSummary,
        setTaskError: setError
      })
    ),
    fetcher: useCallback(
      (token, model_id) =>
        modelService.generateSchema({
          token: token,
          mtModelId: model_id,
          nrowFactor: numberRowFactor,
        }),
      [numberRowFactor]
    ),
  });

  const isSynSchemas = mt_model.synthetic_schemas.length > 0;

  return (
    <>
      <div
        data-testid="mt-model"
        className={`table-row bg-lite-pink text-xs text-orange font-font mt-models-rows transition duration-1000
          ${isOpenTransitionCss(isOpen)}
          ${isSynSchemas ? '' : 'mt-models-lower-row'}`}
      >
        <div className="border-y-0 bg-white" />
        <div className="border-l-[0.5px] border-red-300 text-end">
          <span>Model:</span>
        </div>
        <div className="px-2 text-start">
          <span>{mt_model.name}</span>
        </div>
        <div />
        <div />
        {["Avg % Diff", "Avg WA", "Avg MI"].map((item, i) => (
          <div key={i}>
            {isSynSchemas && (
              <div className="flex gap-x-1 items-center">
                <div key={i} className="cursor-pointer" onClick={()=> columnSort({ column: columnsToSortBy[item] })}>
                  <SortingIcon />
                </div>
                <span className="text-left">{item}</span>
              </div>
            )}
          </div>
        ))}
        <div>
          <div className="flex gap-x-1 justify-end">
            <span className="font-normal text-black">Nrow</span>
            <input
              name={`tr-${id}`}
              className="text-center w-[40px] bg-white rounded-xl focus:bg-lite-pink"
              placeholder="1.0"
              type="text"
              inputMode="decimal"
              min="1.0"
              max="100"
              step="0.1"
              pattern="[0-9]*[.,]?[0-9]*"
              onChange={(e) => setNumberRowFactor(e.target.value)}
            />
          </div>
        </div>
        <div className="border-r-[0.5px] border-red-300">
          <button
            className="button-style text-sm text-orange transition-colors focus:outline-none bg-transparant-orange hover:bg-TransparantOrange border border-orange hover:border-transparent px-6 py-[0.5px]"
            onClick={() => {
              fetch(mt_model.id);
              initTask({ initStatus: "Generate" });
            }}
          >
            Generate
          </button>
        </div>
      </div>
      {/* Render SyntheticSchema from selected mt_model */}
      {mt_model.synthetic_schemas.map((schema, i) => (
        <SyntheticSchema
          key={i}
          isOpen={isOpen}
          schema={schema}
        />
      ))}
      {taskMonitoring && <MultiTabelModal
        className="z-50"
        isOpen={!!taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {!!error && <MultiTabelModal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
        }}
      />}
    </>
  );
};
