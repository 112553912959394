import { useCallback, useState } from 'react';
import { useApi } from 'src/api/useApi';
import { useUser } from 'src/contexts/UserContext';
import { userService } from 'src/api/services/userService';
import { TextInput } from 'src/components/Input/TextInput';
import { Modal } from 'src/components/Modal/Modal';
import * as S from 'src/components/User/RenameUserModal.styles'

export const RenameUserModal = ({ isOpen, setIsOpen }) => {
  const { currentUser, fetchCurrentUser } = useUser();
  const [name, setName] = useState(currentUser.name);
  const [lastName, setLastName] = useState(currentUser.last_name);

  // Rename user name, last name and redirect to profile settings.
  const renameUser = useCallback(
    (token) => userService.patchUser({ token: token, id: currentUser.id, name: name, lastName: lastName, role: null }),
    [currentUser.id, name, lastName]
  );

  const onSuccess = () => {
    // Refetching data from currentUser to get user's latest information.
    fetchCurrentUser();
    // close modal
    setIsOpen(false);
  };
  const { error, fetch } = useApi({ fetcher: renameUser, fetchOnLoad: false, onSuccess: onSuccess });

  return (
    <S.RenameUserModalWrapper>
      <Modal
        isOpen={isOpen}
        title="Change your name"
        error={error}
        buttonText="Save"
        linkText="Cancel"
        buttonAction={() => {
          if (name !== currentUser.name || lastName !== currentUser.last_name) {
            fetch();
          } else {
            setIsOpen(false);
          }
        }}
        linkAction={() => setIsOpen(false)}
      >
        <div>
          <TextInput
            placeholder="First name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </Modal>
    </S.RenameUserModalWrapper>
  );
};
