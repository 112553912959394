import styled from "styled-components";
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const Wrapper = styled.div`
  H3 {
    margin-top: 2rem;
  }
  H3 {
    margin: 1rem;
  }
  span {
    padding: 0.5rem;
  }
  p {
    font-size: 0.7rem;
  }
  ${Theme.breakpoints.laptop} {
    p {
      font-size: 0.875rem;
    }
  }
  svg {
    cursor: pointer;
    margin-bottom:1rem;
  }
  H4{
    color:${Theme.colors.red};
  }
`;

export const PaperContainer = styled(Paper)`
  margin-top: 2rem;
  margin-bottom: 6.875rem;
  padding: 5rem 1rem;
  top: 10.5rem;
  Button {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    background-color: ${Theme.colors.TranparantOrange} !important;
  }
  ${Theme.breakpoints.laptop} {
    padding: 10.188rem 15em;
    p {
      font-size: ${Theme.fontSize.small};
      padding: 0rem 7rem;
    }
  }
`;


