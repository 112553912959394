import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';

export const Wrapper = styled.div`
  H3 {
    margin: 2rem;
  }
`;

export const PaperContainer = styled(Paper)`
  padding: 0;
  text-align: left;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  flex-basis: 50%;
  width: 50%;
`;

export const Footer = styled.footer`
  padding: 2rem;
  text-align: center;
  p {
    font-weight: 600;
  }
`;