import React from 'react';
import { Theme } from 'src/components/Theme';
import { Button } from 'src/components/Button/Button';
import { Variant } from 'src/components/Button/ButtonOptions';

export const SecondaryButton = ({ children, ...props }) => (
  <Button
    color={Theme.colors.red}
    bgColor={Theme.colors.TranparantOrange}
    variant={Variant.outlined}
    hover={Theme.colors.litepink}
    {...props}
  >
    {children}
  </Button>
);
