import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from 'src/auth/protected-route';
import { GlobalStyle } from 'src/components/Theme';
import { FileProvider } from 'src/contexts/FileContext';
import { TokenProvider } from 'src/contexts/TokenContext';
import { UserProvider } from 'src/contexts/UserContext';
import { ConnectorProvider } from 'src/contexts/ConnectorContext';
import { Faq } from 'src/views/Faq/Faq';
import { Evaluation } from 'src/views/Files/Evaluation/Evaluation';
import { Models } from 'src/views/Files/Generate/Models';
import Home from 'src/views/Home/Home';
import { HowItWorks } from 'src/views/HowItWorks/HowItWorks';
import { Parameters } from 'src/views/Parameters/Parameters';
import { CreateProjectSteps } from 'src/views/Project/CreateProjectSteps';
import { CreateUserName } from 'src/views/Project/CreateUserName';
import { JoinProject } from 'src/views/Project/JoinProject';
import { LoginRedirect } from 'src/views/Project/LoginRedirect';
import { SelectProject } from 'src/views/Project/SelectProject';
import Test from 'src/views/Test';
import { Toolbar } from "src/views/Dashboard/Toolbar";
import { Settings } from "src/views/Settings/Settings";
import * as S from "src/App.styles";
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';
import { useStorage } from 'src/contexts/useStorage';
import { PageNotFound } from 'src/views/PageNotFound/PageNotFound';
import { SchemaProvider } from './contexts/SchemaContext';
import { MTEvaluation } from './views/Files/MultiTable/MTEvaluation/MTEvaluation';

function App() {
  const { isLoading, error } = useAuth0(); // extract isLoading and error from Auth0 hook
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  return (
    <>
      <GlobalStyle />
      <TokenProvider>
        <UserProvider>
          <Routes>
            <Route path="/login" exact element={<ProtectedRoute component={LoginRedirect} />} />
            <Route path="/onboarding/*" element={<ProtectedRoute component={Onboarding} />} />
            <Route path="/*" element={<LoggedIn />} />
          </Routes>
        </UserProvider>
      </TokenProvider>
    </>
  );
}

const LoggedIn = () => {
  const [isMultiTableView, setMultiTableView] = useState(false);
  const { getCurrentProjectId } = useStorage();
  let projectId = getCurrentProjectId();
  if (!projectId) {
    //Current projectId has not been set, return LoginRedirect
    return <Navigate to="/login" />;
  }

  return (
    <S.AppContainer>
      <ConnectorProvider>
        <FileProvider>
          <SchemaProvider>
            <S.ToolbarWrapper>
              <Toolbar setMultiTableView={setMultiTableView} />
            </S.ToolbarWrapper>
            <S.LoggedInWrapper>
              <Routes>
                <Route index path="/" exact element={<ProtectedRoute component={Home} isMultiTableView={isMultiTableView} setMultiTableView={setMultiTableView} />} />
                <Route path="test" exact element={<ProtectedRoute component={Test} />} />
                <Route path="howitworks" exact element={<ProtectedRoute component={HowItWorks} />} />
                <Route path="faq" exact element={<ProtectedRoute component={Faq} />} />
                <Route path="parameters" exact element={<ProtectedRoute component={Parameters} />} />
                <Route path="models" exact element={<ProtectedRoute component={Models} />} />
                <Route path="evaluation" exact element={<ProtectedRoute component={Evaluation} />} />
                <Route path="settings" exact element={<ProtectedRoute component={Settings} setMultiTableView={setMultiTableView} />} />
                <Route path="mt_evaluation/:id" exact element={<ProtectedRoute component={MTEvaluation} />} />
                <Route path="*" exact element={<PageNotFound />} />
              </Routes>
            </S.LoggedInWrapper>
          </SchemaProvider>
        </FileProvider>
      </ConnectorProvider>
    </S.AppContainer>
  );
};

const Onboarding = () => (
  <S.OnboardingWrapper>
    <Routes>
      <Route path="create-project" exact element={<ProtectedRoute component={CreateProjectSteps} />} />
      <Route path="select-project" exact element={<ProtectedRoute component={SelectProject} />} />
      <Route path="user-name" exact element={<ProtectedRoute component={CreateUserName} />} />
      <Route path="user-no-project" exact element={<ProtectedRoute component={JoinProject} />} />
      <Route path="*" exact element={<PageNotFound />} />
    </Routes>
  </S.OnboardingWrapper>
);

export default App;
