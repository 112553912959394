import { Size } from 'src/components/Button/ButtonOptions';
import { Button } from 'src/components/Button/Button';
import { Spinner } from 'src/components/Loaders/Spinner';
import { Modal } from 'src/components/Modal/Modal';
import { EmailInputs } from 'src/components/Project/EmailInputs';
import { useInvite } from 'src/components/Project/useInvite';
import { Theme } from 'src/components/Theme';
import { useStorage } from 'src/contexts/useStorage';

// Invite users for an existing project.
export const InviteUsers = ({ fetchUsersFromProject }) => {
  const { getCurrentProjectId } = useStorage();

  // Use invite state from the invite hook.
  const {
    showSuccess,
    setShowSuccess,
    emails,
    setEmails,
    setRoles,
    addEmail,
    inviteUsers,
    loading,
    setLoading,
    error,
    setError,
  } = useInvite();

  return (
    <>
      <EmailInputs emails={emails} setEmails={setEmails} setRoles={setRoles} addEmail={addEmail} />
      {loading ? (
        <Spinner />
      ) : (
        <Button bgColor={Theme.colors.TranparantOrange} size={Size.small} onClick={() =>
          // do not send any invite if no email provided
          !emails.some(e => e === " " || e === "") && inviteUsers(getCurrentProjectId())
        }>
          Send invites
        </Button>
      )}
      <Modal
        isOpen={showSuccess}
        type="success"
        title="Your invitation was sent"
        description=""
        buttonText="Continue"
        buttonAction={() => {
          fetchUsersFromProject();
          setShowSuccess(false);
        }}
      />
      <Modal
        isOpen={!!error}
        type="error"
        title="Sorry, something went wrong"
        description={error}
        buttonText="Try again"
        linkText="Ok, close"
        buttonAction={() => {
          setError('');
          setLoading(false);
        }}
        linkAction={() => setError('')}
      />
    </>
  );
};
