import { useCallback, useState } from 'react';
import { useApi } from 'src/api/useApi';
import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';
import { projectService } from 'src/api/services/projectService';
import { TextInput } from 'src/components/Input/TextInput';
import { Modal } from 'src/components/Modal/Modal';
import { getCurrentProjectOrError } from 'src/contexts/aux';

export const RenameProjectModal = ({ isOpen, setIsOpen }) => {
  const { currentUser, fetchCurrentUser } = useUser();
  const { getCurrentProjectId } = useStorage();
  let currentProject =  getCurrentProjectOrError({ currentUser: currentUser, currentProjectId: getCurrentProjectId() });

  // Variables for type the project name and rename it.
  const [name, setName] = useState(currentProject.data.name);

  // Rename project and redirect to the home.
  const renameProject = useCallback(
    (token) => projectService.patchProject({ token: token, name: name, id: getCurrentProjectId() }),
    [name, getCurrentProjectId]
  );

  const onSuccess = () => {
    // fetchCurrentUser to get the new project name change
    fetchCurrentUser();
    // close rename modal
    setIsOpen(false);
  }
  const { error, fetch } = useApi({
    fetcher: renameProject,
    fetchOnLoad: false,
    onSuccess: onSuccess
  });

  return (
    <Modal
      isOpen={isOpen}
      title="Rename your project"
      error={error}
      buttonText="Save"
      linkText="Cancel"
      buttonAction={() => {
        if (name !== currentProject.data.name) {
          fetch();
        } else {
          setIsOpen(false);
        }
      }}
      linkAction={() => setIsOpen(false)}
    >
      <TextInput
        data-testid="rename-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};
