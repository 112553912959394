import styled from 'styled-components';
import { H6 } from 'src/components/Headers/Headers.style';
import { Theme } from 'src/components/Theme';

export const DropDownContainer = styled.div`
  width: 5rem;
  height: 0.75rem;
  cursor: pointer;
  right: 0rem;
  top: -1rem;
  ${Theme.breakpoints.laptop} {
    right: 2rem;
  }
`;

export const DropDownHeader = styled(H6)`
  margin-bottom: 0.5rem;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  position: relative;
  font-family: ${Theme.fontFamily.secondaryFont};
  font-size: ${Theme.fontSize.xsmall};
  font-style: ${Theme.fontWeight.regular};
  background: ${Theme.colors.white};
  border: 0.063rem solid #e7e7e7;
  z-index: 100;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -0.5rem;
    left: 40%;
    overflow: hidden;
    border: 0.6rem solid transparent;
    border-top: 0;
    border-bottom-color: ${Theme.colors.white};
  }
  &::before {
    top: -0.6rem;
    border-bottom-color: #e7e7e7;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  &:hover {
    font-weight: 600;
  }
`;
