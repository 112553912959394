import { RecIcon } from 'src/assets/icons/RecIcon';
import { Theme } from 'src/components/Theme';
import { roundToDecimals, roundIfNumber } from 'src/helpers/numberHelper';
import { Bar } from 'src/views/Files/Evaluation/Histogram/Bar';

export const HistogramSplit = ({
  attributeName,
  realDistributionBins,
  realDistributionProbabilities,
  synDistributionBins,
  synDistributionProbabilities,
  wassersteinValue,
}) => {
  // Getting real dataset bin (x axis) and probability (y axis) values.
  const realData = realDistributionBins?.map((bin, i) => ({
    bin: String(roundIfNumber(bin)),
    prob: roundIfNumber(realDistributionProbabilities[i]),
  }));
  // Getting synthetic dataset bin (x axis) and probability (y axis) values.
  const synData = synDistributionBins?.map((bin, i) => ({
    bin: String(roundIfNumber(bin)),
    prob: roundIfNumber(synDistributionProbabilities[i]),
  }));
  // Aux arrays for rendering
  const barVars = [
    [realData, "Real dataset", "real", Theme.colors.pink],
    [synData, "Synthetic dataset", "syn", Theme.colors.blue]
  ];

  return (
    <>
      {(realData || synData) && (
        <div className='eval-paper'>
          {barVars.map((item, i) => (
            <Bar
              key={i}
              data={item[0]}
              title={item[1]}
              icon={<RecIcon realOrSyn={item[2]}/>}
              attributeName={attributeName}
              color={item[3]}
              attrOrBin="bin"
            />
          ))}
          {(typeof wassersteinValue !== 'undefined') && (
            <div className='m-2 flex flex-col justify-center items-center w-full'>
              <div className='H6'>Wasserstein distance:</div>
              <p>{roundToDecimals(wassersteinValue, 4)}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};