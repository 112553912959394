import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const Paper = styled.div`
  box-sizing: border-box;
  text-align: center;
  background-color: ${Theme.colors.white};
  ${Theme.breakpoints.laptop} {
    width: 70rem;
  }
  ${Theme.breakpoints.desktopM} {
    width: 100rem;
  }
  ${Theme.breakpoints.medium} {
    width: 25rem;
  }
  ${Theme.breakpoints.small} {
    width: 20rem;
  }

`;
