// Helper function to return a project from logged in user (currentUser) with given id (currentProjectId)
export const getCurrentProjectOrError = ({ currentUser, currentProjectId }) => {
  if (!currentUser) {
    return { error: "No currentUser set", data: null };
  }
  const currentProject = currentUser.projects.find((p) => p.id === currentProjectId);
  if (!currentProject) {
    return { error: "No project exists in currentUser with given id", data: null };
  }
  return { error: null, data: currentProject };
}