import { callApi } from "src/api/services/aux";

export const datasetService = {
  // Endpoint for get real dataset info with project id, filename and/or id.
  // And destructuring params for passing undefine values.
  getRealDatasetsInfo: ({ token, projectId, connectorId, filename, fileId }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/real_datasets_info",
    params: {
      ...(projectId && { project_id: projectId }),
      ...(connectorId && { connector_id: connectorId}),
      ...(filename && { filename: filename }),
      ...(fileId && { id: fileId })
    }
  }),
  // Endpoint for patch real dataset info with description, id and/or metadata.
  // And destructuring params for passing undefine values.
  patchRealDatasetInfo: ({ token, description, id, metadata }) => callApi({
    token: token,
    method: "PATCH",
    endpoint: "/real_dataset_info",
    options: {
      body: JSON.stringify({
        ...(description && { description }),
        ...(id && { id }),
        ...(metadata && { meta_data: metadata })
      })
    }
  }),
  // Endpoint for get real dataset url with id.
  // And destructuring params for passing undefine values.
  getRealDatasetUrl: ({ token, fileId }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/real_dataset/file_url",
    params: { ...(fileId && { id: fileId }) }
  }),
  // Endpoint for get synthetic dataset url with id.
  // And destructuring params for passing undefine values.
  getSyntheticDatasetUrl: ({ token, fileId }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/synthetic_dataset/file_url",
    params: { ...(fileId && { id: fileId }) }
  }),
  // Endpoint for get real dataset rows with id.
  // Setup starRow with 0 and limit value.
  // And destructuring params for passing undefine values.
  getRealDatasetRows: ({ token, fileId, startRow, nrRows }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/real_dataset/rows",
    params: {
      ...(fileId && { id: fileId }),
      ...(startRow >= 0 && { start_row: startRow }),
      ...(nrRows && {nr_rows: nrRows})
    }
  }),
  // Endpoint for get synthetic dataset rows with id.
  // Setup starRow with 0 and limit value.
  // And destructuring params for passing undefine values.
  getSyntheticDatasetRows: ({ token, synFileId, startRow, nrRows }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/synthetic_dataset/rows",
    params: {
      ...(synFileId && { id: synFileId }),
      ...(startRow >= 0 && { start_row: startRow }),
      ...(nrRows && {nr_rows: nrRows})
    }
  }),
  // Endpoint for post real dataset.
  postRealDataset: ({ token, connectorId, file }) => {
    let data = new FormData();
    data.append('file', file);
    const url = new URL(process.env.REACT_APP_AUTH0_AUDIENCE + '/real_dataset');
    url.search = new URLSearchParams({ connector_id: connectorId }).toString();
    return fetch(url, {
      method: 'POST',
      body: data,
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  // Endpoint for delete real dataset with id.
  deleteRealDataset: ({ token, fileId }) => callApi({
    token: token,
    method: "DELETE",
    endpoint: "/real_dataset",
    params: { id: fileId }
  })
};