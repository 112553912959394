import { callApi } from "src/api/services/aux";

export const connectorService = {
  // Endpoint for getting connectors
  getConnectors: ({ token, projectId }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/connectors",
    params: { project_id: projectId },
  })
};