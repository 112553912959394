import { useState } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { CheckboxWrapper } from 'src/views/Files/Generate/CtganUtils/HyperParameters.styles';
import { QuestionIcon } from 'src/assets/icons/QuestionIcon';

export const HyperParametersModal = ({ setIsChecked, isChecked, setAutoParam }) => {
  const [isOpen, setIsOpen] = useState(false);
  //incase we need to disable the checkbox , change the following usestate to ture
  const disabled = false;
  // function to update state of the checkbox
  const checkHandler = () => { setIsChecked(current => !current)};

  return (
    <CheckboxWrapper>
      <input
        type="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={checkHandler}
        onInput={setAutoParam}
        disabled={disabled}
      />
      <span htmlFor="checkbox" >Auto-set parameters</span>
      <QuestionIcon onClick={()=>setIsOpen(!isOpen)}/>
       {isOpen &&
        <Modal
          isOpen={isOpen}
          title="Auto-set hyper parameters"
          type="error"
          description="Auto setting hyper parameters provides an automatic recommendation of parameters that might result in better quality of synthetic data. Training will take a while, so relax and grab a cup of coffee ☕"
          buttonText="OK"
          buttonAction={() => {
            setIsOpen(false)
          }}
        />
      }
    </CheckboxWrapper>
  );
}
