import * as S from "src/views/Dashboard/ToolbarItem/ToolbarItem.styles";

export const ToolbarItem = ({ item }) => (
  <S.ToolbarItemLink
    exact="true"
    to={item.href}
    activeclassname="active-item"
    onClick={item.onClick}
    id="load-btn"
  >
    <div>
      {item.icon}
      <span>{item.title}</span>
    </div>
  </S.ToolbarItemLink>
);
