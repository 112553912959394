import { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { datasetService } from "src/api/services/datasetService";
import { useTask } from "src/api/services/useTask";
import { useApi } from "src/api/useApi";
import { UploadIcon } from "src/assets/icons/ToolbarIcons/UploadIcon";
import { Modal } from "src/components/Modal/Modal";
import { Theme } from "src/components/Theme";
import { useFiles } from "src/contexts/FileContext";

export const UploadFile = ({
  connectorId,
  isButton = false,
}) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const navigate = useNavigate();
  const { files, fetchFiles, uploadFileRef } = useFiles();
  const [filename, setFilename] = useState('');
  // Show successful modal.
  const [showSuccess, setShowSuccess] = useState(false);

  // Post a dataset, show success modal and then redirect to home.
  const uploadDatasetCallback = useCallback(
    (token, newDataset) => {
      // If the dataset name already exists don't call the api.
      if (files.some((dataset) => dataset.filename === newDataset.name)) {
        throw new Error("A file with this name already exists");
      }
      return datasetService.postRealDataset({
        token: token,
        connectorId: connectorId,
        file: newDataset,
      });
    },
    [files, connectorId]
  );

  // set taskCallable
  const taskCallable = () => {
    setShowSuccess(true);
    fetchFiles();
    window.location.pathname !== "/" && navigate("/");
  }

  const { fetch: uploadDataset, error, setError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: taskCallable,
        setTaskError: setError
      })
    ),
    fetcher: uploadDatasetCallback
  });

  //Aux variables for uploading modal
  const modalOpen = taskMonitoring || showSuccess;
  const modalType = (modalOpen && taskMonitoring) ? "progress-spinner" : "success"
  const modalTitle = (modalOpen && taskMonitoring) ? taskStatus : `${filename} has been uploaded`;
  const modalBText = (modalOpen && taskMonitoring) ? "" : "Continue";

  return (
    <>
      <form>
        <div
          className={`flex items-center w-full cursor-pointer px-4 py-1 text-[0.875rem] font-secondaryFont hover:bg-light-gray hover:text-orange
            ${
              !isButton
                ? "hover:rounded-l-3xl transition-all duration-500 ease-in-out hover:duration-500"
                : "border border-[#FF7246] bg-white text-black rounded-3xl hover:text-black hover:bg-[#ff724633]"
            }
            disabled:text-light-gray disabled:cursor-none
            `}
          onClick={() => (uploadFileRef.current && uploadFileRef.current.click())}
        >
          <div className="w-8 h-6 m-2">
            <UploadIcon />
          </div>
          <div className="max-md:hidden" color={Theme.colors.pitchblack}>
            Upload File
          </div>
        </div>
        <input
          type="file"
          ref={uploadFileRef}
          data-testid="file-input"
          accept=".csv"
          onChange={(e) => {
            const file = e.target.files[0];
            setFilename(file.name);
            initTask({ initStatus: `Uploading ${file.name}` });
            uploadDataset(file);
          }}
          hidden
        />
      </form>
      {modalOpen && <Modal
        isOpen={modalOpen}
        progress={taskProgress}
        type={modalType}
        title={modalTitle}
        buttonText={modalBText}
        buttonAction={() => setShowSuccess(false)}
      />}
      {!!error && <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
        }}
      />}
    </>
  );
};