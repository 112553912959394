import { HistogramSplit } from 'src/views/Files/Evaluation/Histogram/HistogramSplit';

const getBins = (attribute) => {
  // Getting distribution bins and probability.
  let distributionBins = attribute.distribution_bins;
  // If there are distribution bins, we will return with the distribution bins value.
  // Otherwise it will be null.
  if (!!distributionBins) {
    if (attribute.is_categorical === false) {
      // If is_categorical is false we take the average of consequent bins.
      const newBins = [];
      for (let i = 0; i < distributionBins?.length - 1; i++) {
        newBins.push((distributionBins[i] + distributionBins[i + 1]) / 2);
      }
      distributionBins = newBins;
    }
    if (attribute.data_type === "DateTime") {
      for (let i = 0; i < distributionBins?.length; i++) {
        let binDatetime = new Date(distributionBins[i] * 1000).toLocaleString();
        distributionBins[i] = binDatetime;
      }
    }
  }
  return distributionBins;
};

export const Histogram = ({ realMData, synMData, evaluation }) => {
  // Getting real and synthetic datasets attributes
  const realAttributes = realMData.attributes;
  const attributeNames = Object.keys(realAttributes);
  const synAttributes = synMData.attributes;
  // Get wasserstein metrics
  const wassAttributes = evaluation.wasserstein_attr;
  const wassValues = evaluation.wasserstein_values;

  return (
    <>
      {attributeNames.map((name) => {
        const realDistributionBins = getBins(realAttributes[name]);
        const realDistributionProbabilities = realAttributes[name].distribution_probabilities;
        const synDistributionBins = getBins(synAttributes[name]);
        const synDistributionProbabilities = synAttributes[name].distribution_probabilities;
        // Get the Wasserstein value at the index where we find the Wasserstein attribute.
        const wassersteinValue = wassValues[wassAttributes.indexOf(name)];

        return (
          <HistogramSplit
            key={name}
            attributeName={name}
            realDistributionBins={realDistributionBins}
            realDistributionProbabilities={realDistributionProbabilities}
            synDistributionBins={synDistributionBins}
            synDistributionProbabilities={synDistributionProbabilities}
            wassersteinValue={wassersteinValue}
          />
        );
      })}
    </>
  );
};