import { RecIcon } from "src/assets/icons/RecIcon";
import { Theme } from "src/components/Theme";
import { roundIfNumber, roundToDecimals } from "src/helpers/numberHelper";
import { wordDelimiter } from "src/helpers/wordDelimiter";
import { getMissingAttributes } from "src/helpers/getMissingAttributes";
import { Bar } from "src/views/Files/Evaluation/Histogram/Bar";
import { CorrelationChart } from "src/views/Files/Evaluation/Correlation/CorrelationChart";
import { BottomCorrelation } from "src/views/Files/Evaluation/Correlation/Correlation";

export const NullValues = ({ realMData, synMData, evaluation }) => {
  // Getting real and synthetic datasets null correlation values attributes and values
  const realNullAttributes =
    realMData.null_values_correlation_matrix.attributes;
  const realNullValues = realMData.null_values_correlation_matrix.values;
  const synNullAttributes = synMData.null_values_correlation_matrix.attributes;
  const synNullValues = synMData.null_values_correlation_matrix.values;
  // Get missing attributes
  const missingAttributes = getMissingAttributes(
    evaluation.null_corr_euclidean_missing_attr
  );
  // Get null correlation distance
  const nullCorrelationDistance = evaluation.null_corr_euclidean_distance;
  // Getting the missing rate attributes for real and synthetic files.
  const missingRateAttr = evaluation.missing_rate_attr;
  const realMissingRate = evaluation.missing_rate_u;
  const synMissingRate = evaluation.missing_rate_v;
  // Getting real data attributes (x axis) and rate (y axis) values.
  const realData = missingRateAttr?.map((attr, i) => ({
    attr: wordDelimiter(attr),
    rate: roundIfNumber(realMissingRate[i]),
  }));
  // Getting real data attributes (x axis) and rate (y axis) values.
  const synData = missingRateAttr?.map((attr, i) => ({
    attr: wordDelimiter(attr),
    rate: roundIfNumber(synMissingRate[i]),
  }));
  // Auxiliary arrays for rendering
  const auxArrayNullValuesBar = [
    [realData, "Real dataset missing rates", "real", Theme.colors.pink],
    [synData, "Synthetic dataset missing rates", "syn", Theme.colors.blue]
  ];
  const auxArrayNullValuesChart = [
    ["Real dataset missing values correlation", realNullAttributes, realNullValues],
    ["Synthetic dataset missing values correlation", synNullAttributes, synNullValues]
  ];
  const thirdBlockVars = [
    ["Missing nullable attributes:", missingAttributes],
    ["Missing values correlation euclidean distance:", roundToDecimals(nullCorrelationDistance, 4)],
  ];

  return (
    <>
      <div className="eval-paper">
        {auxArrayNullValuesBar.map((item, i) => (
          <Bar
            key={i}
            data={item[0]}
            title={item[1]}
            icon={<RecIcon realOrSyn={item[2]}/>}
            color={item[3]}
            attrOrBin="attr"
          />
        ))}
        {auxArrayNullValuesChart.map((item, i) => (
          <CorrelationChart
            key={i}
            title={item[0]}
            correlationAttributes={item[1]}
            correlationValues={item[2]}
          />
        ))}
        {thirdBlockVars.map((item, i) => (
          <BottomCorrelation key={i} title={item[0]} sub={item[1]}/>
        ))}
      </div>
    </>
  );
};