import { useCallback, useState } from "react";
import { useApi } from "src/api/useApi";
import { DropdownRole } from "src/components/Dropdown/DropdownRole";
import { Spinner } from "src/components/Loaders/Spinner";
import { DeleteUserProjectModal } from "src/components/Project/DeleteUserProjectModal";
import { userService } from "src/api/services/userService";
import { RoleWrapper, Row, IconWrapper } from "src/views/Team/Team.styles";
import { DeleteIcon } from "src/assets/icons/DeleteIcon";

export const TeamRow = ({ user, fetchUsersFromProject }) => {
  // Popup modal for delete user member.
  const [deleteUserIsOpen, setDeleteUserIsOpen] = useState(false);

  // Patch the user from the current project.
  const changeRole = useCallback(
    (token, newRole) => userService.patchUser({ token: token, id: user.id, name: null, lastName: null, role: newRole }),
    [user]
  );

  const { fetch, loading } = useApi({
    fetcher: changeRole,
    fetchOnLoad: false,
    onSuccess: (data) => user.role = data.role // assign new role
  });

  return (
    <>
      <Row>
        {loading ? (
          <Spinner size="small" />
        ) : (
          <RoleWrapper>
            <DropdownRole
              role={user.role}
              // Change the role with the same index as the current user.
              onChange={(option) => (option.value !== user.role) && fetch(option.value) }
            />
          </RoleWrapper>
        )}
      </Row>
      <Row>
        <IconWrapper title="Delete User" onClick={() => setDeleteUserIsOpen(true)}>
          <DeleteIcon />
        </IconWrapper>
      </Row>
      {deleteUserIsOpen && (
        <DeleteUserProjectModal
          isOpen={deleteUserIsOpen}
          setIsOpen={setDeleteUserIsOpen}
          user={user}
          fetchUsersFromProject={fetchUsersFromProject}
        />
      )}
    </>
  );
};
