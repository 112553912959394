import styled from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const StepOnePaper = styled(Paper)`
  Button {
    margin-top: 3rem;
  }
  ${Theme.breakpoints.laptop} {
    p {
      padding: 0rem 4rem;
    }
    Button {
      margin-bottom: 2rem;
    }
  }
  padding: 2rem;
`;

export const StepTwoPaper = styled(Paper)`
  Button {
    margin-bottom: 2.5rem;
  }
  p {
    margin-bottom: 4rem;
  }
  padding: 2rem;
`;

export const StepTwoInput = styled.div`
  margin-bottom: 0.5rem;
`;

export const StepThreePaper = styled(Paper)`
  Button {
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  }
  padding: 2rem;
`;

export const StepThreeText = styled.div`
  margin: 1rem 1rem 4rem 1rem;
`;

export const StepThreeInput = styled.div`
  position: relative;
  margin-bottom: 2.5rem;
`;

export const EmailWrapper = styled.div`
  position: relative;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ul {
    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
  }
`;

export const AddEmailButton = styled.div`
  cursor: pointer;
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -2rem;
  font-weight: bold;
  font-size: ${Theme.fontSize.small};
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
