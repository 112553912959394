import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'src/api/useApi';
import { useQuery } from 'src/api/useQuery';
import { LinkButton } from 'src/components/Button/LinkButton';
import { Modal } from 'src/components/Modal/Modal';
import { useFiles } from 'src/contexts/FileContext';
import { modelService } from 'src/api/services/modelService';
import { Questions } from 'src/views/Faq/Questions';
import { Model } from 'src/views/Files/Generate/Model';
import { Flex, LinkWrapper, Wrapper } from 'src/views/Files/Generate/Models.styles';

export const Models = () => {
  const navigate = useNavigate();
  const query = useQuery();

  // Get real dataset by id
  const { files, getFile } = useFiles();
  const dataset = getFile(query.get('fileId'));

  // Get synthetic models for data generation.
  const getModels = useCallback((token) => modelService.getModels({ token: token }), []);

  const { data: models, error, setError, loading } = useApi({ fetcher: getModels, fetchOnLoad: true });

  return (
    <Wrapper>
      <div className='lg:py-4 lg:px-96'>
        <div className='viewHeader'>Syndata's models</div>
        <p>
          Select the model to generate data from, enter number of rows to generate, and click
          Generate.
        </p>
        <LinkWrapper>
          <LinkButton onClick={() => navigate(`/faq?id=${Questions.models.find((q) => q.id === 7).id}`)}>
            Not sure which model to choose?
          </LinkButton>
        </LinkWrapper>
        <p>
          File to generate from<strong> {files && dataset.filename}</strong>
        </p>
      </div>
      <Flex>
        {files && !loading && models.map((model) => (
          <Model key={model.id} model={model} fileId={dataset.id} />
        ))}
      </Flex>
      <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Try again"
        buttonAction={() => {
          setError('');
        }}
      />
    </Wrapper>
  );
};
