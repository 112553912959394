import { useState } from 'react';

import { QuestionIcon } from 'src/assets/icons/QuestionIcon';
import { LinkButton } from 'src/components/Button/LinkButton';
import { CorrelationEuclidianInfoModal } from 'src/components/Files/CorrelationEuclidianInfoModal';
import { NullValuesEuclidianInfoModal } from 'src/components/Files/NullValuesEuclidianInfoModal';
import { WassersteinInfoModal } from 'src/components/Files/WassersteinInfoModal';
import { getMetricColor } from 'src/helpers/getMetricColor';
import { roundToDecimals } from 'src/helpers/numberHelper';

export const Quality = ({ setActiveView, evaluation }) => {
  // Evaluation framework metrics
  const wassersteinAverage = roundToDecimals(evaluation.wasserstein_avg, 4);
  const mutualInformationEuclidianDistance = roundToDecimals(evaluation.mutual_information_euclidean_distance, 4);
  const nullCorrelationEuclidianDistance = roundToDecimals(
    evaluation.null_corr_euclidean_distance,
    4
  );

  // Show info modal
  const [wassersteinModal, setWassersteinModal] = useState(false);
  const [mutualInformationEuclidianModal, setMutualInformationEuclidianModal] = useState(false);
  const [nullValuesEuclidianModal, setNullValuesEuclidianModal] = useState(false);

  const cssCircle = (value) => (
    `w-56 h-56 rounded-full ${getMetricColor(value)}
      flex-view font-font font-bold text-2xl`
  );

  const qualityInfo = [
    [2, 'Distributions similarity', setWassersteinModal, wassersteinAverage],
    [4, 'Joint-distributions similarity', setMutualInformationEuclidianModal, mutualInformationEuclidianDistance],
    [5, 'Null values evaluation', setNullValuesEuclidianModal, nullCorrelationEuclidianDistance],
  ]

  return (
    <>
      <div className='paper mt-8 p-4 flex flex-col w-l md:flex-row justify-around'>
        {qualityInfo.map((info, idx) => (
          <div key={idx} className='my-4 mx-8'>
            <div className='flex justify-center gap-1 mb-6'>
              <LinkButton onClick={() => setActiveView(info[0])}>
                {info[1]}
              </LinkButton>
              <QuestionIcon onClick={() => info[2](true)} />
            </div>
            <div className={cssCircle(info[3])}>
              {info[3]}
            </div>
          </div>
        ))}
      </div>
      {wassersteinModal && (
        <WassersteinInfoModal isOpen={wassersteinModal} setIsOpen={setWassersteinModal} />
      )}
      {mutualInformationEuclidianModal && (
        <CorrelationEuclidianInfoModal
          isOpen={mutualInformationEuclidianModal}
          setIsOpen={setMutualInformationEuclidianModal}
        />
      )}
      {nullValuesEuclidianModal && (
        <NullValuesEuclidianInfoModal
          isOpen={nullValuesEuclidianModal}
          setIsOpen={setNullValuesEuclidianModal}
        />
      )}
    </>
  );
};