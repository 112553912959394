import { A, StyledLink } from 'src/components/Link/Link.styles';

export const Variant = {
  filled: 'filled',
  outlined: 'outlined',
};

export const ExternalLink = ({ children, ...props }) => {
  return (
    <A target="_blank" rel="noreferrer" {...props}>
      {children}
    </A>
  );
};

export const Link = ({ children, ...props }) => <StyledLink {...props}>{children}</StyledLink>;