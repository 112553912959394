import { UsersIcon } from 'src/assets/icons/UsersIcon';
import {  H6 } from 'src/components/Headers/Headers.style';
import { InviteUsers } from 'src/components/Project/InviteUsers';
import { useUser } from 'src/contexts/UserContext';
import { InviteUsersWrapper}  from 'src/views/Team/Team.styles';
import { TeamRow } from 'src/views/Team/TeamRow';
import * as S from 'src/views/Settings/Settings.styles';

export const Team = ({ data, fetchUsersFromProject }) => {
  const { currentUser } = useUser();

  // Filter current user from the list of the users and sort by role.
  const users = data && data
    .filter(user => user.id !== currentUser.id)
    .sort((a, b) =>
      a.role < b.role ? -1 :
      a.role > b.role ? 1 : 0
    );

  return (
    <S.Wrapper>
      <S.PaperContainer>
        <S.ContainerWrapper>
          <H6 className='flex justify-start'>
            <UsersIcon />
            <span>Project Members</span>
          </H6>
        </S.ContainerWrapper>
        <S.SubContainer>
          <H6>Name</H6>
          <H6>Role</H6>
        </S.SubContainer>
        <S.SubContainer>
          <p>
            {currentUser.name + " " + currentUser.last_name} <b>(You)</b>
          </p>
          <H6>{currentUser.role[0].toUpperCase() + currentUser.role.slice(1)}</H6>
        </S.SubContainer>
        {users && users.map((user, i) => (
          // Map users and index to an user row.
          <S.SubContainer key={i}>
            {user.name && user.last_name ? (
              <p>{`${user.name} ${user.last_name}`}</p>
            ) : (
              <p>{`${user.email}`}</p>
            )}
            <TeamRow user={user} fetchUsersFromProject={fetchUsersFromProject} />
          </S.SubContainer>
        ))}
        <InviteUsersWrapper>
          <InviteUsers fetchUsersFromProject={fetchUsersFromProject} />
        </InviteUsersWrapper>
      </S.PaperContainer>
    </S.Wrapper>
  );
};
