
import { H4 } from 'src/components/Headers/Headers.style';
import { Button } from 'src/components/Button/Button';
import * as S from 'src/views/PageNotFound/PageNotFound.styles';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <S.Wrapper>
      <S.PaperContainer>
        <div className='flex justify-center'>
          <ExclamationIcon/>
        </div>
       <H4>This page doesn't exist!</H4>
       <p>Sorry, the page you were looking for could not be found.</p>
       <Button onClick={() => navigate('/')}>Go back to Home</Button>
      </S.PaperContainer>
    </S.Wrapper>
  );
};