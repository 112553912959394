export const wordDelimiter = (str, limit = 16) => {
  // Capitalize only the first letter of the string.
  if (str.length>0) {
    const newStr = str[0].toUpperCase() + str.slice(1);
    // Limit number of characters to 20.
    return newStr.length > limit ? newStr.substring(0, limit) + '...' : newStr;
  } else return str;
};

export const delimiterWord = (str, limit) => {
  // Limit number of characters.
  return str?.length > limit ? str.substring(0, limit) + '...' : str;
};
