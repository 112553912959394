import styled from 'styled-components';
import { H6 } from 'src/components/Headers/Headers.style';
import { Theme } from 'src/components/Theme';

export const TooltipWrapper = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding-top: 1rem;
  ${Theme.breakpoints.laptop} {
    padding-top: 2rem;
  }
`;

export const TextWrapper = styled.p`
  width: 100%;
  padding-left: 2rem;
  color: ${Theme.colors.orange};
  font-family: ${Theme.fontFamily.font};
  font-style: normal;
  margin: 1rem;
  font-size: ${Theme.fontSize.xlarge};
  text-align: left;
  position: absolute;
  ${H6} {
    cursor: pointer;
  }
  ${Theme.breakpoints.laptop} {
    font-size: 2rem;
    &:hover {
      font-weight: bold;
    }
  }
`;

export const SvgWrapper = styled.div`
  padding: 1rem 0rem;
  display: flex;
  svg{
    width: 50px;
  }
  ${Theme.breakpoints.laptop} {
    padding: 1rem 0;
  }
`;

export const Span = styled.span`
  text-align: left;
  cursor: pointer;
  background-color: ${Theme.colors.lightgray};
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  height: 100%;
  width: 100%;
  position: absolute;
  visibility: visible;
  top: 0rem;
  border-style: solid solid solid solid;
  border-color: ${Theme.colors.TranparantOrange};
  border-width: 0.063rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
