import { useState } from 'react';
import { H6 } from 'src/components/Headers/Headers.style';
import { Link } from 'src/components/Link/Link';
import { Span, SvgWrapper, TextWrapper, TooltipWrapper } from 'src/components/Tooltip/Tooltip.styles';

export const Tooltip = ({
  number,
  icon,
  title,
  text,
  linkAction,
  linkText,
  children,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  // Handle tooltip to open when we hover over the title.
  const onHover = () => {
    setHover(!hover);
  };

  // Handle tooltip to close when we move the mouse outside the component.
  const onLeave = () => {
    setHover(false);
  };

  return (
    <TooltipWrapper role="button" onMouseEnter={onHover} {...props}>
      <TextWrapper> {number}</TextWrapper>
      <SvgWrapper className='flex justify-center items-center'>{icon}</SvgWrapper>
      <H6>{title}</H6>
      {hover && (
        <Span onMouseLeave={onLeave}>
          {text}
          <Link to={linkAction}>
            {' '}
            <u>{linkText}</u>
          </Link>
        </Span>
      )}
      {children}
    </TooltipWrapper>
  );
};
