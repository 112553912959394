import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const DarkSvg = styled.svg`
  cursor: pointer;
  &:hover,
  &:active {
    fill: ${Theme.colors.pitchblack};
    transition: background-color 0.5s;
  }
`;