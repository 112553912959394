import { Svg } from 'src/assets/icons/PlusIcon/PlusIcon.styles';
export const PlusIcon = () => {
    return (
      <Svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="33" height="31" rx="5.5" stroke="#FD5523" />
        <path
          d="M14 16H20"
          stroke="#FD5523"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 13V19"
          stroke="#FD5523"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  };
  