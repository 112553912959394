import { AddEmailButton,EmailWrapper } from 'src/views/Project/CreateProjectSteps.styles';
import { DropdownRole } from 'src/components/Dropdown/DropdownRole';
import { PlusIcon } from 'src/assets/icons/PlusIcon/PlusIcon';
import { EmailInput } from 'src/components/Input/EmailInput';

// A list of email inputs that can be extended with more inputs by clicking a button.
export const EmailInputs = ({ emails, setEmails, setRoles, addEmail }) => {
  return (
    <>
      <EmailWrapper>
        <ul>
          {emails.map((email, i) => (
            <li key={i}>
              {/* // Map email and index to an email input. */}
              <EmailInput
                data-testid="invite-email-input"
                value={email}
                // Change the email with the same index as the input.
                onChange={(e) => {
                  setEmails((current) => {
                    current[i] = e.target.value;
                    return [...current];
                  });
                }}
              />
              <DropdownRole
                // Change the role with the same index as the input.
                onChange={(option) =>
                  setRoles((current) => {
                    current[i] = option.value;
                    return [...current];
                  })
                }
              />
            </li>
          ))}
      </ul>
      </EmailWrapper>
      <AddEmailButton className="flex justify-center" data-testid="add-email-button" onClick={() => {
        !emails.some(e => e === " " || e === "") && addEmail();
      }}>
        <PlusIcon />
      </AddEmailButton>
    </>
  );
};
