import { useState } from 'react';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { ToggleButton } from 'src/components/Button/ToggleButton';
import { DropdownDataTypes } from 'src/components/Dropdown/DropdownDataTypes';
import { QuestionIcon } from 'src/assets/icons/QuestionIcon';
import { CategoricalInfoModal } from 'src/components/Parameters/CategoricalInfoModal';
import { DataTypeInfoModal } from 'src/components/Parameters/DataTypeInforModal';
import { UniqueInfoModal } from 'src/components/Parameters/UniqueInfoModal';
import { Theme } from 'src/components/Theme';
import { Toggle } from 'src/components/Toggle/Toggle';
import { wordDelimiter } from 'src/helpers/wordDelimiter';

export const Parameter = ({
  attribute,
  rows,
  attributeMetaData,
  toggleConfirm,
  isConfirmed
}) => {
  // Show info modal.
  const [dataTypeModal, setDataTypeModal] = useState(false);
  const [uniqueModal, setUniqueModal] = useState(false);
  const [categoricalModal, setCategoricalModal] = useState(false);
  // The initial data-type for the parameter
  const [dataType, setDataType] = useState(attributeMetaData.data_type);

  // Will update metadata on changes.
  const setAttrMDataKeyValue = ({ key, value }) => {
    if (key === "data_type") {
      setDataType(value);
    }
    attributeMetaData[key] = value;
    // when is_categorical is true
    if (key === "is_categorical" && attributeMetaData[key]) {
      // delete sub_type key from attribute
      delete attributeMetaData["sub_type"];
    }
  }

  return (
    <> 
      <div className='mx-2 mt-4 mb-8 flex flex-col items-center' data-testid={attribute}>
        <Toggle isChecked={isConfirmed} />
        <div className='table'>
          <div className='table-row'>
            <div className='table-cell h-8 w-44 border border-gray bg-black text-white font-bold align-middle'>
              {wordDelimiter(attribute)}
            </div>
          </div>
          {rows.map((element, i) => (
            <div key={i} className='table-row'>
              <div className='table-cell h-8 border border-gray border-t-0 align-middle hover:bg-lite-pink'>
                {wordDelimiter(element)}
              </div>
            </div>
          ))}
        </div>
        <div className='H6 text-left mt-4 mb-2 flex'>
          Type of data: <QuestionIcon onClick={() => setDataTypeModal(true)} />
        </div>
        <DropdownDataTypes
          disabled={isConfirmed}
          dataType={dataType}
          onChange={(option) => {
            setAttrMDataKeyValue({ key: 'data_type', value: option.value })
          }}
        />
        <div className='H6 text-left mt-4 mb-2 flex'>
          Unique values: <QuestionIcon onClick={() => setUniqueModal(true)} />
        </div>
        <ToggleButton
          className="w-full p-0 h-8 flex-view"
          dataTestid="toggle-unique"
          value={attributeMetaData.is_unique ? 'Yes' : 'No'}
          labels={['Yes', 'No']}
          disabled={isConfirmed || dataType === 'Constant'}
          onChange={(label) => setAttrMDataKeyValue({ key: 'is_unique', value: label === 'Yes' }) }
        />
        <div className='H6 text-left mt-4 mb-2 flex'>
          Categorical values: <QuestionIcon onClick={() => setCategoricalModal(true)} />
        </div>
        <ToggleButton 
          className="w-full p-0 h-8 flex flex-view"
          dataTestid="toggle-categorical"
          value={attributeMetaData.is_categorical ? 'Yes' : 'No'}
          labels={['Yes', 'No']}
          disabled={isConfirmed || dataType === 'Constant'}
          onChange={(label) => setAttrMDataKeyValue({ key: 'is_categorical', value: label === 'Yes' }) }
        />
        {isConfirmed ? (
          <SecondaryButton
            data-testid={`${attribute}`}
            fullWidth
            color={Theme.colors.darkgray}
            hover={Theme.colors.lightgray}
            onClick={() => toggleConfirm()}
          >
            Unconfirm
          </SecondaryButton>
        ) : (
          <SecondaryButton fullWidth onClick={() => toggleConfirm()}>
            Confirm
          </SecondaryButton>
        )}
      </div>
      {dataTypeModal && <DataTypeInfoModal isOpen={dataTypeModal} setIsOpen={setDataTypeModal} />}
      {uniqueModal && <UniqueInfoModal isOpen={uniqueModal} setIsOpen={setUniqueModal} />}
      {categoricalModal && <CategoricalInfoModal isOpen={categoricalModal} setIsOpen={setCategoricalModal} />}
    </>
  );
};
