import { useCallback, useState } from "react";

import { useApi } from "src/api/useApi";
import { modelService } from "src/api/services/modelService";
import { CancelIcon } from "src/assets/icons/CancelIcon";
import { InputExtra } from "src/views/Files/Generate/Models.styles";

export const MTModelsTrainView = ({
  setIsOpen,
  fetchTrainSchemaWrapup,
}) => {
  const [extraInput, setExtraInput] = useState("");
  const CTGAN_PARAMETERS = {
    EPOCHS: '{"table":{"epochs": 300}}', //initial display
  };

  const { data: models, loading } = useApi({
    fetcher: useCallback(
      (token) => modelService.getModels({ token: token }),
      []
    ),
    fetchOnLoad: true,
  });

  //Set initExtraInputParam value on the extra input parameter field
  const setInitExtraInputParam = (value) => {
    if (value === "") {
      setExtraInput(CTGAN_PARAMETERS.EPOCHS);
    }
  };

  return (!loading &&
    <div className="fixed inset-0 flex items-center justify-center z-10 overflow-auto">
      <div className="modal-overlay bg-black opacity-50 fixed inset-0" />
      <div className="modal-container w-2/3 max-md:w-4/5 z-50">
        <div className="bg-white shadow-3xl p-8 m-auto padding-x">
          <div className="cursor-pointer flex justify-end" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </div>
          <div className="flex flex-col items-center gap-5">
            <div className="H5 justify-center">Choose Multitable Model to Train</div>
            <div className="flex flex-col justify-start w-[89%] mt-4">
              <div className="flex flex-row justify-start items-center ml-3">
                <p>Extra Input Parameters</p>
                <InputExtra
                  data-testid="extra-input"
                  placeholder={CTGAN_PARAMETERS.EPOCHS}
                  value={extraInput}
                  onClick={(e) => setInitExtraInputParam(e.target.value)}
                  onChange={(e) => setExtraInput(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 justify-center items-center">
              {models.map((model) => (
                <div key={model.id} className=" max-w-sm h-[300px] rounded overflow-hidden shadow-lg hover:bg-lite-pink border-orange ">
                  <img
                    className="w-full h-28 p-2 object-cover object-center"
                    src={model.meta_data.webapp.model_image_url}
                    alt="Models images"
                  />
                  <div className="px-6 py-4 h-max">
                    <div className="font-bold text-xl mb-2">
                      {model.modelname}MT
                    </div>
                    <p className="text-gray-700 text-base h-16">
                      {model.description}{" "}
                    </p>
                    <div className="flex items-center justify-center justify-items-end ">
                      <div className="p-2">
                        <button
                          data-testid="train-mt-model-button"
                          className="button-style text-sm text-orange transition-colors focus:outline-none"
                          onClick={() => fetchTrainSchemaWrapup({
                            synModelId: model.id,
                            extraInput: extraInput
                          })}
                        >
                          Train
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};