import { useCallback, useState } from "react";

import { useApi } from "src/api/useApi";
import { modelService } from "src/api/services/modelService";
import { useTask } from "src/api/services/useTask";
import { ArrowDownIcon } from "src/assets/icons/ArrowDownIcon";
import { MultiTabelModal } from "src/components/Modal/MultiTableModal";
import { useSchema } from "src/contexts/SchemaContext";
import { formatDate } from "src/helpers/date";
import { MTModelRow } from "src/views/Files/MultiTable/MTModelRow";
import { MTModelsTrainView } from "src/views/Files/MultiTable/MTModelsTrainView";

export const RealSchemaRow = ({ isOpen, schema, toggleRow }) => {
  const {
    taskMonitoring,
    taskProgress,
    taskStatus,
    monitorTask,
    initTask,
    forceEndTask
  } = useTask();
  const { fetchSchemaSummary } = useSchema();
  const [showTrainModal, setShowTrainModal] = useState(false);

  const { fetch, error, setError } = useApi({
    fetchOnLoad: false,
    onSuccess: (data) => (
      monitorTask({
        taskId: data.task_id,
        callable: fetchSchemaSummary,
        setTaskError: setError
      })
    ),
    fetcher: useCallback(
      (token, synModelId, extraInput) =>
        modelService.trainSchema({
          token: token,
          synModelId: synModelId,
          connectorId: schema.connector_id,
          body: extraInput
        }),
      [schema.connector_id]
    )
  });

  const fetchTrainSchemaWrapup = ({ synModelId, extraInput }) => {
    fetch(synModelId, extraInput);
    setShowTrainModal(false);
    initTask({ initStatus: `Training ${schema.db_name}` });
  }

  return (
    <>
      <div className="table-row-group">
        <div className="table-row text-pitch-black font-font text-sm real-datasets-rows">
          <div className="bg-white border-t-0">
            <div
              data-testid="accordion"
              className={`cursor-pointer flex justify-center items-center transition-all duration-300 ease-linear
                ${isOpen ? "rotate-0" : "-rotate-90"}
                ${schema.mt_models.length > 0 ? "visible" : "collapse"}`}
              onClick={toggleRow}
            >
              <ArrowDownIcon />
            </div>
          </div>
          <div className="text-left pl-8">
            {schema.db_name}
          </div>
          <div>
            {schema.db_schema}
          </div>
          <div>
            {formatDate(schema.created_at)}
          </div>
          {[...Array(5).keys()].map((_,i) => (
            <div key={i} />
          ))}
          <div>
            <button
              data-testid="open-mt-model-train-view-button"
              className="button-style text-sm text-orange transition-colors focus:outline-none"
              onClick={() => setShowTrainModal(true)}
            >
              Train
            </button>
          </div>
        </div>
        {/* Render MT Model Rows */}
        {schema.mt_models.map((mt_model, i) => (
          <MTModelRow
            key={i}
            id={i}
            isOpen={isOpen}
            mt_model={mt_model}
            fetchSchemaSummary={fetchSchemaSummary}
          />
        ))}
      </div>
      {showTrainModal && <MTModelsTrainView
        className="z-50"
        setIsOpen={setShowTrainModal}
        fetchTrainSchemaWrapup={fetchTrainSchemaWrapup}
      />}
      {taskMonitoring && <MultiTabelModal
        className="z-50"
        isOpen={taskMonitoring}
        progress={taskProgress}
        type="progress-spinner"
        title={taskStatus}
      />}
      {!!error && <MultiTabelModal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Continue"
        buttonAction={() => {
          forceEndTask();
          setError("");
        }}
      />}
    </>
  );
};