import { Circle, CircleText, ContainerProgressSpinner, WrapperCircle } from 'src/components/Loaders/ProgressSpinner.styles';

export const ProgressSpinner = ({progress}) => {

  return (
    <ContainerProgressSpinner>
      <WrapperCircle>
        <Circle />
        <CircleText>{progress}%</CircleText>
      </WrapperCircle>
    </ContainerProgressSpinner>
  );
};
