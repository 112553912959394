import { createContext, useCallback, useContext } from 'react';
import { useApi } from 'src/api/useApi';
import { userService } from 'src/api/services/userService';
import { LoadingSpinner } from 'src/components/Loaders/LoadingSpinner';
import { Modal } from 'src/components/Modal/Modal';

export const UserContext = createContext({});

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const getCurrentUser = useCallback(
    (token) => userService.getCurrentUser({ token: token }),
    []
  );
  const { error, setError, data, fetch, loading } = useApi({ fetcher: getCurrentUser, fetchOnLoad: true });

  if (!data) return <LoadingSpinner />;

  if (error) {
    return <Modal
        isOpen={!!error}
        type="error"
        description={error}
        buttonText="Try again"
        buttonAction={() => setError('')}
    />;
  }

  return (
    <UserContext.Provider
      value={{
        currentUser: data,
        fetchCurrentUser: fetch,
        loadingUser: loading
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
