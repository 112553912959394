export default function DownloadIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.5 29a.523.523 0 0 0 .353-.146l3.994-3.994c.464-.446-.26-1.17-.706-.706l-3.64 3.64-3.64-3.64c-.454-.473-1.175.256-.707.706l3.994 3.994a.485.485 0 0 0 .353.146zm0-3a.499.499 0 0 1-.5-.5v-7a.499.499 0 1 1 1 0v7c0 .277-.223.5-.5.5zm7-3H25c2.756 0 5-2.244 5-5 0-2.398-1.734-4.373-4.04-4.836.016-.22.04-.494.04-.664C26 7.28 21.74 3 16.5 3c-3.51.005-6.686 1.973-8.33 5.05C7.948 8.03 7.726 8 7.5 8 3.352 8 0 11.364 0 15.5S3.364 23 7.5 23h1c.663 0 .668-1 0-1h-1A6.492 6.492 0 0 1 1 15.5 6.493 6.493 0 0 1 7.496 9c.285.002.57.023.852.063a.5.5 0 0 0 .52-.276A8.492 8.492 0 0 1 25 12.5a8.614 8.614 0 0 1-.06.963c-.035.28.167.53.447.558A3.993 3.993 0 0 1 29 18c0 2.215-1.785 4-4 4h-2.5c-.685 0-.638 1 0 1z"
      />
    </svg>
  );
};