import styled from 'styled-components';
import { H6 } from 'src/components/Headers/Headers.style';
import { Theme } from 'src/components/Theme';

export const Container = styled.div`
  padding: 1.5rem 1rem;
  background: ${({ open }) => (open ? Theme.colors.litepink : 'inherit')};
  border: 0.063rem solid ${Theme.colors.gray};
  cursor: pointer;
  p {
    line-height: 0.75rem;
    font-size: ${Theme.fontSize.xsmall};
  }
  svg {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
    margin: 0rem 0.5rem;
  }
  ${Theme.breakpoints.laptop} {
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    p {
      line-height: 1rem;
      font-size: ${Theme.fontSize.small};
    }
  }
`;

export const Wrapper = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  ${H6} {
    margin: 0;
    text-align: left;
    padding-bottom: ${({ open }) => (open ? '1rem' : 'inherit')};
  }
`;
