export const PlayIcon = () => {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3334 13.5002C16.0001 13.0002 16.0001 12.0002 15.3334 11.5002L12.4167 9.31274C11.5927 8.69471 10.4167 9.28269 10.4167 10.3127V14.6877C10.4167 15.7178 11.5927 16.3058 12.4167 15.6877L15.3334 13.5002ZM2.08337 12.5007C2.08337 18.2507 6.75004 22.9173 12.5 22.9173C18.25 22.9173 22.9167 18.2507 22.9167 12.5007C22.9167 6.75065 18.25 2.08398 12.5 2.08398C6.75004 2.08398 2.08337 6.75065 2.08337 12.5007ZM4.16673 12.5011C4.16673 7.90731 7.90632 4.16772 12.5001 4.16772C17.0938 4.16772 20.8334 7.90731 20.8334 12.5011C20.8334 17.0948 17.0938 20.8344 12.5001 20.8344C7.90632 20.8344 4.16673 17.0948 4.16673 12.5011Z"
          fill="#FF7246"
          />
      </svg>
    );
  };