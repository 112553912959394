export const Connector = ({
  label,
  connectorId,
  selectedConnectorId,
  setSelectedConnectorId,
  setCheckedTables,
  fetchListTables,
}) => (
  <div
    data-testid={label}
    className="flex justify-start items-center p-1 hover:bg-orange hover:bg-opacity-10 rounded-lg"
    onClick={() => {
      // reset selected tables
      setCheckedTables([]);
      if (connectorId !== selectedConnectorId) {
        setSelectedConnectorId(connectorId);
        // avoid re-fetching from the same connector
        fetchListTables(connectorId);
      }
    }}
  >
    <input
      id="default-radio-1"
      type="radio"
      checked={connectorId === selectedConnectorId}
      name="default-radio"
      className="w-4 h-4 text-orange bg-lite-orange border-gray-300"
      readOnly={true}
    />
    <button className="rounded-md px-5 text-left font-font text-sm p-2 disabled:text-orange">
      {label}
    </button>
  </div>
);
