import { Theme } from "src/components/Theme";
export const FileIcon = ({ color = Theme.colors.pitchblack }) => {
  return (
    <svg
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3 2)"
      >
        <path d="m14.5 12.5v-7l-5-5h-5c-1.1045695 0-2 .8954305-2 2v10c0 1.1045695.8954305 2 2 2h8c1.1045695 0 2-.8954305 2-2z" />
        <path d="m9.5.5v3c0 1.1045695.8954305 2 2 2h3m-12-3c-1.1045695 0-2 .8954305-2 2v10c0 1.1045695.8954305 2 2 2h8c1.1045695 0 2-.8954305 2-2" />
      </g>
    </svg>
  );
};
