export const ContactIcon = () => {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    
    >

      <path fill="none"d="M40.5,48c-3,0-5.5-2.92-5.5-6.5v-8c0-3.58,2.47-6.5,5.5-6.5A5.31,5.31,0,0,1,45,29.77V22A21,21,0,0,0,3,22v7.77A5.31,5.31,0,0,1,7.5,27c3,0,5.5,2.92,5.5,6.5v8c0,3.58-2.47,6.5-5.5,6.5S2,45.08,2,41.5V22a22,22,0,0,1,44,0V41.5C46,45.08,43.53,48,40.5,48Zm0-20C38,28,36,30.47,36,33.5v8c0,3,2,5.5,4.5,5.5S45,44.53,45,41.5v-8C45,30.47,43,28,40.5,28ZM3,33.5v8c0,3,2,5.5,4.5,5.5S12,44.53,12,41.5v-8c0-3-2-5.5-4.5-5.5S3,30.47,3,33.5Z"   stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"/>
      <rect width="28" height="28" fill="none" />
    </svg>
  );
};
