export const SortingIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby="sortingIconTitle"
      stroke="#000000"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      color="#000000"
    >
      {" "}
      <title id="sortingIconTitle">Sorting</title>{" "}
      <polyline points="8 8.333 12 4.333 16 8.333 16 8.333" />{" "}
      <polyline points="16 15.667 12 19.667 8 15.667 8 15.667" />{" "}
    </svg>
  );
};