import { useNavigate } from 'react-router-dom';
import { Size } from 'src/components/Button/ButtonOptions';
import { LinkButton } from 'src/components/Button/LinkButton';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { H6 } from 'src/components/Headers/Headers.style';
import { useUser } from 'src/contexts/UserContext';
import { useStorage } from 'src/contexts/useStorage';
import { ButtonWrapper, Column, Container, Row, SelectTeamWrapper } from 'src/views/Project/SelectProject.styles';

export const SelectProject = () => {
  const { currentUser } = useUser();
  const { setCurrentProjectId } = useStorage();
  const navigate = useNavigate();

  // setCurrentProjectId passing the project pushing to the home and fetch files according to the project selected.
  const onSelectProject = (project) => {
    setCurrentProjectId(project.id);
    navigate('/');
  };

  return (
    <SelectTeamWrapper>
      <div className='H3'>Select project</div>
      <p data-testid="team-size">You are a member in {currentUser.projects.length} projects</p>
      <Container>
        <Column>
          <H6>Project name:</H6>
        </Column>
      </Container>
      <div data-testid="team-list">
        {currentUser.projects.map((project) => (
          <Container key={project.id}>
            <Row>
              <LinkButton onClick={() => onSelectProject(project)}>{project.name}</LinkButton>
            </Row>
          </Container>
        ))}
      </div>
      <ButtonWrapper>
        <SecondaryButton size={Size.medium} onClick={() => navigate('/onboarding/create-project')}>
          Create new project
        </SecondaryButton>
      </ButtonWrapper>
    </SelectTeamWrapper>
  );
};
