import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const CheckboxWrapper = styled.div`
  margin-top: 0.5rem;
  text-align: left;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg{
	cursor: pointer;
  }
  input {
  	display:flex;
	appearance: none;
	background: ${Theme.colors.gray};
	height: 1.3rem;
	width: 1.3rem;
	border-radius: 20%;
	&::after {
		content: '✔';
		font-size: 1rem;
		margin: auto;
		color: transparent;
		text-shadow: 0 0 0 transparent;
		transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
	}
	&:checked::after {
		color: ${Theme.colors.black};
		text-shadow: 2px 3px 2px fade(black, 20);
		transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
	}
}
`;