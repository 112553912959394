import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const ContainerProgressSpinner = styled.div`
  position: relative;
  height: 0.5rem;
  width: 90%;
  margin: 4rem auto;
  ${Theme.breakpoints.laptop} {
    height: 0.375rem;
    width: 18.75rem;
  }
`;

export const WrapperCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled.div`
  position: absolute;
  background-color: transparent;
  margin: 2rem auto;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border-top: 1rem solid ${Theme.colors.lightgray};
  border-right: 1rem solid ${Theme.colors.lightgray};
  border-bottom: 1rem solid ${Theme.colors.lightgray};
  border-left: 1rem solid ${Theme.colors.lightgray};
  border-left: 1rem solid ${Theme.colors.red};
  animation: rotateCircleLoader 1.1s infinite linear;
  @keyframes rotateCircleLoader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CircleText = styled.div`
  font-family: ${Theme.fontFamily.font};
  font-size: ${Theme.fontSize.small};
  font-weight: bold;
`;
