import { ContainerSpinner, StyledSpinner } from 'src/components/Loaders/Spinner.styles';

export const Spinner = ({ size = "default" }) => {
  return (
    <ContainerSpinner data-testid="spinner">
      <StyledSpinner viewBox="0 0 50 50" size={size}>
        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
      </StyledSpinner>
    </ContainerSpinner>
  );
};
