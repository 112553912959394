export const GenerateIcon = () => {
    return (
      <svg width="72" height="72" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="15.4321"
          y="15.4326"
          width="37.2973"
          height="37.2973"
          rx="6.21622"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4321 35.6349L35.61 15.457"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5142 52.7425L52.7293 32.5273L32.5142 52.7425Z"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2529 50.9083L50.9044 17.2568"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.2973 15.4324V9.21622C40.2973 5.78309 37.5142 3 34.0811 3H9.21622C5.78309 3 3 5.78309 3 9.21622V34.0811C3 37.5142 5.78309 40.2973 9.21622 40.2973H15.4324"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };