import { useState } from 'react';
import { PenIcon } from 'src/assets/icons/PenIcon';
import { H6 } from 'src/components/Headers/Headers.style';
import { RenameUserModal } from 'src/components/User/RenameUserModal';
import { useUser } from 'src/contexts/UserContext';
import { ProfileWrapper } from 'src/views/Profile/Profile.styles';
import { ProjectSetting } from 'src/views/Project/ProjectSetting';
import { ProfileIcon } from 'src/assets/icons/Profile';
import * as S from 'src/views/Settings/Settings.styles';

export const Profile = ({ fetchUsersFromProject, setMultiTableView }) => {
  const { currentUser } = useUser();
  // Popup modals for renaming user name.
  const [renameUserIsOpen, setRenameUserIsOpen] = useState(false);

  return (
      <S.Wrapper>
        <S.PaperContainer>
          <S.ContainerWrapper>
            <H6 className='flex justify-start'>
              <ProfileIcon />
              <span>Profile</span>
            </H6>
          </S.ContainerWrapper>
          <ProfileWrapper>
            <S.SubContainer>
              <H6>Name</H6>
              <p>{currentUser.name + " " + currentUser.last_name}</p>
              <div className='flex justify-center' title="Change Name" onClick={() => setRenameUserIsOpen(true)}>
                <PenIcon />
              </div>
            </S.SubContainer>
            <S.SubContainer>
              <H6>Mail</H6>
              <p>{currentUser.email}</p>
            </S.SubContainer>
            <S.SubContainer>
              <H6>Organization</H6>
              <p>{currentUser.organization.name}</p>
            </S.SubContainer>
            <ProjectSetting fetchUsersFromProject={fetchUsersFromProject} setMultiTableView={setMultiTableView} />
          </ProfileWrapper>
        </S.PaperContainer>
        <RenameUserModal
          isOpen={renameUserIsOpen}
          setIsOpen={setRenameUserIsOpen}
        />
      </S.Wrapper>
  );
};
