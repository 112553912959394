export const ArrowIcon = () => {
  return (
    <svg
      width="72"
      height="72"
      data-name="Layer 1"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 43V23M24 23l-7 7M24 23l7 7M32 43h2.5A12.87 12.87 0 0 0 37 17.58v-.26A12.16 12.16 0 0 0 25 5a12.16 12.16 0 0 0-12 12.32v0A12.67 12.67 0 0 0 1 30.16 12.68 12.68 0 0 0 13.5 43H16"
        fill="none"
        stroke="#525252"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        className="stroke-1d1d1b"
      />
    </svg>
  );
};