import { useNavigate } from 'react-router-dom';
import { Questions } from 'src/views/Faq/Questions';
import { Modal } from 'src/components/Modal/Modal';

export const CorrelationEuclidianInfoModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const questionId = Questions.evaluation.find((q) => q.id === 35).id;

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title="What is joint-distributions similarity?"
      description="The joint-distributions similarity metric gives you an indication of how similar is the relationship between pairs of distributions in the file’s attributes when comparing the uploaded file with the generated synthetic file."
      buttonText="Close"
      buttonAction={() => setIsOpen(false)}
      linkText="Read more"
      linkAction={() => navigate(`/faq?id=${questionId}`)}
    />
  );
};
