import { useNavigate } from 'react-router-dom';
import { LinkButton } from 'src/components/Button/LinkButton';
import { Spinner } from 'src/components/Loaders/Spinner';
import { Modal } from 'src/components/Modal/Modal';
import { StepThreePaper, StepThreeText } from 'src/views/Project/CreateProjectSteps.styles';
import { Size } from 'src/components/Button/ButtonOptions';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { EmailInputs } from 'src/components/Project/EmailInputs';
import { useInvite } from 'src/components/Project/useInvite';
import { useStorage } from 'src/contexts/useStorage';

// Invite users when creating a new project.
export const InviteProject = () => {
  const navigate = useNavigate();
  const { getCurrentProjectId } = useStorage();

  // Use invite state from the invite hook.
  const {
    showSuccess,
    emails,
    setEmails,
    setRoles,
    addEmail,
    inviteUsers,
    loading,
    setLoading,
    error,
    setError,
  } = useInvite();

  return (
    <StepThreePaper>
      <div className='H3'>Invite people to the team project</div>
      {loading ? (
        <Spinner size="small"/>
      ) : (
        <StepThreeText>
          <p>You can always invite people later.</p>
          <p>
            Admin or user - the invited people can have different <u>roles</u>, you decide.
          </p>
        </StepThreeText>
      )}
      <EmailInputs emails={emails} setEmails={setEmails} setRoles={setRoles} addEmail={addEmail} />
      <div>
        <SecondaryButton
          data-testid="send-invites"
          size={Size.medium}
          onClick={() => !emails.some(e => e === " " || e === "") && inviteUsers(getCurrentProjectId())}
        >
          Send invites
        </SecondaryButton>
        <Modal
          isOpen={showSuccess}
          type="success"
          title="A team project has been created"
          description=""
          buttonText="Continue"
          buttonAction={() => navigate('/')}
        />
        <Modal
          isOpen={!!error}
          type="error"
          title="Sorry, something went wrong"
          description={error}
          buttonText="Try again"
          linkText="Ok, close"
          buttonAction={() => {
            setError('');
            setLoading(false);
          }}
          linkAction={() => navigate('/')}
        />
      </div>
      <LinkButton data-testid='invite-later' onClick={() => navigate('/')}>Invite people later</LinkButton>
    </StepThreePaper>
  );
};
