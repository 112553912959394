import { useCallback } from 'react';
import { useApi } from 'src/api/useApi';
import { datasetService } from 'src/api/services/datasetService';

export const useFileRows = ({ realOrSyn, fileId, startRow, nrRows }) => {
  const service = realOrSyn === "real" ? "getRealDatasetRows" : "getSyntheticDatasetRows";
  const input_service_pre = realOrSyn === "real" ? "fileId" : "synFileId";
  // Get file rows
  const getFileRows = useCallback(
    (token) => {
      const input_service = { token: token, startRow: startRow, nrRows: nrRows };
      input_service[input_service_pre] = fileId;
      return datasetService[service](input_service);
    },
    [fileId, startRow, nrRows, input_service_pre, service]
  );
  const { data: datasetRows, loading, error, setError } = useApi({ fetcher: getFileRows, fetchOnLoad: true});
  return { datasetRows, loading, error, setError };
};