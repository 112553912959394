import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'src/components/Button/Button';
import { Size } from 'src/components/Button/ButtonOptions';
import { JoinProjectWrapper } from 'src/views/Project/JoinProject.styles';

export const JoinProject = () => {
  const { logout } = useAuth0();
  return (
    <>
      <JoinProjectWrapper>
        <div className='H3'>You don't belong to any project</div>
        <p>Contact your organization admin to add you to one</p>
        <Button size={Size.medium} onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </Button>
      </JoinProjectWrapper>
    </>
  );
};
