import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const Container = styled.div`
  position: relative;
  height: 0.5rem;
  width: 90%;
  margin: 1rem;
  ${Theme.breakpoints.laptop} {
    height: 0.375rem;
    width: 18.75rem;
    margin: 1rem 5.313rem;
  }
`;

export const BaseBox = styled.div`
  position: absolute;
  height: 100%;
  border-radius: 1.25rem;
  transition: width 0.1s ease-in-out;
`;

export const Background = styled(BaseBox)`
  width: 100%;
  background: ${Theme.colors.lightgray};
`;

export const Progress = styled(BaseBox)`
  width: ${({ percent }) => percent}%;
  background: ${Theme.colors.red};
`;
