import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'src/api/useApi';
import { Size } from 'src/components/Button/ButtonOptions';
import { SecondaryButton } from 'src/components/Button/SecondaryButton';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { TextInput } from 'src/components/Input/TextInput';
import { Spinner } from 'src/components/Loaders/Spinner';
import { useStorage } from 'src/contexts/useStorage';
import { Error, StepTwoInput, StepTwoPaper } from 'src/views/Project/CreateProjectSteps.styles';
import { useUser } from 'src/contexts/UserContext';
import { projectService } from 'src/api/services/projectService';

const defaultName = 'My first project';

export const SetProjectName = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(defaultName);
  const { fetchCurrentUser } = useUser();
  const { setCurrentProjectId } = useStorage();

  // Create name project and redirect to the step 3.
  const createNameProject = useCallback(
    (token) => projectService.postProject({ token: token, name: name }),
    [name]
  );
  const onSuccess = (data) => {
    // Setting the current project id.
    setCurrentProjectId(data.id);
    // fetch current user to get latest created project
    fetchCurrentUser();
    // continue to the next step
    navigate("?step=3");
  };
  const { loading, error, fetch } = useApi({ fetcher: createNameProject, fetchOnLoad: false, onSuccess: onSuccess });

  return (
    <StepTwoPaper>
      <div className='H3'>Team project name?</div>
      <p>You can always change the name later.</p>
      {loading && <Spinner />}
      {error && (
        <Error>
          <ExclamationIcon /> {error}
        </Error>
      )}
      <StepTwoInput>
        <TextInput data-testid='project-name' value={name} onChange={(e) => setName(e.target.value)} />
      </StepTwoInput>
      <SecondaryButton data-testid='continue' size={Size.medium} onClick={() => fetch()}>
        Continue
      </SecondaryButton>
    </StepTwoPaper>
  );
};
