import { useState } from "react";

import { SortingIcon } from "src/assets/icons/SortingIcon";
import { UploadDataIcon } from "src/assets/icons/ToolbarIcons/UploadDataIcon";
import { useSchema } from "src/contexts/SchemaContext";
import { LoadDataModal } from "src/components/LoadDataModal/LoadDataModal";
import { toggleRow, columnSortByNameAux } from "src/views/Files/utils";
import { RealSchemaRow } from "src/views/Files/MultiTable/RealSchemaRow";

export const RealSchemas = ({ setMultiTableView }) => {
  const { schemaSummary } = useSchema();
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [openRows, setOpenRows] = useState(new Set());
  // State for sorted column ascend or descend
  const [sorted, setSorted] = useState(true);
  const columnSort = ({ column }) => {
    columnSortByNameAux({ list: schemaSummary, column: column, sortAscending: sorted });
    setSorted((prev) => (!prev));
  }

  return (
    <>
      {schemaSummary && schemaSummary.length === 0 && (
        <div className="paper min-h-[70vh] flex flex-col justify-center items-center">
          <div className="flex justify-center items-center">
            <UploadDataIcon width="72" height="72" />
          </div>
          <div className="H4 py-4">Load your first schema</div>
          <p>
            There are currently no schemas loaded here. Remember that you can
            always delete schemas you have loaded.
          </p>
          <br />
          <div
            className="flex justify-center items-center p-2"
            onClick={() => {
              setIsLoadModalOpen(true);
            }}
          >
            <div
              className="max-md:hidden cursor-pointer px-4 py-1 text-[0.875rem] font-secondaryFont
                border border-[#FF7246] bg-white text-black rounded-3xl hover:text-black hover:bg-[#ff724633]
                disabled:text-light-gray disabled:cursor-none"
            >
              Load Data
            </div>
          </div>
          {isLoadModalOpen && (
            <LoadDataModal
              setIsLoadModalOpen={setIsLoadModalOpen}
              setMultiTableView={setMultiTableView}
            />
          )}
        </div>
      )}
      {schemaSummary && schemaSummary.length > 0 && (
        <section className="container paper px-4 mx-auto">
          <div className="flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="table min-w-full">
                  <div className="table-header-group bg-light-gray w-full">
                    <div className="table-row font-font font-bold text-sm text-black real-datasets-headers">
                      <div className="w-[10px] bg-white" />
                      <div className="w-[100px] text-left pl-4">
                        <div className="flex gap-x-1 items-center">
                          <div className="cursor-pointer" onClick={() => columnSort({ column: "db_name" })}>
                            <SortingIcon />
                          </div>
                          <span>Database</span>
                        </div>
                      </div>
                      <div className="w-[300px]">
                        <div className="flex gap-x-1 items-center justify-center">
                          <div className="cursor-pointer" onClick={() => columnSort({ column: "db_schema" })}>
                            <SortingIcon />
                          </div>
                          <span>Schema</span>
                        </div>
                      </div>
                      <div className="w-[140px]">
                        <div className="flex gap-x-1 items-center justify-center">
                          <div className="cursor-pointer" onClick={() => columnSort({ column: "created_at" })}>
                            <SortingIcon />
                          </div>
                          <span>Created</span>
                        </div>
                      </div>
                      <div className="w-[50px]" />
                      {[...Array(3).keys()].map((_,i) => (
                        <div className="w-[60px]" key={i} />
                      ))}
                      <div />
                      <div className="max-w-[140px] w-[140px]" />
                    </div>
                  </div>
                  {schemaSummary.map((schema, i) => (
                    <RealSchemaRow
                      key={i}
                      isOpen={openRows.has(i)}
                      schema={schema}
                      toggleRow={() => toggleRow({
                         setOpenRows: setOpenRows,
                         i: i,
                       })}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};