import { callApi } from 'src/api/services/aux';

export const userService = {
  // Endpoint for get the current user.
  getCurrentUser: ({ token }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/current_user"
  }),
  // Endpoint for get users by project id and/or email.
  // And destructuring params for passing undefine values.
  getUsers: ({ token, projectId, email }) => callApi({
    token: token,
    method: "GET",
    endpoint: "/users",
    params: {
      ...(projectId && { project_id: projectId }),
      ...(email && { email })
    }
  }),
  // Endpoint for post user with email and role.
  postUser: ({ token, email, role }) => callApi({
    token: token,
    method: "POST",
    endpoint: "/user",
    options: { body: JSON.stringify({ email, role }) }
  }),
  // Endpoint for patch user with user id, name, last name and/or role.
  // And destructuring params for passing undefine values.
  patchUser: ({ token, id, name, lastName, role }) => callApi({
    token: token,
    method: "PATCH",
    endpoint: "/user",
    options: {
      body: JSON.stringify({
        id,
        ...(name && { name }),
        ...(lastName && { last_name: lastName }),
        ...(role && { role })
      })
    }
  })
};
