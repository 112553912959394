import { Theme } from "src/components/Theme";
export const ChooseIcon = ({ color = Theme.colors.orange }) => {
    return (
      <svg width="72" height="72" viewBox="0 0 50 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.4324 9.21582H9.21622C5.78309 9.21582 3 11.9989 3 15.432V52.7293C3 56.1625 5.78309 58.9456 9.21622 58.9456H40.2973C43.7304 58.9456 46.5135 56.1625 46.5135 52.7293V15.432C46.5135 11.9989 43.7304 9.21582 40.2973 9.21582H34.0811"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="15.4326"
          y="3"
          width="18.6486"
          height="12.4324"
          rx="6.21622"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M15.4326 37.1889L21.6488 43.4051L34.0813 30.9727"
          stroke="#525252"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    );
  };