// Helper function to construct HTTP request for API call
export const getOptions = (options, method, token) => ({
  ...options,
  method: method,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...options?.headers,
  },
});

// Helper function for calling the API.
export const callApi = (request) => {
  const url = new URL(process.env.REACT_APP_AUTH0_AUDIENCE + request.endpoint);
  // Add query parameters to encode the search.
  if (request.params) {
    url.search = new URLSearchParams(request.params).toString();
  }
  // Make request
  return fetch(url, getOptions(request.options, request.method, request.token));
};