import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { LogoutIcon } from "src/assets/icons/ToolbarIcons/LogoutIcon";
import { SettingIcon } from "src/assets/icons/ToolbarIcons/SettingsIcon";
import { QuestionMarkIcon } from "src/assets/icons/ToolbarIcons/QuestionMarkIcon";
import { FileIcon } from "src/assets/icons/ToolbarIcons/FilesIcon";
import { HowItWorksIcon } from "src/assets/icons/ToolbarIcons/HowItWorksIcon";
import logo from "src/assets/vectors/logosyndata.svg";
import { Link } from "react-router-dom";
import { ToolbarItem } from "src/views/Dashboard/ToolbarItem/ToolbarItem";
import { useStorage } from "src/contexts/useStorage";
import { LoadDataModal } from "src/components/LoadDataModal/LoadDataModal";
import { useConnectors } from "src/contexts/ConnectorContext";
import { UploadFile } from "src/components/LoadDataModal/UploadFile";
import { UploadDataIcon } from "src/assets/icons/ToolbarIcons/UploadDataIcon";
import * as S from "src/views/Dashboard/Toolbar.styles";

export const Toolbar = ({ setMultiTableView }) => {
  // state for toggling the visibility of the dropdown menu
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const { DFConnector, databaseConnectors } = useConnectors();
  // hooks
  const { logout } = useAuth0();
  const { deleteCurrentProjectId } = useStorage();
  // ToolbarItems object
  const toolbarItems = [
    {
      title: "Data",
      href: "/",
      icon: <FileIcon />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <SettingIcon />,
    },
    {
      title: "FAQ",
      href: "/faq",
      icon: <QuestionMarkIcon />,
    },
    {
      title: "How it works",
      href: "/howitworks",
      icon: <HowItWorksIcon />,
    },
  ];

  return (
    <S.ToolbarContainer>
      <S.ToolbarTopSection>
        <Link to="/">
          <img src={logo} alt="Log Syndata" />
        </Link>
      </S.ToolbarTopSection>
      <S.ToolbarMainSection>
        {DFConnector && (
          <div className="mt-10">
            <UploadFile connectorId={DFConnector.id} />
          </div>
        )}
        {databaseConnectors.length > 0 && (
          <div
            onClick={() => setIsLoadModalOpen(true)}
            className="mt-4 flex items-center w-full cursor-pointer px-4 py-1 text-[0.875rem] font-secondaryFont hover:bg-light-gray hover:text-orange hover:rounded-l-3xl disabled:text-light-gray disabled:cursor-none
              transition-all duration-500 ease-in-out
              hover:duration-500"
          >
            <div className="w-8 h-6 m-2">
              <UploadDataIcon />
            </div>
            <div className="max-md:hidden">Load Data</div>
          </div>
        )}
        {toolbarItems.map((item, key) => (
          <ToolbarItem key={key} item={item} />
        ))}
      </S.ToolbarMainSection>
      <S.ToolbarBottomSection
        onClick={(e) => {
          e.preventDefault();
          deleteCurrentProjectId();
          logout({ returnTo: window.location.origin });
        }}
      >
        <LogoutIcon />
        <span>Log Out </span>
      </S.ToolbarBottomSection>
      {isLoadModalOpen && (
        <LoadDataModal setIsLoadModalOpen={setIsLoadModalOpen} setMultiTableView={setMultiTableView} />
      )}
    </S.ToolbarContainer>
  );
};