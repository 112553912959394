import { useCallback, useState } from 'react';
import { useApi } from 'src/api/useApi';
import { useUser } from 'src/contexts/UserContext';
import { projectService } from 'src/api/services/projectService';
import { TextInput } from 'src/components/Input/TextInput';
import { Modal } from 'src/components/Modal/Modal';
import { useStorage } from 'src/contexts/useStorage';
import { getCurrentProjectOrError } from 'src/contexts/aux';
import { useNavigate } from "react-router-dom";

export const DeleteProjectModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const { currentUser, fetchCurrentUser } = useUser();
  const { deleteCurrentProjectId, getCurrentProjectId } = useStorage();
  const currentProject = getCurrentProjectId() && getCurrentProjectOrError({ currentUser: currentUser, currentProjectId: getCurrentProjectId()});
  // Variables for type the project name and delete it.
  const [name, setName] = useState('');

  // Delete project and redirect to the home.
  const deleteProject = useCallback(
    (token) => projectService.deleteProject({ token: token, id: getCurrentProjectId() }),
    [getCurrentProjectId]
  );
  const onSuccess = () => {
    // fetch current user to get latest information
    fetchCurrentUser();
    // delete current project id from local storage
    deleteCurrentProjectId();
    // When deleting a project take the user back to /login
    navigate("/login");
  };

  const { error, fetch } = useApi({
    fetcher: deleteProject,
    fetchOnLoad: false,
    onSuccess: onSuccess
  });

  return (
    <Modal
      isOpen={isOpen}
      type="error"
      title={`Are you sure that you want to delete "${currentProject.data.name}"?`}
      description={`This action can not be undone. You will loose all project information, including files.
      To confirm, please write the project name (${currentProject.data.name}) and press Delete.`}
      error={error}
      buttonText="Delete"
      buttonDisabled={name !== currentProject.data.name}
      buttonAction={() => fetch()}
      linkText="Cancel"
      linkAction={() => setIsOpen(false)}
    >
      <TextInput
        data-testid="delete-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};
